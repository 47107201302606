
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,Toast,Card,WhiteSpace,WingBlank } from 'antd-mobile';


const Item = List.Item;
const Brief = Item.Brief;
export default class Yktmjqxsq extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}

             onLeftClick={() => this.props.history.goBack()}
            >塔西南便民电话</NavBar>
            <List  className="my-list">
                <a href="tel:0998-7521107"><Item extra={'0998-7521107'} arrow="horizontal">石化区社区</Item></a> 
                <a href="tel:0998-7522023"><Item extra={'0998-7522023'} arrow="horizontal">三区社区</Item></a>
                <a href="tel:0998-7527746"><Item extra={'0998-7527746'} arrow="horizontal">二区社区</Item></a>
                <a href="tel:0998-7521300"><Item extra={'0998-7521300'} arrow="horizontal">五区社区</Item></a>
                <a href="tel:0998-7523346"><Item extra={'0998-7523346'} arrow="horizontal">石化区警务室</Item></a>
                <a href="tel:0998-7520120"><Item extra={'0998-7520120'} arrow="horizontal">医院急救中心</Item></a>
                <a href="tel:15199353193"><Item extra={'15199353193'} arrow="horizontal">邮政快递人员</Item></a>
                <a href="tel:13899193628"><Item extra={'13899193628'} arrow="horizontal">健康大药房</Item></a>
                <a href="tel:0998-7523490"><Item extra={'0998-7523490'} arrow="horizontal">440服务电话</Item></a>
                <a href="tel:13779889965"><Item extra={'13779889965'} arrow="horizontal">广电故障处理电话</Item></a>
                <a href="tel:0998-7520112"><Item extra={'0998-7520112'} arrow="horizontal">座机故障</Item></a>
                <a href="tel:13779738716"><Item extra={'13779738716'} arrow="horizontal">修锁(谢师傅)</Item></a>
                <a href="tel:13119986226"><Item extra={'13119986226'} arrow="horizontal">修锁(秦师傅)</Item></a>
                <a href="tel:0998-75233490"><Item extra={'0998-75233490'} arrow="horizontal">物业调度</Item></a>
                <a href="tel:0998-2582777"><Item extra={'0998-2582777'} arrow="horizontal">喀什邦臣酒店</Item></a>
                <a href="tel:0991-7725888"><Item extra={'0991-7725888'} arrow="horizontal">乌鲁木齐塔西南宾馆</Item></a>
                <a href="tel:0998-7523369"><Item extra={'0998-7523369'} arrow="horizontal">塔西南班车订票电话</Item></a>
                <a href="tel:0998-7522666"><Item extra={'0998-7522666'} arrow="horizontal">送水(桶装水)</Item></a>
                <a href="tel:15214974855"><Item extra={'15214974855'} arrow="horizontal">纱窗更换</Item></a>
                <a href="tel:13779719108"><Item extra={'13779719108'} arrow="horizontal">下水道疏通</Item></a>
                <a href="tel:0998-7521107"><Item extra={'0998-7521107'} arrow="horizontal">石化区社区</Item></a>
                <a href="tel:13119986089"><Item extra={'13119986089'} arrow="horizontal">管道疏通</Item></a>
                <a href="tel:18099554799"><Item extra={'18099554799'} arrow="horizontal">万客来超市</Item></a>
                <a href="tel:0998-8255019"><Item extra={'0998-8255019'} arrow="horizontal">海尔售后电话</Item></a>
                <a href="tel:15292925692"><Item extra={'15292925692'} arrow="horizontal">修空调、冰箱、洗衣机</Item></a>
                <a href="tel:13579302677"><Item extra={'13579302677'} arrow="horizontal">全通马石油汽车养护中心</Item></a>
                <a href="tel:18139577281"><Item extra={'18139577281'} arrow="horizontal">电信维修电话</Item></a>
                <a href="tel:13579070758"><Item extra={'13579070758'} arrow="horizontal">新丰照相馆</Item></a>
                <a href="tel:18699865241"><Item extra={'18699865241'} arrow="horizontal">好朋友洗车洗地毯点</Item></a>
                <a href="tel:17699786002"><Item extra={'17699786002'} arrow="horizontal">石油便民货车司机(李维华)</Item></a>
                <a href="tel:13709986068"><Item extra={'13709986068'} arrow="horizontal">家政服务</Item></a>

            </List>
            <List renderHeader={() => '快递服务'} className="my-list">
            <a href="tel:0998-8183160"><Item extra={'0998-8183160'} arrow="horizontal">圆通快递</Item></a>
            <a href="tel:0998-81838183"><Item extra={'0998-81838183'} arrow="horizontal">韵达快递</Item></a>
            <a href="tel:0998-8183688"><Item extra={'0998-8183688'} arrow="horizontal">申通快递</Item></a>
            <a href="tel:19945881449"><Item extra={'19945881449'} arrow="horizontal">京东快递</Item></a>
            <a href="tel:15599983638"><Item extra={'15599983638'} arrow="horizontal">顺丰快递</Item></a>
            <a href="tel:0998-75012207"><Item extra={'0998-75012207'} arrow="horizontal">中铁物流集团</Item></a>
            <a href="tel:18130827222"><Item extra={'18130827222'} arrow="horizontal">百世快递</Item></a>
            </List>
            </div>
        )
    }
}
