import React, { Component } from 'react'
import { YGK_URL, YKT_URL, Axios } from '../../utils/url'
import { Grid, NavBar, Icon, Toast } from 'antd-mobile';
import wdyy from '../../assets/images/wdyy.png'
import yycx from '../../assets/images/jccx.png'
import yyqx from '../../assets/images/jcqx.png'
const xgbdxz = [
    { icon: wdyy, text: "预约" },
    { icon: yycx, text: "预约查询" },
    { icon: yyqx, text: "预约取消" }
];
export default class Jcfw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardList: [],
        }
    }

    //进页面加载
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            ryxm: userInfo.ryxm,
            sqdw: userInfo.rysx20120716p0026,
            sfzh: userInfo.sfzh,
            zz: userInfo.rysx20120716p0051,
            ektbh: userInfo.rysx20120716p0046,
            sqrid: userInfo.ryid,
            phone: userInfo.rysx20120716p0048,
            userInfo: userInfo,
            dwbm: userInfo.dwbm,
        }, () => {
            this.getYktxx();
        });
    }
    //查询一卡通
    getYktxx = () => {
        const url = YKT_URL + 'bryxxController/getYktxx';
        var params = new URLSearchParams();
        params.append('sfzh', this.state.sfzh);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            if (res.data.length > 0) {
                this.state.cardList = [
                    {
                        label: '一卡通',
                        value: 'ykt',
                    }]
            }
            this.getYgtxx()
        });
    }
    //查询员工卡
    getYgtxx = () => {
        const url = YGK_URL + 'bryxxController/getYktxx';
        var params = new URLSearchParams();
        params.append('sfzh', this.state.sfzh);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            if (res.data.length > 0) {
                if (this.state.cardList.length > 0) {
                    this.state.cardList = [
                        {
                            label: '一卡通',
                            value: 'ykt',
                        },
                        {
                            label: '员工卡',
                            value: 'ygk',
                        },
                    ];
                } else {
                    this.state.cardList = [
                        {
                            label: '员工卡',
                            value: 'ygk',
                        }]
                }
            }
        });
    }
    openDownload = e => {
        if (e.text === "预约") {
            if (this.state.cardList.length > 0) {
                // this.props.history.push("/jcsq")
                this.props.history.push({ pathname: "/jcsq", query: { cardList: this.state.cardList } })
            } else {
                Toast.info('您当前暂无一卡通和员工卡，无权进行预约')
            }
        } else if (e.text === "预约查询") {
            if (this.state.cardList.length > 0) {
                this.props.history.push("/jccx")
            } else {
                Toast.info('您当前暂无一卡通和员工卡，无权进行预约')
            }
        } else if (e.text === "预约取消") {
            if (this.state.cardList.length > 0) {
                this.props.history.push("/jcqx")
            } else {
                Toast.info('您当前暂无一卡通和员工卡，无权进行预约')
            }
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}

                    onLeftClick={() => this.props.history.goBack()}
                >就餐预约</NavBar>
                <div style={{ paddingTop: "20px" }}>


                    <Grid data={xgbdxz} activeStyle={false} hasLine={false} onClick={this.openDownload} />

                </div>


            </div>
        )
    }
}

