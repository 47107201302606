import './dhfjf.css'
import React, { Component } from 'react'
import { NavBar, Icon, SearchBar, List, Toast, Modal, Radio, Button, InputItem, Picker } from 'antd-mobile';
import { BASE_URL, YL_URL, PAGE_URL, Axios } from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Dhfjf extends Component {
    constructor(props) {
        super(props);
        var userInfo = "";
        const openid = this.props.location.search;
        if (openid.length > 0) {
            var open = openid.split("&");
            var openidArr = open[0].split("=");
            var openid1 = openidArr[1];
            var params = new URLSearchParams();
            localStorage.setItem("openID", openid1);
            params.append('openid', openid1);
            Axios({
                method: 'post',
                url: BASE_URL + "bRyzcController/getUserInfo",
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {
                localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.setState({
                    yhxm: userInfo.ryxm,
                    yhid: userInfo.sfzh
                })
            });

        }
        this.state = {
            jfdjList: [],
            zje: "",
            czje: "",
            isLoaded: false,
            isLoad: false,
            mxList: [],
            modal: false,
            modal1: false,
            modalfwxz: false,
            modaljfxx: false,
            value: "",
            telecode: "",
            isMxCk: "",
            yhid: "",
            fwxxbdlist: [],
            jtzz: "",
            jtzhlist1: [],
            jtzhlist2: [],
            jtzhlist3: [],
            username: "",
            dataflag: false,
            nowdate: "",
            jsrqFlag: true
        }
        this.handClose = this.handClose.bind(this);
    }
    componentDidMount = () => {
        this.getDate();
        if (this.isWeiXin()) {
            this.setState({
                value: "WXPay.jsPay"
            });
        } else {
            this.setState({
                value: "trade.precreate"
            });
        }
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                const url = BASE_URL + 'bRyzcController/getUserInfo';
                Axios({
                    method: 'post',
                    url: url,
                    data: params,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        yhxm: userInfo.ryxm,
                        yhid: userInfo.sfzh
                    })

                });

            }
        } else {
            this.setState({
                yhxm: userInfo.ryxm,
                yhid: userInfo.sfzh
            })

        }



    }
    getDate() {
        var date = new Date();

        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        var today = date.getDate();
        var now = year + "-" + month + "-" + today;
        console.log(now);
        this.setState({
            nowdate: now,

        });

        const url = BASE_URL + 'whrqController/getJsrq';
        var params = new URLSearchParams();
        // params.append("sfzh","622323199208183117");

        Toast.loading("加载中...", 0, null, true);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            Toast.hide();
            console.log(res);
            console.log(res.data.length);
            if (res.status === 200) {
                for (var a = 0; a < res.data.length; a++) {
                    var jsrq = res.data[a].jsrq;
                    if (jsrq.indexOf(now) > -1) {
                        console.log(111)
                        this.setState({
                            jsrqFlag: false,

                        });
                    }
                }
            }
        });
        // var date = new Date();

        // var year = date.getFullYear();
        // var month = date.getMonth()+1;
        // var today = date.getDate();
        // var new_year = year; //取当前的年份
        // var new_month = month++;//取下一个月的第一天，方便计算（最后一天不固定）
        // if(month>12){//如果当前大于12月，则年份转到下一年
        //     new_month -=12; //月份减
        //     new_year++; //年份增
        // }
        // var new_date = new Date(new_year,new_month,1); //取当年当月中的第一天

        // var month_last_day = (new Date(new_date.getTime()-1000*60*60*24-86400)).getDate();

        // if(today >= month_last_day){
        //     this.state.dataflag = true;
        // }
        // console.log(this.state.dataflag);
        // return flag;

    }



    onSubmit = (e) => {
        this.setState({
            dhhm: e
        });
        this.getClglList(e);
    };

    //获取列表
    async getClglList(e) {

        const url = BASE_URL + 'systeleuserController/getdhfjf';
        var params = new URLSearchParams();
        params.append("dhhm", e);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then((res) => {
            Toast.hide();
            console.log(res);
            if (res.status === 200) {
                if (res.data.list.length > 0) {
                    if(res.data.list[0].prepay1 <5){
                        Toast.fail('您已多月欠费，请到大厅办理');
                        return;
                    }
                    this.setState({
                        jfdjList: res.data.list,
                        zje: res.data.list[0].payremain,
                        username: res.data.list[0].username,
                        dqzd: res.data.list[0].nowprepay,
                        dqye: res.data.list[0].nowremain,
                        isLoaded: true,
                        modaljfxx: true
                    });

                } else {
                    this.setState({
                        jfdjList: res.data.list,
                        zje: res.data.zje,
                        isLoaded: true
                    });
                    alert("暂无信息");
                }
            }
        }).catch(e => {
            Toast.hide();
            console.log(e)
        });
    }
    setMapList = () => {
        if (!this.state.isLoad) {
            return null;
        } else {
            return this.state.fwxxbdlist.map((item, key) => (
                <Item extra={""} align="middle"
                    thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                    multipleLine key={key}
                    onClick={() => this.handlemx(item)}>
                    {/* {"*" + (item.ryxm).substring(1)}  */}
                    <Brief>{item.fwbh.substring(3, 17)}</Brief>
                    <Brief>{item.yhxm}</Brief>
                </Item>
            ))
        }


    }
    setJfxxList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.jfdjList.map((item, key) => (
                <Item extra={item.nowremain + "元"} align="middle"
                    thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                    multipleLine key={key}
                    onClick={() => this.handlejfmx(item)}>
                    {/* {"*" + (item.ryxm).substring(1)}  */}
                    <Brief>{item.username}</Brief>
                    <Brief>{item.telecode}</Brief>
                    <Brief>{"当前余额：" + item.nowremain + "元"}</Brief>
                </Item>
            ))
        }
    }

    handlemx = (item) => {
        //console.log(item.fwbh);
        this.setState({
            dhhm: item.fwbh
        });
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.getClglList(item.fwbh);
    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    handClose = () => {
        this.setState({
            modaljfxx: false,
        });
    }
    handleJump = () => {
        if (this.state.zje === '') {
            Toast.info("请输入电话号码后再支付", 2, null, true);
        } else {
            this.setState({
                modal1: true
            });
        }

    }
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    setmxList = () => {
        return this.state.mxList.map((item, key) => (
            <Item extra={item.je + "元"} align="middle"
                thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                multipleLine key={key} >
                {item.fylb}
                <Brief>{"单价：" + (item.flv == undefined ? "" : item.flv)}</Brief>
                <Brief>{"数量：" + (item.sl == undefined ? "" : item.sl)}</Brief>
            </Item>
        ))
    }
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    setOrder = () => {
        if (this.state.jsrqFlag) {
            // if (this.state.czje === '') {
            //     Toast.info("请输入充值金额", 1, null, false);
            // } else
            if (this.state.czje === '') {
                Toast.info("请输入充值金额", 1, null, false);
            } else if (this.state.value === '') {
                Toast.info("请选择支付类型", 1, null, false);
            } else {
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                if (this.state.value === '') {
                    Toast.info("请选择支付类型", 1, null, false);
                } else {
                    if (this.isWeiXin() && this.state.value === "trade.precreate") {
                        let openId = localStorage.getItem("openID");
                        //alert(openId);
                        window.parent.frames.location.href = PAGE_URL + "?openId=" + openId + "&page=dhfjf";
                        return;
                    }
                    // const url = YL_URL + 'xiadanController/order';
                    var url = "";
                    if (this.isWeiXin()) {
                        url = YL_URL + 'xiadanWXController/order'
                    } else {
                        url = YL_URL + 'xiadanController/order';
                    }
                    var params = new URLSearchParams();
                    params.append('totalAmount', Number(this.state.czje));
                    params.append('goodsId', this.state.dhhm);
                    params.append('goodsName', "电话费缴费");
                    params.append('id', this.state.dhhm);
                    params.append('price', this.state.czje);
                    params.append('payMethod', this.state.value);
                    params.append('ywbm', "DHFJF");
                    params.append('yhid', userInfo.sfzh);
                    params.append('yhxm', userInfo.ryxm);
                    Toast.loading("加载中...", 0, null, true);
                    Axios({
                        method: 'post',
                        url: url,
                        data: params,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then((res) => {
                        Toast.hide();
                        if (this.state.value == "WXPay.jsPay") {
                            if (res.status == '200') {
                                window.parent.frames.location.href = res.data.data;
                            } else {
                                alert("支付失败，请重新支付");
                            }
                        } else {
                            if (res.status == '200') {
                                let jsonData = JSON.parse(res.data.data);
                                if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                                    let payJson = JSON.parse(jsonData.respStr);
                                    this.setState((state, props) => {
                                        return {
                                            zfdz: payJson.appPayRequest.qrCode,
                                            merOrderId: payJson.merOrderId
                                        }
                                    },
                                        () => {
                                            //支付
                                            Modal.alert('确认支付？', '确认支付？', [
                                                { text: '取消', onPress: () => console.log('下次一定') },
                                                {
                                                    text: '确定',
                                                    onPress: () =>
                                                        new Promise((resolve) => {
                                                            this.setState({
                                                                isPay: true
                                                            });
                                                            //跳转到登录
                                                            window.open(this.state.zfdz);
                                                            this.props.history.push({ pathname: '/dhfjf', query: { merOrderId: this.state.merOrderId } });
                                                            setTimeout(resolve, 500);
                                                        }),
                                                },
                                            ])
                                        }
                                    )

                                } else {
                                    alert("支付失败，请重新支付");
                                }
                            }
                        }
                    });
                }
            }
        } else {
            Toast.info("当前日期不能充值", 1, null, true);
        }

    }
    showModal = key => (e) => {
        if (this.state.sftzText == "否" && key == "modal1") {
            return;
        }
        this.setState({
            [key]: true,
        });
    }

    render() {
        const { value } = this.state;
        const data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if (this.isWeiXin()) {
            var obj = {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
            var obj1 = {};
            obj1.value = "trade.precreate";
            obj1.label = "支付宝支付";
            obj1.img = Zfbzf;
            data.push(obj1);
        } else {
            var obj = {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}

                >电话费缴费</NavBar>
                <div className="kf">

                    <SearchBar placeholder="请输入电话号码，格式为：75XXXXX" maxLength={14}
                        showCancelButton={true} onSubmit={this.onSubmit} cancelText={"查询"} onCancel={this.onSubmit} />
                    {/* <InputItem placeholder="请选择房号" value={this.state.jtzz} onClick={this.showModal('modalfwxz')} onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>房号选择</div>
                    </InputItem> */}
                    <div className="gs" >
                        *请输入电话号码,格式为：75XXXXX
                    </div>


                    <List renderHeader={() => ''} >
                        {this.setMapList()}
                    </List>
                </div>
                <Modal
                    style={{ height: "60%", overflow: "auto" }}
                    // title="缴费信息"
                    popup
                    // closable={true}
                    visible={this.state.modaljfxx}
                    onClose={this.onClose('modaljfxx')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <div style={{ cursor: "pointer" }}>
                        <div type="ghost" inline size="small"
                            style={{ marginRight: '4px', position: "absolute", left: "10px", top: "5px", color: "#108EE9", cursor: "pointer", fontSize: "18px", fontWeight: 500 }}
                            onClick={this.handClose}>关闭</div>
                        <div type="ghost" inline size="small"
                            style={{ marginRight: '4px', position: "absolute", right: "10px", top: "5px", color: "#108EE9", cursor: "pointer", fontSize: "18px", fontWeight: 500 }}
                            onClick={this.handleJump}>支付</div>
                    </div>

                    <List renderHeader={() => <div>缴费信息</div>} className="popup-list" style={{ marginTop: "30px" }}>
                        {this.setJfxxList()}
                    </List>
                </Modal>
                <Modal
                    style={{ height: "60%", overflow: "auto" }}
                    title="缴费明细"
                    popup
                    closable={true}
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => ''} >
                        {this.setmxList()}
                    </List>
                </Modal>
                <Modal
                    popup
                    visible={this.state.modal1}
                    onClose={this.onClose('modal1')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '缴费金额'}>
                        <InputItem
                            type='number'
                            placeholder="请输入充值金额"
                            value={this.state.czje}
                            onChange={(value) => this.setState({ czje: value })}
                            extra="元"
                        >充值金额</InputItem>
                    </List>
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
                <Modal
                    popup
                    visible={this.state.modalfwxz}
                    onClose={this.onClose('modalfwxz')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '详情信息'} >
                        <Picker
                            data={this.state.jtzhlist1}
                            cols={1}
                            onOk={(value) => this.setState({ jtzhid1: value })}
                            value={this.state.jtzhid1}
                        >
                            <List.Item arrow="horizontal">小区</List.Item>
                        </Picker>

                        <Picker
                            data={this.state.jtzhlist2}
                            cols={1}
                            onChange={(value) => this.setState({ jtzhid2: value })}
                            value={this.state.jtzhid2}
                        >
                            <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                        </Picker>

                        <Picker
                            data={this.state.jtzhlist3}
                            cols={1}
                            onChange={(value) => this.setState({ jtzhid3: value })}
                            value={this.state.jtzhid3}
                        >
                            <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                        </Picker>
                    </List>
                    <List.Item>
                        <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                    </List.Item>
                </Modal>
            </div>
        )
    }
}
