import './yktgbedit.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,ImagePicker,Toast,Modal,Picker} from 'antd-mobile';
import { YKT_URL, Axios } from '../../../utils/url';
import { SFZ_URL } from '../../../utils/fjyl';
import { BASE_URL} from '../../../utils/url';
import lrz from "lrz";
export default class YktmjqxsqEdit extends Component {
    constructor(props) {
        super(props);
        const list = this.props.location.query.list;
        const sqlist = this.props.location.query.tjlist;
        console.log(sqlist)
        this.state = {
            qxlx:'',
            qxlxText:'',
            qxmcText:'',
            qxmc:'',
            lxsjh:'',
            list,
            sqlist,
            qxList:'',
            qxmcList:[],
            isLoaded:false,
            files:[],
            multiple: false,
            yl:false,
            hasErrorPhone:false

        }
    }
    componentDidMount = () => {
      this.getlx();
    }

     //获取类型
  getlx = () => {
    var params = new URLSearchParams();
    Axios({
      method: 'post',
      url: BASE_URL + 'mjqxsqjl/getfjlx',
      data: params
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if(res.data.length >0){
          this.setState({
            qxList: res.data,
          });
        }

      }
    });

  }

  //选择权限类型
  handleFromChange = (e) => {

    const list = this.state.qxList

    for (var item in list) {
      if (list[item].value == e) { //item 表示Json串中的属性，如'name'
        var jValue = list[item];//key所对应的value
        console.log(jValue.label);
        // this.state.syrlxText = jValue.label;
        this.setState({
          qxlxText: jValue.label,
          qxlx: e
      }, () => {
          this.getqxlx();
      });
      }
    }
  }

  getqxlx = () =>{
    console.log(this.state.qxlx)

    const url = BASE_URL + 'mjqxsqjl/getzjlx';
    var params = new URLSearchParams();
    params.append("qxlx",this.state.qxlx);
    Axios({
      method: 'post',
      url: url,
      data: params
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if(res.data.length >0){
          this.setState({
            qxmcList: res.data,
          });
        }

      }
    });
  }
  handleCrzlxChange = (e) =>{
      const list = this.state.qxmcList
    var Text;
    for (var item in list) {
      if (list[item].value == e) { //item 表示Json串中的属性，如'name'
        var jValue = list[item];//key所对应的value
        Text = jValue.label;
        console.log(jValue.label);
        // this.state.syrlxText = jValue.label;
        this.setState({
          qxmcText: Text,
          qxmc: e
        });

      }
    }
    console.log(Text)
    for(var item in this.state.sqlist){
      console.log(this.state.sqlist[item].lymc)
      if(this.state.sqlist[item].lymc == Text){
        this.setState({
          qxmcText: '',
          qxmc: ''
        });
        Toast.info('您已经申请过该权限了，请重新选择', 3, null, false);
        return;
      }
    }

  }
    handleSubmit = e => {
      e.preventDefault()
      console.log('表单提交')
      console.log(this.state.files)
      // const { RYBH,RYXM,XBMC,YLXMC,MZMC,ZJBH,LXSJH,files} = this.state
      const url = BASE_URL+'mjqxsqjl/save';
      let params = new FormData();
      params.append('yktbh', this.state.list.yktzh);
      params.append('ryxm', this.state.list.sqyhxm);
      params.append('sqdd', this.state.qxmc);
      params.append('ddmc', this.state.qxmcText);
      params.append('zjbh', this.state.list.sfzh);
      params.append('lxdh', this.state.lxsjh);
      console.log(JSON.stringify(this.state.files));
      if(this.state.qxmcText==""){
        Toast.info('请填写权限名称', 3, null, false);
        return;
      }
      if(this.state.qxmc==""){
        Toast.info('请填写权限类型', 3, null, false);
        return;
      }
      if(this.state.files.length !==0){
        for(var i=0;i<this.state.files.length;i++){
          params.append("fjList",this.state.files[i].file);
          console.log(this.state.files[i].file)
        }
      }else{
        Toast.info('请先上传附件', 3, null, false);
        return;
      }
      if(this.state.lxsjh==""){
        Toast.info('请先填写手机号', 3, null, false);
        return;
      }

        Toast.loading("加载中...",0,null,true);
        Axios({
          headers: { 'Content-Type': 'multipart/form-data' },
          method: 'post',
          url: url,
          data: params,
          timeout:900000,
          //这部分非常重要，否则formdata会被转格式
          transformRequest: [function(){return params;}],
        }).then((res) => {
          console.log(res)
          Toast.hide();
          if (res.status === 200) {
            Toast.info(res.data.msg, 2, null, false);
          }
          this.props.history.go(-1)
        });

    }

    onChangeImage = (e)=>{
      this.setState({
        sfzList: e,
      });
    }


    onChange = (files, type, index) => {
      console.log(files, type, index);
        var str=[];
        if(type!="remove"){
        for(let i=0;i<files.length;i++){

            lrz(files[i].url, { quality: 0.1 }) .then((rst) => {
              console.log(rst)
              let file = new File([rst.file], this.genID(16)+"."+files[i].file.name.split(".")[1], { type: files[i].file.type, lastModified: Date.now() });
              var arr = [{ orientation: 1, url: rst.base64, file: file }];;
              str.push(arr[0])
              this.setState({
                files: str,
              })
            })

        }
      }else{
        this.setState({
        files,
      });
      }
    }
    genID = (length) => {
      return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
    }

    handleChange = (value) => {
      const str = value.replace(/\s*/g,"");
    console.log(str);
    if (value.replace(/\s/g, '').length !== 11) {
      this.setState({
        hasErrorPhone: true,
      });
    } else {
      this.setState({
        hasErrorPhone: false,
      });
    }

    this.setState((state, props) => {
      return {
        lxsjh: str,
      }
    },
      () => {
      })
    }
//手机输入错误提示
onErrorClickPhone = () => {
  if (this.state.hasErrorPhone) {
    Toast.info('请输入正确的手机号',1,null,false);
  }
}

    render() {
      console.log(this.state.list);
        const { qxlx,qxmc,files} = this.state
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >门禁权限申请详情</NavBar>
             <form onSubmit={this.handleSubmit}>
             <List >
             <div className="scfj" style={{marginTop:"50px",color:"#888",fontSize:"14px"}}>申请人基本信息</div>
             <InputItem
              value={this.state.list.sqyhxm}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>申请姓名</div></InputItem>

            <InputItem
              value={this.state.list.yktzh}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>一卡通号</div></InputItem>



            <Picker
              data={this.state.qxList}
              cols={1}
              onChange={this.handleFromChange}
              className="forss"
              value={qxlx}
              id="aaa"
            >
              <List.Item arrow="horizontal"><span className="error">*</span>权限类型</List.Item>
            </Picker>

            <Picker
              data={this.state.qxmcList}
              cols={1}
              onChange={this.handleCrzlxChange}
              className="forss"
              value={qxmc}
              extra="请先选择权限类型"
            >
              <List.Item arrow="horizontal"><span className="error">*</span>权限名称</List.Item>
            </Picker>


            <InputItem
              value={this.state.list.sfzh}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>证件编号</div></InputItem>

            <InputItem
              type="phone"
              value={this.state.lxsjh}
              onChange={this.handleChange}
              placeholder="请输入手机号"
              error={this.state.hasErrorPhone}
              onErrorClick={this.onErrorClickPhone}
            ><div ><span className="error">*</span>手机号</div></InputItem>
            {/* <div className="scfj">余额(元)</div>
            {this.setMapList()} */}
            {/* {this.setsfzMap()} */}
            <Modal
            visible={ this.state.yl }
            transparent
            footer={[{ text: '关闭', onPress: () => {this.setState({ yl: false })}} ]}
            >
              <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                  <img
                      alt="example"
                      style={{width: '100%',height:'100%' }}
                      src={SFZ_URL}
                  />
              </div>
            </Modal>
            <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
    <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>身份证正反面(<span onClick={()=>this.setState({ yl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={files}
              onChange={this.onChange}
              onImageClick={(index, fs) => console.log(index, fs)}
              selectable={files.length < 2}
              multiple={this.state.multiple}
              capture="camera"
            />


          </div>

            </List>
            <div className="formSubmit">
            <button className="submit" type="submit">
              申 请
            </button>
          </div>
             </form>

            </div>
        )
    }
}
