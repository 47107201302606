import React, { Component } from 'react'
import { BASE_URL, Axios } from '../../../utils/url.js'
import { SFZ_URL } from '../../../utils/fjyl';
import lrz from "lrz";
import { List, NavBar, InputItem, Toast, Button, Icon, ImagePicker, Modal ,Picker} from 'antd-mobile';
import './index.css'

export default class fwxxbd extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var fwxx = "";
        var buttonres
        if (this.props.location.query.res) {
            fwxx = this.props.location.query.fwxx;
            fwxx.fwbh = fwxx.fwbh.substr(fwxx.fwbh.lastIndexOf("_") + 1)
            buttonres = true
        } else {
            // fwxx = JSON.parse(localStorage.getItem("fwxx"));
            fwxx = { yhxm: "", hzsfzh: "", fwbh: "" }
            buttonres = false
        }
        this.state = {
            uname: userInfo.ryxm,
            sfzh: fwxx.hzsfzh,
            fwxx: fwxx,
            buttonres: buttonres,
            fwbh: fwxx.fwbh,
            // fwxx: fwxgxx.fwbh.substr(fwxgxx.fwbh.lastIndexOf("_") + 1,13),
            files: [],
            hasError: false,//身份证号数判断值
            hasFWError: false,
            jtzz: "",
            jtzhlist1: [],
            jtzhlist2: [],
            jtzhlist3: [],
            modalfwxz: false

        }

    }
    // 按钮函数
    fwbdButton = () => {
        console.log(this.state.fwbh);
        console.log(this.state.sfzh);
        console.log(this.state.files.length);
        if (this.state.fwbh == "") {
            Toast.info("请输入房屋编号", 1, null, false)
        } else if (this.state.sfzh == "") {
            Toast.info("请输入身份证号", 1, null, false)
        } else if (this.state.files.length == 0) {
            Toast.info("请上传个人身份证", 1, null, false)
        } else {
            const url = BASE_URL + 'grzy/profilefwbd'
            let params = new FormData();
            params.append("fwbh", this.state.fwbh);
            params.append("sfzh", this.state.sfzh);
            params.append("uname", this.state.uname);
            params.append("fjList", this.state.files[0].file);
            Axios({
                headers: { 'Content-Type': 'multipart/form-data' },
                method: 'post',
                url: BASE_URL + "grzy/profilefwbd",
                data: params,
                // transformRequest: [function () { return params; }],
            }).then((res) => {

                Toast.info(res.data.msg, 1, null, false)
                console.log(res.data.msg);
                this.props.history.goBack()
            })
        }



    }

    onChangeImg = (files, type, index) => {
        var str = [];
        if (type == "remove") {
            // var str = { files }
            this.setState({ files });
        } else if (type == "add") {
            // this.setState({ files:files });
            console.log('files[0]')
            console.log(files[0])
            lrz(files[0].url, { quality: 0.1 }).then((rst) => {
                console.log(rst)
                let newfile = new File([rst.file], files[0].file.name, { type: rst.file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: newfile }];
                str.push(arr[0])
                console.log(str);
                this.setState({ files: str, })
            })
        }
    }
    buttonres = () => {
        if (this.state.buttonres) {
            return null
        } else {
            return (<Button type="primary" onClick={this.fwbdButton}>确认绑定</Button>)
        }
    }
    imgres = () => {
        if (this.state.buttonres) {
            return null
        } else {
            return (
                <div>
                    <div className="scfj" style={{
                        fontSize: "14px",
                        color: "#888"
                    }}>上传附件</div>
                    <div>
                        <div style={{ paddingTop: "10px" }}>

                        </div>
                        <div className="scfj"><span style={{ color: "red" }}>*</span>身份证正面(<span onClick={() => this.setState({ yl: true })} style={{
                            color: "#108ee9",

                        }}>查看样例</span>)</div>
                    </div>
                    <ImagePicker
                        files={this.state.files}
                        onChange={this.onChangeImg}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.files.length < 1}
                        capture="camera"
                    />
                </div>
            )
        }
    }


    // 返回个人主页
    backgrzx = (e) => {
        return (
            this.props.history.go(-1)
        )
    }
    // 名字回调函数
    onChangename = (value) => {
        const str = value.replace(/\s*/g, "");
        this.setState({
            uname: str,
        });
    }
    //绑定身份证的onchange事件
    onChangeId = (value) => {
        const str = value.replace(/\s*/g, "");
        if (str.replace(/\s/g, '').length !== 18) {
            this.setState({
                hasError: true,
            });
        } else {
            this.setState({
                hasError: false,
            });
        }
        this.setState({
            sfzh: str,
        });
    }
    componentDidMount = () => {
        this.getjtzz("", "1");
    }
    async getjtzz(pid, type) {
        console.log(pid);
        console.log("获取家庭住址");
        let that = this;
        const url = BASE_URL + 'yktsqxx/getJtzz';
        var params = new URLSearchParams();
        params.append("pid", pid);
        params.append("type", type);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {

            if (res.status === 200) {
                console.log(res.data.data);
            console.log(type);
                if (type == "1") {
                    that.setState({
                        jtzhlist1: res.data.data
                    });
                } else if (type == "2") {
                    that.setState({
                        jtzhlist2: res.data.data
                    });
                } else if (type == "3") {
                    that.setState({
                        jtzhlist3: res.data.data
                    });
                }

            }
        });
    }
    jtzhclick = (e) => {
        console.log(e);
        if (e == "2") {
            if (this.state.jtzhid1 != "") {
                this.getjtzz(this.state.jtzhid1, "2");
            } else {
                Toast.info('请先选择一级单位', 3, null, false);
            }
        }
        if (e == "3") {
            if (this.state.jtzhid2 != "") {
                this.getjtzz(this.state.jtzhid2, "3");
            } else {
                Toast.info('请先选择二级单位', 3, null, false);
            }
        }
    }
    jadzmc = () => {
        const list = this.state.jtzhlist3
        for (var item in list) {
            if (list[item].value == this.state.jtzhid3) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                console.log(jValue.label);
                this.setState({
                    fwbh: jValue.label,
                    modalfwxz: false,
                });
                this.setState({
                    fwbh: jValue.label
                });
            }
        }
    }
    // 身份证号错误提示
    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.info('不足18位', 1, null, false);
        }
    }
    // 房屋绑定回调函数
    onChangeFW = (value) => {
        const str = value.replace(/\s*/g, "");
        console.log(value.substr(0, 2))
        // if (value.substr(0, 2) == "01" || value.substr(0, 2) == "03" || value.substr(0, 2) == "05") {
        //     this.setState({ hasFWError: false })
        // } else {
        //     this.setState({ hasFWError: true })
        // }
        this.setState({
            fwbh: str
        })
        console.log(str)
    }
    showModal = key => (e) => {


        if (this.state.sftzText == "否" && key == "modal1") {
            return;
        }
        this.setState({
            [key]: true,
        });
    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    render() {
        const { files } = this.state
        return (
            <div>
                <NavBar
                    icon={<Icon type="left" />}
                    mode="dark"
                    onLeftClick={this.backgrzx}
                >房屋绑定信息</NavBar>
                <div className="grid-css"><List>
                    <InputItem
                        editable={false}
                        type="text"
                        placeholder="请输入姓名"
                        value={this.state.uname}
                        onChange={this.onChangename}
                    >姓名:</InputItem>
                    <InputItem
                        maxLength="18"
                        type="text"
                        onChange={this.onChangeId}
                        error={this.state.hasError}
                        onErrorClick={this.onErrorClick}
                        value={this.state.sfzh}
                        placeholder={'请输入身份证号'}
                    >身份证号:</InputItem>
                    <InputItem
                        type="text"
                        onChange={this.onChangeFW}
                        error={this.state.hasFWError}
                        onErrorClick={this.onFWErrorClick}
                        value={this.state.fwbh}
                        placeholder={'房号：例如08-001-03-0018'}
                        // extra={<Button type="ghost" size="small" inline onClick={this.showModal('modalfwxz')}>{"房屋选择"}</Button>}
                    >房屋信息:</InputItem>
                    {/*  */}
                    {this.imgres()}
                    {/* <ImagePicker
                        files={files}
                        length='1'
                        onChange={this.onChangeImg}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files.length < 1}
                        capture="camera"
                    /> */}
                </List>
                    <div className="changeProfiel">
                        {this.buttonres()}
                    </div>
                </div>
                <Modal
                    visible={this.state.yl}
                    transparent
                    footer={[{ text: '关闭', onPress: () => { this.setState({ yl: false }) } }]}
                >
                    <div style={{ marginTop: 50, transform: this.state.transStyle, display: 'flex', alignItems: 'center' }}>
                        <img
                            alt="example"
                            style={{ width: '100%', height: '100%' }}
                            src={SFZ_URL}
                        />
                    </div>
                </Modal>
                <Modal
                    popup
                    visible={this.state.modalfwxz}
                    onClose={this.onClose('modalfwxz')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '详情信息'} >
                        <Picker
                            data={this.state.jtzhlist1}
                            cols={1}
                            onOk={(value) => this.setState({ jtzhid1: value })}
                            value={this.state.jtzhid1}
                        >
                            <List.Item arrow="horizontal">小区</List.Item>
                        </Picker>

                        <Picker
                            data={this.state.jtzhlist2}
                            cols={1}
                            onChange={(value) => this.setState({ jtzhid2: value })}
                            value={this.state.jtzhid2}
                        >
                            <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                        </Picker>

                        <Picker
                            data={this.state.jtzhlist3}
                            cols={1}
                            onChange={(value) => this.setState({ jtzhid3: value })}
                            value={this.state.jtzhid3}
                        >
                            <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                        </Picker>
                    </List>
                    <List.Item>
                        <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                    </List.Item>
                </Modal>
            </div>
        )
    }
}
