import './ygkbksq.css'
import React, { Component } from 'react'
import { NavBar,Icon,List, Modal,Toast,Card, WingBlank, WhiteSpace } from 'antd-mobile';
import { YGK_URL, Axios } from '../../../utils/url';
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class Ygkbksq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isLoaded: false,

        }
    }
    componentDidMount = () => {
        this.getList();
    }

    async getList() {
      console.log("执行获取数据");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        let sfzh="220104196812140615";
        const url = YGK_URL+'ygkbksq/page';
        var params = new URLSearchParams();
        params.append("sfzh", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    list: res.data.records,
                    isLoaded: true
                });
            }
        });
    }

    handleSq=()=>{
        // this.props.history.push('./ygkgbedit');
        this.Inquire()
    }

    handleJump=(item)=>{
        this.props.history.push({ pathname : "./ygkgbshow" , query : { bkid : item.bkid}});
    }

    async Inquire() {
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        let sfzh="220104196812140615";
          const url = YGK_URL+'ygkbksq/inquire';
          var params = new URLSearchParams();
          params.append("sfzh", ryobject.sfzh);
          await Axios({
              method: 'post',
              url: url,
              data: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                if(!res.data.falg){
                  this.props.history.push('./ygkgbedit');
                }else{
                    if(res.data.msg!=""){
                      Toast.info(res.data.msg, 3, null, false);
                    }else{
                      Toast.info('您已经提交了申请，正在审核中,请耐心等待', 3, null, false);
                    }

                }
            }
          });
      }


    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.list.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={() => this.handleJump(item)}>
                    <Card.Header
                        title={item.ryxm}
                        thumb={ygk}
                        extra={<span>{'类型：' + item.lxmc}</span>}
                    />
                    <Card.Body>
                        <div>{item.zhlxmc}</div>
                    </Card.Body>
                    <Card.Footer content={'申请时间：' + item.firsttime.slice(0,10) } extra={<div>{'当前状态：' + item.dqztmc}</div>}  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
                // <Item multipleLine extra={item.dqztmc} key={key} onClick={() => this.handleJump(item)}>
                //     {item.ryxm} <Brief>{item.firsttime}</Brief>
                //     <Brief>{item.fkzxmc}</Brief>
                // </Item>
            ))
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
              rightContent={[
                <div onClick={this.handleSq}>申请</div>
              ]}
             onLeftClick={() => this.props.history.goBack()}
            >员工卡补卡申请</NavBar>
            <div className="divmagin">
            <div className="scfj">申请说明</div>
            <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>
            1、员工卡补卡申请提交后，预计三个工作日内审核完成。<br></br>
                2、补卡成功后，请本人带上身份证原件到服务大厅领取。</p>
                    {this.setMapList()}
                </div>
            </div>
        )
    }
}
