import './dhfjf.css'
import React, { Component } from 'react'
import { NavBar, Icon, SearchBar, List, Toast, WingBlank,WhiteSpace,Card, Radio, Button, InputItem, Picker } from 'antd-mobile';
import { BASE_URL, Axios, FORMAT } from '../../../utils/url'
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;

export default class Dhfcx extends Component {
    constructor(props) {
        super(props);
        var userInfo = "";
        const openid = this.props.location.search;
        if (openid.length > 0) {
            var open = openid.split("&");
            var openidArr = open[0].split("=");
            var openid1 = openidArr[1];
            var params = new URLSearchParams();
            localStorage.setItem("openID", openid1);
            params.append('openid', openid1);
            Axios({
                method: 'post',
                url: BASE_URL + "bRyzcController/getUserInfo",
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then((res) => {
                console.log(res)
                localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.setState({
                    yhxm: userInfo.ryxm,
                    yhid: userInfo.sfzh
                })
            });

        }
        this.state = {
            jfdjList: [],
            zje: "",
            yeList:[],
            czje: "",
            isLoad: false,
            dateList: [],
            list:[],
            value: "",
            telecode: "",
            isMxCk: "",
            yhid: "",
            jtzz: "",
            username: "",
            dataflag: false,
            nowdate: "",
            jsrqFlag: true,
            sValue:'',
            mouth:'',
            dqsj:''
        }
    }
    componentDidMount = () => {
        this.getMonthDay()
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                const url = BASE_URL + 'bRyzcController/getUserInfo';
                Axios({
                    method: 'post',
                    url: url,
                    data: params,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        yhxm: userInfo.ryxm,
                        yhid: userInfo.sfzh
                    })

                });

            }
        } else {
            this.setState({
                yhxm: userInfo.ryxm,
                yhid: userInfo.sfzh
            })

        }
        console.log(userInfo)
        this.getClglList()
    }

    //获取列表
    async getClglList(e) {
        console.log(e)
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const url = BASE_URL + 'systeleuserController/getdjfList';
        var params = new URLSearchParams();
        // params.append("sfzh", this.state.yhid);
        if(!e){
            e = this.state.dateList[0].label;
            this.setState({
                dqsj:this.state.dateList[0].label
            })
        }
        let year = e.substring(0,4);
        let mouth = e.substring(5,6);
        this.setState({
            mouth:mouth
        })
        if (userInfo) {
            console.log(userInfo.sfzh);
            params.append("sfzh", userInfo.sfzh);
            params.append("year", year);
            params.append("mouth", mouth);
        }
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {

                let date = new Date(Date.now())
                let nowDate = {
                    year: date.getFullYear(), // 年
                    month: date.getMonth() + 1, // 月份
                    day: date.getDate(), // 当前日期前一天(昨天)
                  }
                  console.log(nowDate.month);
                if(mouth == nowDate.month){
                    //进来初始化

                    if(res.data.list1.length > 0){
                        this.setState({
                            yeList: res.data.list1,
                            isLoad: true,
                        });
                    }else{
                        alert("本人信息与电话号信息不一致，请到大厅确认绑定后再使用该功能");
                    }

                }else{
                    if (res.data.list.length > 0) {
                        this.setState({
                            jfdjList: res.data.list,
                            zje: res.data.list1[0].payremain,
                            isLoad: true,
                        });

                    } else {
                        this.setState({
                            jfdjList: res.data.list,
                            isLoad: true
                        });
                        alert("本人信息与电话号信息不一致，请到大厅确认绑定后再使用该功能");
                    }
                }

            }
        }).catch(e => {
            Toast.hide();
            console.log(e)
        });
    }
    handleFromChange = (e)=>{
       this.setState({
           dqsj:this.state.dateList[e].label
       })
       this.getClglList(this.state.dateList[e].label);
    }
    setMapList = (e) => {
        if(this.state.isLoad){
           if(this.state.jfdjList == ""){
            return this.state.yeList.map((item, key) => (

                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={()=>this.handleJump(item.usercode,this.state.mouth)}>
                    <Card.Header
                        title={this.state.dqsj+"账单"}
                        thumb={ygk}
                        extra={<span>0.00元</span>}
                    />
                    <Card.Body>
                        <div>当前余额：{item.payremain}</div>
                    </Card.Body>
                    <Card.Footer content="" style={{color:"blue"}} extra={<div>查看明细</div>} />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
    ))
           }else{
            return this.state.jfdjList.map((item, key) => (

                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={()=>this.handleJump(item.usercode,this.state.mouth)}>
                    <Card.Header
                        title={this.state.dqsj+"账单"}
                        thumb={ygk}
                        extra={<span>{item.totalmoney}元</span>}
                    />
                    <Card.Body>
                        <div>当前余额：{this.state.zje}</div>
                    </Card.Body>
                    <Card.Footer content="" style={{color:"blue"}} extra={<div>查看明细</div>} />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
    ))
           }

        }
    }
    handleJump(dhhm,mouth){
        console.log(dhhm,mouth)
        this.props.history.push({ pathname: '/dhfcxmx', query: { dhhm: dhhm,mouth:mouth } });
    }
// 获取昨天和上一个月
getMonthDay() {
    let oneDay = 24 * 60 * 60 * 1000
    let date = new Date(Date.now() - oneDay)// 昨天
    let nowDate = {
      year: date.getFullYear(), // 年
      month: date.getMonth() + 1, // 月份
      day: date.getDate(), // 当前日期前一天(昨天)
    }
    let startYear = '';
    let startMonth = '';
    let i = 0;

    for(let a = 0;a<12;a++){

        startYear=nowDate.year;
        startMonth= nowDate.month - a;

        if (startMonth <= 0) {
        startYear = startYear - 1
        startMonth = 13

        i = i+1;
        startMonth = startMonth -i;
        }
        this.state.dateList.push({ value: a, label: `${startYear}年${startMonth}月` })
    }

    this.state.list.push(this.state.dateList)
  }


    render() {

        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}

                >电话费缴费</NavBar>
                <div style={{ marginTop: "2.89rem" }}>
                    <Picker
                        data={this.state.list}
                        title="选择月份"
                        cascade={false}
                        extra="请选择(可选)"
                        value={this.state.sValue}
                        // onChange={v => this.setState({ sValue: v })}
                        onChange={this.handleFromChange}
                        onOk={v => this.setState({ sValue: v })}
                    >
                        <List.Item arrow="horizontal">月份选择</List.Item>
                    </Picker>
                </div>
                <div>

                    {/* <SearchBar placeholder="请输入电话号码" maxLength={14}
                        showCancelButton={true} onSubmit={this.onSubmit} cancelText={"查询"} onCancel={this.onSubmit} /> */}



                    <List renderHeader={() => ''} >
                        {this.setMapList()}
                    </List>
                </div>
            </div>
        )
    }
}
