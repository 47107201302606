import React, { Component } from 'react'
import { List, NavBar, InputItem, Button, WhiteSpace, Toast, Modal } from 'antd-mobile';
import { BASE_URL, CLYDD, Axios } from '../../utils/url.js'
import './grzx.css'
const Item = List.Item;
export default class clgl extends Component {
    constructor(props) {
        super(props);
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.state = {
            sqr: userInfo.ryxm,
            sqdw: userInfo.rysx20120716p0026,
            sfzh: userInfo.sfzh,
            zz: userInfo.rysx20120716p0051,
            ektbh: userInfo.rysx20120716p0046,
            sqrid: userInfo.ryid,
            sqdwbm: '',
            phone: userInfo.rysx20120716p0048,
            bgdh: userInfo.rysx20120716p0049,
            jtdh: userInfo.rysx20120716p0050
        }

    }
    jiebang = () => {
        Modal.alert('确认解除微信绑定？', '确认解除微信绑定？', [
            { text: '取消', onPress: () => console.log('下次一定') },
            {
                text: '确定',
                onPress: () =>
                    new Promise((resolve) => {
                        var params = new URLSearchParams();
                        params.append('sfzh', this.state.sfzh);
                        Toast.loading("解绑中...", 0, null, true);
                        Axios({
                            method: 'post',
                            url: BASE_URL + "bRyzcController/wxjb",
                            data: params,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }).then((res) => {
                            Toast.hide();
                            console.log(res.data);
                            if (res.data) {
                                window.location.href = "http://www.txnbmfw.com/txnwxgzh/page/index.html";
                            }
                        }).catch((e) => {
                            alert(JSON.stringify(e))
                        })
                    }),
            },
        ])

    }
    // 事件绑定
    xggrxx = (e) => {
        return (
            this.props.history.push('/xggexx')
        )
    }
    fwxxbd = (e) => {
        return (
            this.props.history.push('/fwxxbdlist')
        )
    }
    jfjl = (e) => {
        return (
            this.props.history.push('/jfjl')
        )
    }
    // sysm = (e) =>{
    //     return (
    //         this.props.history.push('/sysm')
    //     )
    // }
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    xgmm() {
        console.log(this.isWeiXin());
        if (this.isWeiXin()){
            return null;
        }else{
            return <Item arrow="horizontal" onClick={() => { this.xggrxx() }}>修改个人密码</Item>
        }
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                <NavBar
                    mode="dark"
                >个人中心</NavBar>
                <div className="grid-css">
                    <List renderHeader={() => ''} >
                        <InputItem
                            type="money"
                            value={this.state.sqr}
                            editable={false}
                        ><div>姓名</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.phone}
                            editable={false}
                        ><div>手机号</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.sfzh}
                            editable={false}
                        ><div>身份证号</div></InputItem>
                    </List>
                    <List >
                        {this.xgmm()}
                        <Item arrow="horizontal" onClick={() => { this.fwxxbd() }}>房屋绑定信息</Item>
                        <Item arrow="horizontal" onClick={() => { this.jfjl() }}>个人缴费记录</Item>
                        {/* <Item arrow="horizontal" onClick={() => { this.sysm() }}>使用说明</Item> */}
                    </List>
                </div>

                <div className="loginButton"><Button type="primary" onClick={this.jiebang}>解除微信绑定</Button><WhiteSpace /></div>
                <div style={{fontSize: "14px",color:"rgb(243 75 98)",textAlign:"center"}} >温馨提示：如果个人信息有误，请解绑后重新绑定</div>
            </div>
        )
    }
}
