import React, { Component } from 'react'
import { BASE_URL, JC_URL, Axios } from '../../../utils/url'
import { NavBar, InputItem, List, DatePicker, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;
const cxList = [
    {
        label: '预约成功',
        value: 'success',
    },
    {
        label: '预约取消',
        value: 'cencal',
    },
];

export default class Jccx extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yyzt: '',
            cgList: []
        }
    }

    //进页面加载
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        this.setState({
            ryxm: userInfo.ryxm,
            sqdw: userInfo.rysx20120716p0026,
            sfzh: userInfo.sfzh,
            zz: userInfo.rysx20120716p0051,
            ektbh: userInfo.rysx20120716p0046,
            sqrid: userInfo.ryid,
            phone: userInfo.rysx20120716p0048,
            userInfo: userInfo,
            dwbm: userInfo.dwbm,

        })
    }
    //餐厅选择
    chooseYyzt = (e) => {
        this.setState({
            yyzt: e
        })
        const url = JC_URL + 'ctyyxx/getYydata';
        var params = new URLSearchParams();
        params.append('sfzh', this.state.sfzh);
        params.append('yyzt', e);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            if (res.data.length > 0) {
                // this.state.cgList  = res.data
                this.setState({
                    cgList: res.data,
                }, () => {
                    this.setMapList();
                });
            } else {
                this.setState({
                    cgList: []
                }, () => {
                    this.setMapList();
                });
            }
        });
    }
    setMapList = () => {
        const list = this.state.cgList

        if (this.state.yyzt[0] === 'success') {
            if (list.length > 0) {
                return list.map((item, key) => (
                    <Item multipleLine extra={item.ctmc}>
                        {item.xm} <Brief>{item.yyrq}</Brief><Brief>{item.yylx}</Brief>
                    </Item>
                ))
            }

        } else if (this.state.yyzt[0] === 'cencal') {
            if (list.length > 0) {
                return list.map((item, key) => (
                    <Item multipleLine extra={item.ctmc}>
                        {item.xm} <Brief>{item.yyrq}</Brief><Brief>{item.yylx}</Brief>
                    </Item>
                ))
            }
        }


    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >预约查询</NavBar>
                <div style={{ paddingTop: "45px" }}>
                    <Picker
                        data={cxList}
                        cols={1}
                        title="请选择预约状态"
                        extra="请选择预约状态"
                        onChange={this.chooseYyzt}
                        className="forss"
                        value={this.state.yyzt}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>查询状态</List.Item>
                    </Picker>
                    <div>
                        {this.setMapList()}

                    </div>
                </div>
            </div>
        )
    }
}
