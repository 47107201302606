import './jfqr.css'
import React, { Component } from 'react'
import { NavBar, Icon, Modal, Toast } from 'antd-mobile'
import { BASE_URL, Axios } from  '../../utils/url'
import Zfcg from '../../assets/images/zfcg.png'
import Wzf from '../../assets/images/wzf.png'
export default class Jfqr extends Component {
    constructor(props) {
        super(props);
        const merOrderId = this.props.location.query.merOrderId;
        // alert(merOrderId);
        this.state = ({
            merOrderId: merOrderId,
            msg: "",
            isPay: false,
            payStatus: "支付成功",
            nopayStatus: "未支付",
            time:12000
        });
    }
    chaxun=()=>{
        //差一个查询接口
    }
    //调用接口查询是否已支付
    timeTransition = (ms) => {
        if (this.state.isPay) {
            this.setState({
                msg: "已支付成功"
            });
        } else {
            let maxtime = ms / 1000; //按秒计算
            let timer = null;
            let _this = this;
            setTimeout(function f() {
                if (maxtime >= 0) {
                    let minutes = Math.floor(maxtime / 60);
                    let seconds = Math.floor(maxtime % 60);
                    minutes < 10 ? minutes = '0' + minutes : minutes = minutes;
                    seconds < 10 ? seconds = '0' + seconds : seconds = seconds;
                    let msg = "请在" + minutes + "分" + seconds + "秒内完成支付";
                    _this.setState({
                        msg
                    });
                    --maxtime;
                } else {
                    _this.setState({
                        msg: '订单已过期，请重新下单'
                    });
                    clearTimeout(timer);
                    return;
                }
                timer = setTimeout(f, 1000);
            }, 1000);
            setTimeout(function f() {
                if (_this.state.isPay) {
                    _this.setState({
                        msg: '已支付成功'
                    });
                    clearTimeout(timer);
                    return;
                }else{
                    _this.getPayStatus();
                    timer = setTimeout(f, 5000);
                }

            }, 5000);
        }

    }
    componentDidMount() {
        this.timeTransition(this.state.time);//根据接口返回的时间
        this.setResut();
    }
    //返回页面刷新列表
    getPayStatus = () => {

        const url = BASE_URL+'bylxdxxController/getPayStatus';
        var params = new URLSearchParams();
        params.append('merOrderId', this.state.merOrderId);
        Toast.loading("加载中...",0,null,true);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            Toast.hide();
            if(res.status=='200'){
                if(res.data.flag){
                    this.setState({
                        isPay:true
                    })
                }
            }
        });
    }
    quxiao=()=>{
        console.log("取消");
    }

    setResut = () => {
        Modal.alert('是否已支付？', '是否已支付？', [
            { text: '取消', onPress: () => {this.quxiao()} },
            {
                text: '已支付',
                onPress: () =>
                    new Promise((resolve) => {
                        console.log("已支付");
                        setTimeout(resolve, 500);

                    }),
            },
        ])
    }
    //如果回写失败，自动调用查询银联查询订单信息获取支付状态回写业务剩下逻辑跟上述一致
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>

                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >缴费确认</NavBar>
                <div className='divmagin'>
                    <div className="djsClass">
                        {this.state.msg}
                    </div>
                    <div className='djsClass'>
                        <img src={this.state.isPay ? Zfcg : Wzf} className="zfcglogo"></img>
                        <p>{this.state.isPay ? this.state.payStatus : this.state.nopayStatus}</p>
                    </div>

                </div>
            </div>
        )
    }
}

