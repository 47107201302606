import React, { Component } from 'react'
import { Axios, JC_URL } from '../../../utils/url'
import { NavBar, InputItem, List, DatePicker, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

// Make sure that in `time` mode, the maxDate and minDate are within one day.
const minDate = new Date(nowTimeStamp);
const maxDate = new Date(nowTimeStamp + 86400000);
export default class Jcsq extends Component {
    constructor(props) {
        super(props)
        const cardList = this.props.location.query.cardList;
        this.state = {
            lxList: [],
            ctList: [],
            ctData:[],
            cardList:cardList,
            date: '',
            ctVal: '',
            lxVal: '',
            cardVal: '',
            dqzt: '',
            day: '',
            gywc: '',
            gyz: '',
            gyzc: '',
            note: '',
            year_month: '',
            yysjwc: '',
            yysjz: '',//早餐
            yysjzc: '',//中餐
            zcygk: '',
            zcykt: '',
            ctxxList: '',
            ctmc: ''
        }
    }

    //进页面加载
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            ryxm: userInfo.ryxm,
            sqdw: userInfo.rysx20120716p0026,
            sfzh: userInfo.sfzh,
            zz: userInfo.rysx20120716p0051,
            ektbh: userInfo.rysx20120716p0046,
            sqrid: userInfo.ryid,
            phone: userInfo.rysx20120716p0048,
            userInfo: userInfo,
            dwbm: userInfo.dwbm,
        });

        this.getctmc();
    }

    getctmc = () =>{
        const url = JC_URL + 'ctyyxx/getCtmc';
        var params = new URLSearchParams();
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            if (res.data.length > 0) {
                this.state.ctList = res.data
                this.state.ctData = res.data;
            }
        });
    }
    //时间选择
    chooseDate = (e) => {
        this.getctmc()
        var date = new Date(e);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
        * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
        * */
        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        // 拼接
        let year_month = year + month;
        this.setState({
            date: e,
            ctVal: '',
            cardVal: '',
            lxVal: '',
            yyrq:year+'-'+month+'-'+day
        })
        const url = JC_URL + 'ctyyxx/getCtxxxx';
        var params = new URLSearchParams();
        params.append('year_month', year_month);
        params.append('day', day);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            if (res.data.length > 0) {
                let ctList = this.state.ctList;
                let ctidArr = res.data;
                for (let i = 0; i < ctList.length; i++) {
                    const Index = ctidArr.findIndex((item) => { return item.ctid === ctList[i].value })
                    if (Index > -1) {
                        this.state.ctList.splice(i, 1);
                    }
                    // 前两个数据的key值相同，打印出对应的下标 ，后面找不相同的key  输出-1
                }
            }else{
                this.setState({
                    ctList:this.state.ctData
                })
            }
        });
    }
    //餐厅选择
    chooseYyct = (e) => {
        if (this.state.date === '') {
            Toast.fail('请先选择预约时间');
            return;
        } else {
            const url = JC_URL + 'ctyyxx/getCtxx';
            var params = new URLSearchParams();
            params.append('ctid', e);
            Axios({
                method: 'post',
                url: url,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {
                if (res.data.length > 0) {
                    this.state.dqzt = res.data[0].dqzt;
                    this.state.ctmc = res.data[0].ctmc;
                    this.state.gywc = res.data[0].gywc;
                    this.state.gyz = res.data[0].gyz;
                    this.state.gyzc = res.data[0].gyzc;
                    this.state.yysjwc = res.data[0].yysjwc;
                    this.state.yysjz = res.data[0].yysjz;
                    this.state.yysjzc = res.data[0].yysjzc;
                    this.state.zcygk = res.data[0].zcygk;
                    this.state.zcykt = res.data[0].zcykt;

                    var date = new Date(this.state.date);
                    var year = date.getFullYear();
                    /* 在日期格式中，月份是从0开始的，因此要加0
                    * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                    * */
                    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                    // 拼接
                    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                    var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

                    let year_month = year + month + day;
                    let hour_min = hour.toString() + minute.toString();
                    //当前时间
                    var dateNow = new Date();
                    var yearNow = dateNow.getFullYear();
                    /* 在日期格式中，月份是从0开始的，因此要加0
                    * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                    * */
                    var monthNow = dateNow.getMonth() + 1 < 10 ? "0" + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1;
                    // 拼接
                    var dayNow = dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate();
                    let year_monthNow = yearNow + monthNow + dayNow;
                    if (year_monthNow === year_month) {
                        // if(this.state.gywc !=='1'){
                        //     this.state.lxList.splice(2,1);
                        // }
                        // if(this.state.gyzc !=='1'){
                        //     this.state.lxList.splice(1,1);
                        // }
                        // if(this.state.gyz !=='1'){
                        //     this.state.lxList.splice(0,1);
                        // }
                        this.state.lxList.splice(0)
                        if (hour_min < this.state.yysjz.replace(':', '') && this.state.gyzc === '1') {
                            let zcArr = {}
                            zcArr['label'] = '早';
                            zcArr['value'] = '早';
                            this.state.lxList.push(zcArr)
                        }
                        if (hour_min < this.state.yysjzc.replace(':', '') && this.state.gyz === '1') {
                            let zcArr = {}
                            zcArr['label'] = '中';
                            zcArr['value'] = '中';
                            this.state.lxList.push(zcArr)
                        }
                        if (hour_min < this.state.yysjwc.replace(':', '') && this.state.gywc === '1') {
                            let zcArr = {}
                            zcArr['label'] = '晚';
                            zcArr['value'] = '晚';
                            this.state.lxList.push(zcArr)
                        }
                    } else {
                        this.state.lxList = [{
                            label: '早',
                            value: '早',
                        },
                        {
                            label: '中',
                            value: '中',
                        },
                        {
                            label: '晚',
                            value: '晚',
                        }]
                        if (this.state.gywc !== '1') {
                            this.state.lxList.splice(2, 1);
                        }
                        if (this.state.gyzc !== '1') {
                            this.state.lxList.splice(1, 1);
                        }
                        if (this.state.gyz !== '1') {
                            this.state.lxList.splice(0, 1);
                        }
                    }
                    this.setState({
                        ctVal: e
                    })
                }

            });
        }
    }
    //用卡类型
    chooseYklx = (e) => {
        if (this.state.ctVal !== '') {
            if (e[0] === 'ykt' && this.state.zcykt === '1') {
                this.setState({
                    cardVal: e
                })
            } else if (e[0] === 'ygk' && this.state.zcygk === '1') {
                this.setState({
                    cardVal: e
                })
            } else {
                let msg = '';
                if (e[0] === 'ykt') {
                    msg = '一卡通';
                } else if (e[0] === 'ygk') {
                    msg = '一卡通';
                }
                Toast.fail('该餐厅暂不支持' + msg + '预约！')
            }

        } else {
            // Toast.error('请先选择预约餐厅！');
            Toast.fail('请先选择预约餐厅！')
            return;
        }

    }

    //预约类型  早  中  晚
    chooseYylx = (e) => {
        // toLocaleTimeString()   根据本地时间格式，把 Date 对象的时间部分转换为字符串。
        let time = this.state.date;
        if (time !== '' && this.state.ctVal !== '') {
            const url = JC_URL + 'ctyyxx/checkYy';
            let params = new FormData()
            params.append('sfzh', this.state.sfzh);
            params.append('yyrq', this.state.yyrq);
            params.append('yylx', e);
            Axios({
                method: 'post',
                url: url,
                data: params,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {
                if(res.data.length>0){
                    Toast.fail('您在该时间已预约过' + e + '餐了！')
                    this.setState({
                        date: '',
                        ctVal: '',
                        cardVal: '',
                        lxVal: '',
                        yyrq:''
                    })
                }else{
                    this.setState({
                        lxVal: e
                    })
                }
            });

        } else {
            Toast.fail('请先选择预约时间和预约餐厅')
        }



    }

    handleSubmit = e => {
        e.preventDefault()
        if (this.state.ryxm && this.state.sfzh && this.state.phone && this.state.date && this.state.ctVal && this.state.lxVal && this.state.cardVal) {
            const url = JC_URL + 'ctyyxx/saveData';
            let params = new FormData()
            params.append('xm', this.state.ryxm);
            params.append('sfz', this.state.sfzh);
            params.append('phone', this.state.phone);
            params.append('ctid', this.state.ctVal);
            params.append('ctmc', this.state.ctmc);
            params.append('yyrq', this.state.yyrq);
            params.append('yylx', this.state.lxVal);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                headers: { 'Content-Type': 'multipart/form-data' },
                method: 'post',
                url: url,
                data: params,
                timeout: 900000, //这个就是重点
                //这部分非常重要，否则formdata会被转格式
                // transformRequest: [function () {return params;}],
            }).then((res) => {
                Toast.hide();
                if (res.data.flag&& res.data.msg == "预约成功!") {

                    // Toast.info(res.data.msg)
                    Toast.info(res.data.msg, 2, null, false);
                    //
                    let that = this;
                    that.props.history.go(-1)
                }
            });
        } else {
            Toast.fail('请完善表单之后再提交')
        }
    }
    render() {
        return (

            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >预约申请</NavBar>

                <form onSubmit={this.handleSubmit}>
                    <List renderHeader={() => '预约信息'} className="my-list">
                        <InputItem value={this.state.ryxm} >
                            <div ><span className="error">*</span>姓名</div>
                        </InputItem>
                        <InputItem value={this.state.sfzh} >
                            <div ><span className="error">*</span>身份证号</div>
                        </InputItem>
                        <InputItem value={this.state.phone} onChange={(value) => this.setState({ phone: value })}>
                            <div ><span className="error">*</span>手机号</div>
                        </InputItem>
                        <DatePicker
                            mode="date"
                            title="请选择预约日期"
                            extra="请选择预约日期"
                            value={this.state.date}
                            onChange={this.chooseDate}
                            minDate={minDate}
                            maxDate={maxDate}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约时间</List.Item>
                        </DatePicker>
                        <Picker
                            data={this.state.ctList}
                            cols={1}
                            title="请选择预约餐厅"
                            extra="请选择预约餐厅"
                            onChange={this.chooseYyct}
                            className="forss"
                            value={this.state.ctVal}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约餐厅</List.Item>
                        </Picker>
                        <Picker
                            data={this.state.cardList}
                            cols={1}
                            title="请选择用卡类型"
                            extra="请选择用卡类型"
                            onChange={this.chooseYklx}
                            className="forss"
                            value={this.state.cardVal}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>用卡类型</List.Item>
                        </Picker>


                        <Picker
                            data={this.state.lxList}
                            cols={1}
                            title="请选择预约类型"
                            extra="请选择预约类型"
                            onChange={this.chooseYylx}
                            className="forss"
                            value={this.state.lxVal}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约类型</List.Item>
                        </Picker>
                    </List>



                    <div className="formSubmit">
                        <button className="submit" type="submit">
                            申 请
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}
