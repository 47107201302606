import React, { Component } from 'react'
import { NEW_YGK_URL, Axios } from '../../../utils/url'

import {DatePicker,Picker,Tabs,NavBar, InputItem, List, TextareaItem, Radio, Button, Toast, ImagePicker, Icon, Modal, PickerView } from 'antd-mobile';
import './yysq.css'
const nowTimeStamp = Date.now();

const tabs = [
    { title: "预约基本信息" },
    { title: '人员信息' }
];
const Item = List.Item;
const Brief = Item.Brief;
export default class YysqViewQr extends Component {
    constructor(props) {
        super(props);
        let isBgBool = false;
        var type= this.props.location.query.type
        let isViewedit = false;


     this.state = {
            editviewable:isViewedit,
            infotype:type,
            errormsg:'',
            yyrq: '',
            headData:{
                note: "" ,
                fDjlx:'0003',
                reservationId  :"",
                lntervieweeCall:"",//联系电话
                lntervieweeType:"",//*拜访对象类型：
                lntervieweeName:"",//联系人
                positionCode:"",//出入地方
                fZzjg:'01',  //组织机构
                reservationTime:"",     //出入申请时间
                visitType:"",//来访类型
                subjectMatter:"",//拜访事由
                lntervieweeAddress:"",//拜访地址
                createUserId:"",//申请人
                createUserName:"",//姓名
                reservationState:'待提交',
            },
            dataFormList:[],
            ryxxlist: [],
            isLoaded: false,
            merOrderId: "",
            isBg: isBgBool,
            value: "",
            bglx:"",
            showxs:false,
            showqsbg:false,
            xssjh:"",
            locationzd:"" ,
            lfcode:0,
            bfcode:0,
            crcode:[0],
            sexcode:[0],
            ofdjlx: [{ value: '0', label: '个人' }, { value: '1', label: '单位' }],
            lfdjlx: [{ value: '0', label: '个人' }, { value: '1', label: '单位' }],
            xb:[{ value: '0', label: '男' }, { value: '1', label: '女' }],
            yhxx:{},
            sizeMX:0,
            returnAddRowFunList: [
	            // {
	            //     id: "1"
	            // },
	        ]

        }

    }
    handleItemDelete(index) {
		// 拷贝一份，不要直接去修改state里面的数据
		const list = [...this.state.returnAddRowFunList];
        list.splice(index-1, 1)
        this.state.returnAddRowFunList=list
		this.setState({
			returnAddRowFunList: list
		})
        this.returnAddRowFun()
        this.state.sizeMX=
        this.setState({
			sizeMX: list.length+1
		})
        this.forceUpdate()
	}
    returnAddRowFun = () => {
        let { returnAddRowFunList, cacheTypeList } = this.state;
        return (

            returnAddRowFunList && returnAddRowFunList.length && returnAddRowFunList.map(item => {
                return (
                    <React.Fragment key={item.id}>

                        {
                            (

                                <div>
                                <List renderHeader={() => `人员信息${item.id}`} >
                                <InputItem
                                        type="text" placeholder ="请输入访客姓名"
                                        value={this.state[`visitorName${item.id}`]}
                                        editable={this.state.editviewable}
                                        ref={el => this.labelFocusInst = el}
                                        onChange={(e) => this.changngemxdata(e,item.id,'visitorName')}
                                    ><div>
                                    <font color="red">*</font>访客姓名：</div> </InputItem>


                  <Picker
                                data={this.state.xb}
                                cols={1}
                                onChange={(e) => this.changngxbdata(e,item.id)}
                                editable={this.state.editviewable}
                                className="forss"
                                value={this.state[`sexdata${item.id}`]}
                                extra="请选择访客性别"
                     >
                <List.Item arrow="horizontal"><span className="error">*</span>访客性别</List.Item>
            </Picker>

                                    <InputItem
                                        type="text" placeholder ="请输入访客地址"
                                        value={this.state[`visitorAddress${item.id}`]}
                                        editable={this.state.editviewable}
                                        ref={el => this.labelFocusInst = el}
                                        onChange={(e) => this.changngemxdata(e,item.id,'visitorAddress')}
                                    ><div>
                                    <font color="red">*</font>访客地址：</div>
                                    </InputItem>
                                      {}
                                    <InputItem
                                        type="text" placeholder ="访客联系方式"
                                        value={this.state[`visitorCall${item.id}`]}
                                        editable={this.state.editviewable}
                                        ref={el => this.labelFocusInst = el}
                                        onChange={(e) => this.changngemxdata(e,item.id,'visitorCall')}
                                    ><div>
                                    <font color="red">*</font>访客联系方式：</div> </InputItem>
                                    <InputItem
                                        type="text" placeholder ="请输入证件号码"
                                        value={this.state[`identificationNumber${item.id}`]}
                                        editable={this.state.editviewable}
                                        ref={el => this.labelFocusInst = el}
                                        onChange={(e) => this.changngemxdata(e,item.id,'identificationNumber')}
                                    ><div>
                                    <font color="red">*</font>证件号码：</div> </InputItem>
                                    <TextareaItem
                                    title="备注："
                                    data-seed="logId" placeholder ="请输入"
                                    editable={this.state.editviewable}
                                    ref={el => this.autoFocusInst = el}
                                    value={this.state[`note${item.id}`]}
                                    onChange={(e) => this.changngemxdata(e,item.id,'note')}
                                    autoHeight
                                />


                                    {/* <InputItem
                                        value={this.state[`passWord${item.id}`]}
                                        title={`文件名称${item.id}`}
                                        placeholder="请输入"
                                        onChange={(e) => this.inputName(`passWord${item.id}`, e.target.value)} /> */}
                            </List>
                            </div>
                            )
                        }
                        {/* {

                                <div  className="mxitem">
                                       <div className="mxitemson">
                                       {Number(item.id) === this.state.sizeMX?

                                        <button     className='additem'  onClick={(e) =>this.addRowFunBACK(e) } type="button">
                                        <span>增加</span>
                                        </button>
                                        :null }
                                        <span ></span>
                                        <button   className="delitem"  onClick={(e) =>this.handleItemDelete(item.id) }  type="button">
                                        <span>删除</span>
                                        </button>

                                        </div>
                                </div>
                        } */}
                        {


                        }

                    </React.Fragment>
                )
            })
        )
    }


    addRowFunBACK = () => {
    let { returnAddRowFunList } = this.state;
    let addId = returnAddRowFunList.length+1
    let obj = {}

    obj[`id`] = `${addId}`

    let stateObj = {}
    stateObj[`createUserId${addId}`] = ""
    stateObj[`identificationNumber${addId}`] = ""
    stateObj[`note${addId}`] = ""
    stateObj[`visitorAddress${addId}`] = ""
    stateObj[`visitorCall${addId}`] = ""
    stateObj[`visitorName${addId}`] = ""
    stateObj[`visitorSex${addId}`] = ""
    this.state.sizeMX=addId
    this.setState({
        sizeMX: addId,
    })

    this.setState(stateObj)
    this.setState({
        returnAddRowFunList: [...returnAddRowFunList, obj],
    })

this.forceUpdate()
}

checkData = (varl) => {

  let checkresult=true
  let { returnAddRowFunList } = this.state;
  let arrObj = []
  returnAddRowFunList.map(item => {
      let obj = {}
      obj[`visitorSex`] = this.state[`visitorSex${item.id}`]
      obj[`visitorCall`] = this.state[`visitorCall${item.id}`]
      obj[`visitorName`] = this.state[`visitorName${item.id}`]
      obj[`note`] = this.state[`note${item.id}`]
      obj[`visitorAddress`] = this.state[`visitorAddress${item.id}`]
      obj[`createUserId`] = this.state[`createUserId${item.id}`]
      obj[`identificationNumber`] = this.state[`identificationNumber${item.id}`]
      arrObj = [...arrObj, obj]
  })
     //1是保存 2是提交
    if(varl=='2'){
        if(arrObj.length==0){
        this.state.errormsg="请维护访客人员信息,并保存,再提交申请"
        this.setState({

            errormsg:"请维护访客人员信息,并保存,再提交申请"
        })

     return false
        }
        for(var field in arrObj){
            var objfileid=arrObj[field];
           var visitorCall2= objfileid.visitorCall
           var note2= objfileid.note
           var identificationNumber2= objfileid.identificationNumber
           var visitorName2= objfileid.visitorName
           var visitorAddress2= objfileid.visitorAddress
           var re = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;  // 电话号码限制 正则表达式
           var result = re.test(visitorCall2);
           var hang="第"+(Number(field)+1)+"行,"
           var msg=''
           if(!result){
            msg+=hang+'请输入正确的访客联系方式！ ' ;
            checkresult= false


            }

            if(note2.length > 64){
                msg+=hang+'人员信息备注不能超过64个字 ' ;
                checkresult= false
            }
           if(identificationNumber2.length > 18){
            msg+=hang+'居民身份证号码不能超过18位,请检查 ' ;
            checkresult= false

          }

          if(identificationNumber2.length < 18){
            msg+=hang+'居民身份证号码不能小于18位,请检查 ' ;
            checkresult= false

          }

          if(visitorName2.length> 64){
            msg+=hang+'访客姓名不能超过64位,请检查 ' ;
            checkresult= false

          }

          if(!visitorAddress2){
            msg+=hang+'访客地址不能为空，请检查！ ' ;
            checkresult= false

          }
          if(visitorAddress2.length> 64){
            msg+=hang+'访客地址不能超过64位,请检查 ' ;
            checkresult= false

          }

          if(!checkresult){
            this.state.errormsg=msg
            this.setState({

                errormsg:msg
            })

            break
          }

         }

    }

    // var fieldCheckEmpty={
    //     'visitorCall':'访客联系方式',
    //     'note':'备注',
    //     'identificationNumber':'居民身份证号码',
    //     'visitorName':'访客姓名',
    //     'visitorAddress':'访客地址不能为空',
    // }




   return checkresult
}
//表单提交事件
handleSubmit = e => {
    let { returnAddRowFunList } = this.state;
    let arrObj = []
    returnAddRowFunList.map(item => {
        let obj = {}
        obj[`visitorSex`] = this.state[`visitorSex${item.id}`]
        obj[`visitorCall`] = this.state[`visitorCall${item.id}`]
        obj[`visitorName`] = this.state[`visitorName${item.id}`]
        obj[`note`] = this.state[`note${item.id}`]
        obj[`visitorAddress`] = this.state[`visitorAddress${item.id}`]
        obj[`createUserId`] = this.state[`createUserId${item.id}`]
        obj[`identificationNumber`] = this.state[`identificationNumber${item.id}`]
        arrObj = [...arrObj, obj]
    })

    //阻止表单提交行为
    //e.preventDefault()
    // 表单数据
    const url = NEW_YGK_URL + 'yysqRecordController/saveData';

    var datajsom={
        mpAppReservationRecord:this.state.headData,
        MpAppReservationRecordMxList:arrObj
    }

    if(this.state.yhxx){
        let databf=  this.state.headData
        databf.createUserId=this.state.yhxx.idno
        databf.createUserName=this.state.yhxx.name
        this.setState({
            databf
        })
    }
if(this.checkData("1")){

    Axios({
    contentType: "application/json;charset=UTF-8",
     headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      method: 'post',
      url: url,
      data: datajsom, //JSON.stringify(datajsom),
      timeout: 900000, //这个就是重点
      //这部分非常重要，否则formdata会被转格式
    //   transformRequest: [function () { return JSON.stringify(datajsom)}],
      }).then((res) => {
      Toast.hide();
      let  returnSource=res.data

      if (res.status == 200 && returnSource.msg == "保存成功!") {


        this.setState({
            headData:returnSource.data.mpAppReservationRecord,
            ryxxlist:returnSource.data.MpAppReservationRecordMxList

        })
        Toast.success(returnSource.msg, 1);
      }else{
        Toast.fail(returnSource.msg, 5);
      }
    });
    }
    else{
        Toast.hide();
      Toast.info(this.state.errormsg)
    }

  }
    componentDidMount = () => {
       //获取预约位置字典
       this.getYysqLocation()
       var type= this.props.location.query.type
       let isViewedit = true;
       let editdata=this.state.headData
       if(type==='edit'||type==='view'){
        editdata=this.props.location.query.datasj
        this.setState({
            headData:editdata

        })
      this.getMX(this.props.location.query.datasj)
    }
    if(type==='apply'){
        let { returnAddRowFunList } = this.state;
        let addId = 1
        let obj = {}
        obj[`id`] = `${addId}`
        let stateObj = {}
        stateObj[`createUserId${addId}`] = ''
        stateObj[`identificationNumber${addId}`] =''
        stateObj[`note${addId}`] =''
        stateObj[`visitorAddress${addId}`] =''
        stateObj[`visitorCall${addId}`] = ''
        stateObj[`visitorName${addId}`] = ''
        stateObj[`visitorSex${addId}`] = ''
        //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
        this.setState(stateObj)
        this.setState({
            returnAddRowFunList: [...returnAddRowFunList, obj],
        })
        this.state.sizeMX=addId
        this.setState({
            sizeMX: addId,
        })
    }
    if(type==='view'){
        isViewedit=false
    }

    var yhdata= this.props.location.query.yhxx
        let yhxxinfo={
            idno:yhdata.idno,
                name:yhdata.name
            }

        this.state.yhxx=yhxxinfo
        this.setState({
            yhxx:yhxxinfo

        })

    }
    async  getMX(item){
    var guid=item.reservationId
    var dateNow
    if(item.reservationTime){
        dateNow =new Date(item.reservationTime)
    }else{
        dateNow =new Date()
    }
     var e1 =item.lntervieweeType
     var list=this.state.ofdjlx
    for (var itembf in list) {
        if (list[itembf].label == e1) { //item 表示Json串中的属性，如'name'
          this.state.bfcode=[list[itembf].value]
          this.setState({
            bfcode:[list[itembf].value]
          })

          break;

        }
      }
      var e2 =item.visitType
      var listnew=this.state.lfdjlx

      for (var itemlf in listnew) {
          if (listnew[itemlf].label == e2) { //item 表示Json串中的属性，如'name'
            this.state.lfcode=[list[itemlf].value]
            this.setState({
                lfcode:[list[itemlf].value]
            })
            break;

          }
        }
        var e3 =item.positionCode
        this.state.crcode=[e3]
        this.setState({
            crcode:[e3]
        })


    this.setState.yyrq=dateNow
    this.setState({
        yyrq: dateNow
    })

    const queyMxurl = NEW_YGK_URL + 'yysqRecordController/queyMx';
    var params = new URLSearchParams();
    params.append("guid", guid);

    Axios({
        method: 'post',
        url: queyMxurl,
        data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
        if (res.status == 200) {
          var  array=res.data
          if(array&&array.length!=0){
            for (let index = 0; index < array.length; index++) {
                let { returnAddRowFunList } = this.state;
                let addId = index+1
                const element = array[index];
                let obj = {}
                obj[`id`] = `${addId}`
                // if(index!=0){
                //     obj[`id`] = `${addId}`
                // }

                let stateObj = {}
                stateObj[`createUserId${addId}`] = element.createUserId
                stateObj[`identificationNumber${addId}`] =element.identificationNumber
                stateObj[`note${addId}`] =element.note
                stateObj[`visitorAddress${addId}`] =element.visitorAddress
                stateObj[`visitorCall${addId}`] = element.visitorCall
                stateObj[`visitorName${addId}`] = element.visitorName
                stateObj[`visitorSex${addId}`] = element.visitorSex
                stateObj[`sexdata${addId}`] = element.visitorSex=='男'?['0']:['1']
                //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
                this.setState(stateObj)
                this.setState({
                    returnAddRowFunList: [...returnAddRowFunList, obj],
                })
                this.state.sizeMX=addId
                this.setState({
                    sizeMX: addId,
                })
            }
          }else{
                let { returnAddRowFunList } = this.state;
                let addId = 1
                let obj = {}
                obj[`id`] = `${addId}`
                let stateObj = {}
                stateObj[`createUserId${addId}`] = ''
                stateObj[`identificationNumber${addId}`] =''
                stateObj[`note${addId}`] =''
                stateObj[`visitorAddress${addId}`] =''
                stateObj[`visitorCall${addId}`] = ''
                stateObj[`visitorName${addId}`] = ''
                stateObj[`visitorSex${addId}`] = ''
                //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
                this.setState(stateObj)
                this.setState({
                    returnAddRowFunList: [...returnAddRowFunList, obj],
                })
                this.state.sizeMX=addId
                this.setState({
                    sizeMX: addId,
                })

          }

            this.forceUpdate()
        }
    });
      this.forceUpdate()
    }
    async  getYysqLocation(){
        const queylocationurl = NEW_YGK_URL + 'yysqRecordController/getyysqlocation';
        Axios({
            method: 'post',
            url: queylocationurl,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
            if (res.status == 200) {
                this.state.locationzd= res.data
               var  jsonCode=JSON.stringify(res.data)
               jsonCode=jsonCode.replaceAll("LABEL",'label').replaceAll("VALUE",'value')
               jsonCode=JSON.parse(jsonCode)
               this.state.locationzd=jsonCode
                this.setState({
                    locationzd: jsonCode,
                  });
            }
        });


    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    onChange = (value, label) => {


        // this.setState({
        //     value,
        // });
    };
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }

  genID = (length) => {
    return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
}
setmxList = () => {
    let thatthis=this
    return (
         <div>

          <List renderHeader={() => '人员信息'}>

                <List.Item>
                 <InputItem
                    type="text"
                    value={this.state.A}
                    // editable={false}
                    ref={el => this.labelFocusInst = el}
                    onChange={(value,item)=>this.changngemx(value,item,'visitorName')}
                    ><div>申请人</div></InputItem>
                <InputItem
                    type="text"
                    value={this.state.A}
                    // editable={false}
                    onChange={(value) => this.setState({ sqdw: value })}
                ><div>申请单位</div></InputItem>
                </List.Item>




            </List>
           </div>
            )


  }


       /* 点击确定按钮 */
   _clickChooseOk = () => {
    var thattjis=this

    const url = NEW_YGK_URL+'yysqRecordController'+'/qrData';
    var params = new URLSearchParams();
    params.append("ids", thattjis.state.headData.reservationId);
     Axios({
        method: 'post',
        url: url,
        data: params,
       headers: {
         'Content-Type': 'application/x-www-form-urlencoded'
       }
    }).then((res) => {
        Toast.info('确认成功',2)
        setTimeout( function(){
            thattjis.props.history.goBack()
        }, 2000 );
    })

    }
  //*拜访对象类型：
  handlebflxChange = (e) => {
    var datasj=0
    if(e.length=='1'){
        datasj=e[0]
    }

    const list = this.state.ofdjlx
    for (var item in list) {
      if (list[item].value == datasj) { //item 表示Json串中的属性，如'name'
        this.state.lntervieweeType = list[item].value
        let databf=  this.state.headData
        this.state.bfcode=e
        this.setState({
            bfcode:e
        })

        databf.lntervieweeType=list[item].label
             this.setState({
                 databf
           })
        break;

      }
    }

    this.forceUpdate()
  }

  handleCrdChange = (e) =>  {
    var datasj=0
    if(e.length=='1'){
        datasj=e[0]
    }
    const list = this.state.locationzd
    for (var item in list) {
      if (list[item].value == datasj) { //item 表示Json串中的属性，如'name'
        let databf=  this.state.headData
        this.state.headData.positionCode = list[item].value
        this.state.crcode=e
        this.setState({
            crcode:e
        })
        databf.positionCode=datasj
             this.setState({
                 databf
           })
        break;

      }
    }

    this.forceUpdate()

 }


   //*来访对象类型： val, extend.items
   handlelflxChange = (e) =>  {
    var datasj=0
    if(e.length=='1'){
        datasj=e[0]
    }

    const list = this.state.lfdjlx
    for (var item in list) {
      if (list[item].value == datasj) { //item 表示Json串中的属性，如'name'
        this.state.lntervieweeType = list[item].value
        let databf=  this.state.headData
        this.state.lfcode=e
        this.setState({
            lfcode:e
        })
        databf.visitType=list[item].label
             this.setState({
                 databf
           })
        break;

      }
    }

    this.forceUpdate()

 }
      //val 实际值 var2 对应的字段
     changngedata = (val,var2) => {

        let datatemp=  this.state.headData
        datatemp[var2]=val
               this.setState({
                datatemp
             })
         }
         //value,item,'visitorName'  值 index
    changngemxdata = (val,var2,var3) => {
        let addid=var2
        if(var3==='visitorSex'){
        }

        let stateObj = {}
        stateObj[`${var3}${addid}`] = val
        this.setState(stateObj)
    }


    changngxbdata = (e,var2) => {



        // if(index!=0){
        //     obj[`id`] = `${addId}`
        // }

        let stateObj = {}
        stateObj[`visitorSex${var2}`] = e[0]=='0'?'男':'女'
        stateObj[`sexdata${var2}`] =e
        //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
        this.setState(stateObj)
        this.forceUpdate()
    }
    //弹窗
    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
          [key]: true,
        });
      }
      onClose = key => () => {
        this.setState({
          [key]: false,
        });
      }




    render() {

        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}

                    onLeftClick={() => this.props.history.go(-1)}
                >预约申请</NavBar>
                <form onSubmit={this.handleSubmit}>
                <div className="divmagin">
                <Tabs tabs={tabs}
                        initialPage={0}
                        onChange={(tab, index) => { }}
                        onTabClick={(tab, index) => { }}
                    >
                        <div>
                            <List renderHeader={() => '基本信息'} >

                            {
                                   <Picker
                                   data={this.state.locationzd}
                                   cols={1}
                                   onChange={this.handleCrdChange}
                                   editable={this.state.editviewable}
                                   className="forss"
                                   value={this.state.crcode}

                                   extra="请选择出入地"
                        >
                   <List.Item arrow="horizontal"><span className="error">*</span>出入地</List.Item>
               </Picker>
                            /* <InputItem
                                    type="select" placeholder ="请选择出入地"
                                    value={this.state.headData.positionCode}
                                     editable={this.state.editviewable}
                                    ref={el => this.labelFocusInst = el}

                                    onChange={(value)=>this.changngedata(value,'positionCode')}
                                ><div><font color="red">*</font>出入地：</div></InputItem>
                             */}
                            {


                         <Picker
                                data={this.state.ofdjlx}
                                cols={1}
                                onChange={this.handlebflxChange}
                                editable={this.state.editviewable}
                                className="forss"
                                value={this.state.bfcode}

                                extra="请先选择拜访类型"
                     >
                <List.Item arrow="horizontal"><span className="error">*</span>拜访对象类型</List.Item>
            </Picker> }

            <div className='create-wrap'>
                                <InputItem
                                    type="text"
                                    value={this.state.headData.lntervieweeName}
                                    editable={this.state.editviewable}
                                    onChange={(value)=>this.changngedata(value,'lntervieweeName')}
                                ><font color="red">*</font>拜访对象名称：</InputItem></div>

                       <div className='create-wrap'>
                                <InputItem
                                    type="text"
                                    value={this.state.headData.lntervieweeCall}
                                    onChange={(value)=>this.changngedata(value,'lntervieweeCall')}
                                    editable={this.state.editviewable}
                                    ><font color="red">*</font>拜访对象联系方式：</InputItem></div>

                               <div className='create-wrap'> <InputItem
                                    type="text"
                                    editable={this.state.editviewable}

                                    value={this.state.headData.lntervieweeAddress}
                                    onChange={(value)=>this.changngedata(value,'lntervieweeAddress')}
                                ><font color="red">*</font>拜访对象地址：</InputItem></div>
                                         <Picker
                                data={this.state.lfdjlx}
                                cols={1}
                                editable={this.state.editviewable}
                                onChange={this.handlelflxChange}

                                className="forss"
                                value={this.state.lfcode}
                                extra="请先选择来访类型"
                     >
                <List.Item arrow="horizontal"><span className="error">*</span>来访对象类型</List.Item>
            </Picker>



            <DatePicker
                            mode="date"
                            title="请选择预约日期"
                            //extra="请选择预约日期" format="yyyy-MM-dd"
                           value={this.state.yyrq}
                            onChange={(value)=>this.chooseDate(value,'reservationTime')}

                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约日期</List.Item>
                        </DatePicker>


                                {/* <InputItem
                                    type="date"
                                    value={this.state.headData.reservationTime}
                                    editable={this.state.editviewable}

                                    onChange={(value)=>this.changngedata(value,'reservationTime')}
                                ><div><font color="red">*</font>预约拜访时间：</div>
                                </InputItem>
                                */}

                              <TextareaItem
                                    title="拜访事由" placeholder ="请输入"
                                    data-seed="logId"
                                    ref={el => this.autoFocusInst = el}
                                    editable={this.state.editviewable}

                                    value={this.state.headData.subjectMatter}
                                    onChange={(value)=>this.changngedata(value,'subjectMatter')}
                                    autoHeight
                                />
                                <TextareaItem
                                    title="备注："
                                    data-seed="logId" placeholder ="请输入"
                                    editable={this.state.editviewable}
                                    ref={el => this.autoFocusInst = el}
                                    value={this.state.headData.note}
                                    onChange={(value)=>this.changngedata(value,'note')}
                                    autoHeight
                                />
                            </List>
                            <div  className="mxitem" display=''>
                                       <div className="mxitemson">

                <div style={{width:'20px'}}></div>
                <button className="save" type="button"  onClick={this._clickChooseOk}>
                  确认
                </button>

                </div>
          </div>


                        </div>
                        <div>
                           {/* // {this.setmxList()} */}
                             {this.returnAddRowFun()}


                        </div>
                </Tabs>
                </div>

                </form>

            </div>
        )
    }
}
