import './yktsqshow.css'
import React, { Component } from 'react'
import { NavBar, Icon, List, Picker, InputItem, Modal, Button, SearchBar, PickerView, Radio, Toast, ImagePicker } from 'antd-mobile';
import { YKT_URL, Axios } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;
export default class Yktsq extends Component {
    constructor(props) {

        super(props);
        const yktglid = this.props.location.query.yktglid;
        console.log(yktglid);
        this.state = {
            list: {},
            isLoaded: false,
            yktglid:yktglid,
            yhlxlist:[],
            fjlist:[],
            hkbfiles: [],
            hzyktfiles: [],
            jhzfiles: [],
            yczfiles: [],
            hzsfzfiles: [],
            brsfzfiles: [],
            yktsqdfiles: [],
        }
    }

    componentDidMount = () => {
        this.getData();
        // this.brsfzfj();
        // this.yczpfj();
        // this.yktfj();
        // this.hkbfj();
        // this.hzyktfj();
        // this.jhzfj();
        // this.hzsfzfj();
    }

    async getData() {
      console.log("执行获取数据");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        const url = YKT_URL+'yktsqxx/getYktsqJbxx';
        var params = new URLSearchParams();
        params.append("yktglid", this.state.yktglid);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if (res.data.flag) {
                this.setState({
                    list: res.data.data.jbxx,
                    fjlist:res.data.data.fjlist
                },()=>{
                    console.log(this.state.list);
                    const list = this.state.fjlist

                    let falg = false;
                    let arr = [];
                    let arr2 = [];
                    let arr3 = [];
                    let arr4 = [];
                    let arr5 = [];
                    let arr6 = [];
                    let arr7 = [];
                    for (var item in list) {
                        if (list[item].fjlb == "2") {
                            falg = true;
                            arr.push(list[item]);
                        }
                        if (list[item].fjlb == "5") {
                            falg = true;
                            arr2.push(list[item]);
                        }
                        if (list[item].fjlb == "1") {
                            falg = true;
                            arr3.push(list[item]);
                        }
                        if (list[item].fjlb == "10") {
                            falg = true;
                            arr4.push(list[item]);
                        }
                        if (list[item].fjlb == "6") {
                            falg = true;
                            arr5.push(list[item]);
                        }
                        if (list[item].fjlb == "3") {
                            falg = true;
                            arr6.push(list[item]);
                        }
                        if (list[item].fjlb == "7") {
                            falg = true;
                            arr7.push(list[item]);
                        }
                    }
                    this.setState({
                        brsfzfiles:arr,
                        yczfiles:arr2,
                        yktsqdfiles:arr3,
                        hkbfiles:arr4,
                        hzyktfiles:arr5,
                        jhzfiles:arr6,
                        hzsfzfiles:arr7,
                    })

                });
            }
        });
    }



    //本人身份证
    brsfzfj = () => {
        if (this.state.brsfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">本人身份证(必填)</div>
                    <ImagePicker
                        files={this.state.brsfzfiles}
                        onChange={false}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.brsfzfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //一寸照片
    yczpfj = () => {
        if (this.state.brsfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">一寸照片(红底)(必填)</div>
                    <ImagePicker
                        files={this.state.yczfiles}
                        onChange={this.yczponChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yczfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //一卡通申请单
    yktfj = () => {
        if (this.state.yktsqdfiles.length>0) {
            return (
                <div>
                    <div className="scfj">一卡通申请单(必填)</div>
                    <ImagePicker
                        files={this.state.yktsqdfiles}
                        onChange={this.yktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yktsqdfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //户口本（本页及户主页）
    hkbfj = () => {

        if (this.state.hkbfiles.length>0) {
            return (
                <div>
                    <div className="scfj">户口本(必填)</div>
                    <ImagePicker
                        files={this.state.hkbfiles}
                        onChange={this.hkbChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hkbfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）一卡通或员工卡
    hzyktfj = () => {

        if (this.state.hzyktfiles.length>0) {
            return (
                <div>
                    <div className="scfj">户主（配偶）一卡通或员工卡(必填)</div>
                    <ImagePicker
                        files={this.state.hzyktfiles}
                        onChange={this.hzyktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hzyktfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //结婚证
    jhzfj = () => {
        if (this.state.jhzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">结婚证(非必填)</div>
                    <ImagePicker
                        files={this.state.jhzfiles}
                        onChange={this.jhzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.jhzfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）身份证
    hzsfzfj = () => {

        if (this.state.hzsfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">户主（配偶）身份证(非必填)</div>
                    <ImagePicker
                        files={this.state.hzsfzfiles}
                        onChange={this.hzsfzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hzsfzfiles.length < 4}
                        multiple={this.state.multiple}
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;
        }
    }

    onChange = (brsfzfiles, type, index) => {
        this.setState({
            brsfzfiles,
        });
        console.log(this.state.brsfzfiles);
    }

    yczponChange = (yczfiles, type, index) => {
        console.log(yczfiles, type, index);
        this.setState({
            yczfiles,
        });
    }

    yktChange = (yktsqdfiles, type, index) => {
        console.log(yktsqdfiles, type, index);
        this.setState({
            yktsqdfiles,
        });
    }

    hkbChange = (hkbfiles, type, index) => {
        console.log(hkbfiles, type, index);
        this.setState({
            hkbfiles,
        });
    }

    hzyktChange = (hzyktfiles, type, index) => {
        console.log(hzyktfiles, type, index);
        this.setState({
            hzyktfiles,
        });
    }

    jhzChange = (jhzfiles, type, index) => {
        console.log(jhzfiles, type, index);
        this.setState({
            jhzfiles,
        });
    }

    hzsfzChange = (hzsfzfiles, type, index) => {
        console.log(hzsfzfiles, type, index);
        this.setState({
            hzsfzfiles,
        });
    }

    sfsc = () => {
        console.log(this.state.list.dqzt)
        if (this.state.list.dqzt!=""&&this.state.list.dqzt!="确认") {
            return (
                <Button
                    onClick={() =>
                        alert('删除', '是否确认删除？删除后可重新提交。', [
                        { text: '取消', onPress: () => console.log('cancel') },
                        {
                            text: '确认',
                            onPress: () =>
                            new Promise((resolve) => {
                                this.datadel();
                                setTimeout(resolve, 10);
                            }),
                        },
                        ])
                    }
                    >

                删除
                </Button>
            )
        } else {
            return null;
        }
    }

    async datadel() {
        console.log("执行删除");
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        const url = YKT_URL + 'yktsqxx/deletes';
        var params = new URLSearchParams();
        params.append("ids", this.state.yktglid);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                Toast.info('已删除，您可以重新提交', 3, null, false);
                this.props.history.go(-1)
            }
        });
    }



    setContent = () => {
        console.log(this.state.list);
        if (this.state.list) {
            //C1外部人员
            return (<div>
                <div className="scfj">申请信息</div>

                <InputItem value={this.state.list.ryxm} >
                    <div >用户姓名</div>
                </InputItem>
                <InputItem  value={this.state.list.yhdwmc} >
                    <div >用户单位</div>
                </InputItem>
                <InputItem placeholder="请输入身份证号" value={this.state.list.zjbh} >
                    <div >身份证号</div>
                </InputItem>
                <InputItem placeholder="请输入用户性别" value={this.state.list.xbmc} >
                    <div >用户性别</div>
                </InputItem>
                <InputItem placeholder="请输入民族" value={this.state.list.mzmc} >
                    <div >民族</div>
                </InputItem>
                <InputItem placeholder="请输入手机号码" value={this.state.list.lxsjh} >
                    <div >手机号码</div>
                </InputItem>

                <InputItem  value={this.state.list.lxdz} >
                    <div >家庭住址</div>
                </InputItem>

                <InputItem  value={this.state.list.yhdwmc} >
                    <div >工作单位</div>
                </InputItem>

                <InputItem placeholder="备注" value={this.state.list.bz} editable="false" >
                    <div >备注</div>
                </InputItem>
                <InputItem  value={this.state.list.thyj} editable="false" >
                    <div >退回意见</div>
                </InputItem>
                {this.brsfzfj()}
                {this.yczpfj()}
                {this.yktfj()}
                {this.hkbfj()}
                {this.hzyktfj()}
                {this.jhzfj()}
                {this.hzsfzfj()}

            </div>
            )
        }else{
            return null;
        }
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通申请详细信息</NavBar>
            <div className="divmagin">
                    {this.setContent()}
                </div>
            </div>
        )
    }
}
