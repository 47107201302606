import './yktsq.css'
import React, { Component } from 'react'
import { NavBar, Icon, List,Card, WingBlank, WhiteSpace,Toast } from 'antd-mobile';
import { BASE_URL, YKT_URL, Axios } from '../../../utils/url';
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class Yktsq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isLoaded: false,
            smxx:""

        }
    }

    componentDidMount = () => {
        this.getList();
        this.getSmxx();
    }

    async getList() {
        console.log("执行获取数据");
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        const url = YKT_URL + 'yktsqxx/getYktglList';
        var params = new URLSearchParams();
        params.append("yhsfzh", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            console.log(res);
            if (res.status === 200) {
                if(res.data.msg.length>0){
                    this.setState({
                        list: res.data.msg,
                        isLoaded: true
                    });
                }
            }
        });
    }
    async getSmxx() {
        console.log("获取说明信息");
        const url = YKT_URL + 'zzjgController/getSmxx';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            console.log(res.data.data);
            console.log( res.data.data[0].tsnr);
            if (res.status === 200) {
                this.setState({
                    smxx: res.data.data[0].tsnr
                });
            }
        });
    }


    setMapList = () => {
        console.log(this.state.isLoaded);
        if (!this.state.isLoaded) {
            console.log(111)
            return null;
        } else {
            return this.state.list.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={() => this.handleJump(item)}>
                    <Card.Header
                        title={item.ryxm}
                        thumb={ygk}
                        extra={<span>{'一卡通号：' + item.ykth}</span>}
                    />
                    <Card.Body>
                        <div>{item.zhlxmc}</div>
                    </Card.Body>
                    <Card.Footer content={'申请时间：' + item.firsttime.slice(0,10) } extra={<div>{'备注：' + item.bz}</div>}  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
            ))
        }
    }

    handleSq = () => {

       this.props.history.push('./yktsqedit');



    }

    handleJump = (e) => {
        this.props.history.push({ pathname : "./yktsqshow" , query : {yktglid:e.ryxh}});

    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    rightContent={[
                        <div onClick={this.handleSq}>申请</div>
                    ]}
                    onLeftClick={() => this.props.history.goBack()}
                >一卡通申请</NavBar>
                <div className="divmagin">
                    {this.setMapList()}
                </div>
                <div className="scfj">申请说明</div>
                        {/* <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}> */}
                            <div style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}} dangerouslySetInnerHTML={{ __html:this.state.smxx}}></div>
            </div>
        )
    }
}
