import React, { Component } from 'react'
import { BASE_URL, JC_URL, Axios } from '../../../utils/url'
import { NavBar, Checkbox, List, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;
const CheckboxItem = Checkbox.CheckboxItem;

export default class Jccx extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ctVal: '',
            cgList: [],
            djidList:[]
        }
    }

    //进页面加载
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        this.setState({
            ryxm: userInfo.ryxm,
            sqdw: userInfo.rysx20120716p0026,
            sfzh: userInfo.sfzh,
            zz: userInfo.rysx20120716p0051,
            ektbh: userInfo.rysx20120716p0046,
            sqrid: userInfo.ryid,
            phone: userInfo.rysx20120716p0048,
            userInfo: userInfo,
            dwbm: userInfo.dwbm,

        }, () => {
            this.getyycx();
        });
    }
    getyycx = (e) => {
        const url = JC_URL + 'ctyyxx/getYydata';
        var params = new URLSearchParams();
        params.append('sfzh', this.state.sfzh);
        params.append('yyzt', 'success');
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            this.state.cgList.splice(0);
            if (res.data.length > 0) {
                // this.state.cgList  = res.data
                 //当前时间
                 var date1 = new Date();

                let data = JSON.stringify(res.data);
                let reg = new RegExp('yylsh', "g");
                data = data.replace(reg, 'value');
                let reg1 = new RegExp('ctmc', "g");
                data= data.replace(reg1, 'label');
                data = JSON.parse(data);
                let dataArr = []
                let timestamp;
                for(var a = 0;a<data.length;a++){
                    if(data[a].yylx ==='晚'){
                        timestamp = Date.parse(new Date(data[a].yyrq + ' 18:00').toString());
                        if(date1.getTime()<timestamp){
                            dataArr.push(data[a])
                        }
                    }else if(data[a].yylx ==='中'){
                        timestamp = Date.parse(new Date(data[a].yyrq + ' 12:00').toString());
                        if(date1.getTime()<timestamp){
                            dataArr.push(data[a])
                        }
                    }else if(data[a].yylx ==='早'){
                        timestamp = Date.parse(new Date(data[a].yyrq + ' 06:00').toString());
                        if(date1.getTime()<timestamp){
                            dataArr.push(data[a])
                        }
                    }
                }
                this.setState({
                    cgList: dataArr
                }, () => {
                    this.setMapList();
                })
            }
            if(this.state.cgList.length ===0){
                    Toast.info('暂无要取消的预约订单')
            }
        });
    }
    setMapList = (e) => {
        const data = this.state.cgList
        if (data.length > 0) {
            return data.map(i => (
                <CheckboxItem key={i.value} onChange={() => this.onChange(i.value)}>
                    <List.Item extra={i.yyrq}>{i.xm}</List.Item>
                    <List.Item extra={i.yylx}>{i.label}</List.Item>
                </CheckboxItem>
            ))
        }




    }
    // 选择取消
    onChange = (e) => {
        //不存在
        if(this.state.djidList.indexOf(e) < 0){
            this.state.djidList.push(e)
        }else{
            var index = (this.state.djidList || []).findIndex((item) => item === e);
            this.state.djidList.splice(index,1);
        }

    }
    // 确认提交
    handleSubmit = (e) => {
        e.preventDefault()
        if(this.state.djidList.length>0){
            const url = JC_URL + 'ctyyxx/cancelYy';
            var params = new URLSearchParams();
            params.append('yylsh', this.state.djidList);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }

            }).then((res) => {
                Toast.hide();
                if(res.data.flag && res.data.msg ==='取消成功!'){
                    Toast.success(res.data.msg,2)
                    let _that = this;
                        setTimeout( function(){
                        _that.props.history.go(-1)
                        }, 1000 );

                }else{
                    Toast.success('取消失败！')
                }

            });
        }else{
            Toast.fail('请选择要取消预约的单据')
        }

    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >预约取消</NavBar>
                <div style={{ paddingTop: "45px" }}>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <List renderHeader={() => '请选择要取消的预约'} >
                                {this.setMapList()}
                            </List>
                            <div className="formSubmit">
                                <button className="submit" type="submit">
                                    确认取消
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
