import './replaceCard.css'
import React, { Component } from 'react'
import { NavBar,Icon,List, Modal,Toast,Card, WingBlank, WhiteSpace } from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class ReplaceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isLoaded: false,

        }
    }
    componentDidMount = () => {
        //获取补卡记录列表
        this.getPersonAccountByIdCardNo();
    }

    async getPersonAccountByIdCardNo() {
      console.log("执行获取数据挂失");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        const url = NEW_YGK_URL+'replacecard/queryReplaceBillsByIdCardNo';
        var params = new URLSearchParams();
        params.append("idCardNo", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                this.setState({
                    list: res.data.data,
                    isLoaded: true
                })
            }
        });
    }

    handleSq=()=>{
        this.queryReplaceCardRecord()
    }

    handleJump=(item)=>{
        this.props.history.push({ pathname : "./replaceCardInfoShow" , query : { replaceId : item.REPLACE_ID}});
    }

    async queryReplaceCardRecord() {
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          const url = NEW_YGK_URL+'replacecard/queryReplaceCardRecord';
          var params = new URLSearchParams();
          params.append("idCardNo", ryobject.sfzh);
          await Axios({
              method: 'post',
              url: url,
              data: params
          }).then((res) => {
            console.log(res);
            if(res.data.flag){
                this.props.history.push('./replaceCardSubmit');
            }else{
                if(res.data.msg !==''){
                    Toast.info(res.data.msg, 3, null, false);
                }else{
                    Toast.info('您已经提交了申请，正在审核中,请耐心等待', 3, null, false);
                }
            }

          });
      }


    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.list.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={() => this.handleJump(item)}>
                    <Card.Header
                        title={item.PERSON_NAME}
                        thumb={ygk}
                        extra={<span>{'类型：' + item.PERSON_TYPE}</span>}
                    />
                    <Card.Body>
                        <div>{item.zhlxmc}</div>
                    </Card.Body>
                    <Card.Footer content={'申请时间：' + item.CREATE_TIME.slice(0,10) } extra={<div>{'当前状态：' + item.STATUS}</div>}  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
            ))
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
              rightContent={[
                <div onClick={this.handleSq}>申请</div>
              ]}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通补卡申请</NavBar>
            <div className="divmagin">
            <div className="scfj">申请说明</div>
            <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>
            1、一卡通补卡申请提交后，预计三个工作日内审核完成。<br></br>
                2、补卡成功后，请本人带上身份证原件到服务大厅领取。</p>
                    {this.setMapList()}
                </div>
            </div>
        )
    }
}
