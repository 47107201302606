import './yysqlist.css'
import React, { Component } from 'react'
import { NEW_YGK_URL, Axios } from  '../../../utils/url'
import {NavBar,Icon,List, Toast,ActionSheet,Modal,NoticeBar} from 'antd-mobile'
const Item = List.Item;
const Brief = Item.Brief;
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
let controllerYysq='yysqRecordController'
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}
export default class YysqList extends Component {
    constructor(props){
        super(props);
        localStorage.removeItem('yyxx')
        this.state = {
            yysqxxlist: [],
            isLoaded: false,
            yhid:"",
            yhxm:"",

        }
    }
    componentDidMount = (e) => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if(userInfo)
      {
        let name= userInfo.ryxm
        let idno= userInfo.sfzh
        this.state.yhid=idno
        this.state.yhxm=name
        this.setState({
          yhxm: name,
          yhid: idno
       })
       this.getyysqlList(userInfo.sfzh)
      }

    }
    handleSq=()=>{
      let  yhxx={
        idno:this.state.yhid,
        name:this.state.yhxm
      }
        this.props.history.push( { pathname : "./yysq" , query : { yhxx : yhxx,type:"apply" }});
    }
    //获取列表
    async getyysqlList(sfzh) {
        const url = NEW_YGK_URL+controllerYysq+'/rysqlist';
        var params = new URLSearchParams();
        params.append("sfzh", sfzh);
        params.append("hhid", localStorage.getItem("bshtoken"));
        //获取人员身份证号并传递参数
        Toast.loading("加载中...",0,null,true);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                var list = [];
                if (res.data) {
                  list=res.data
                    // if (res.data.length > 0) {
                    //   for (var i = 0; i < res.data.length; i++) {
                    //     list.push(res.data[i])

                    //   }
                    // }
                    this.setState({
                      yysqxxlist: list,
                      isLoaded: true
                    });
                    if (list.length === 0) {
                      Toast.info("暂未信息")
                    }
                  } else {
                    Toast.info("暂未信息")
                  }


            }
        });
    }
    handleJump=(item)=>{
      var thatthis=this
      let  yhxx={
        idno:this.state.yhid,
        name:this.state.yhxm
      }
      let tjzt=item.reservationState
      let tjztnew=item.recordStatus
      {
          const BUTTONS = ['修改','删除','提交','查看', '取消'];
          ActionSheet.showActionSheetWithOptions({
              options: BUTTONS,
              cancelButtonIndex: BUTTONS.length - 1,
              destructiveButtonIndex: BUTTONS.length - 5,
              // title: 'title',
              message: '请选择您的操作',
              maskClosable: true,
              'data-seed': 'logId',
              wrapProps,
            },
            (buttonIndex) => {


                if(buttonIndex === 0){
                  if(tjztnew!==0){
                    Toast.info("单据已提交,不能修改")
                  }else{
                    this.props.history.push({ pathname : "./yysq" , query : { yhxx : yhxx,datasj : item,type:"edit" }});
                  }
                }

              if(buttonIndex ===1){
                if(tjztnew!==0){
                  Toast.info("单据已提交,不能删除")
                 }else{
                Modal.alert('删除', '您是否删除此单据', [
                    { text: '取消', onPress: () => console.log('取消') },

                    {
                      text: '确定',
                      onPress: () =>
                        new Promise((resolve) => {
                          const url = NEW_YGK_URL+controllerYysq+'/deleteData';
                          var params = new URLSearchParams();
                          params.append("ids", item.reservationId);
                          //获取人员身份证号并传递参数
                          Toast.loading("加载中...",0,null,true);
                           Axios({
                              method: 'post',
                              url: url,
                              data: params,
                             headers: {
                               'Content-Type': 'application/x-www-form-urlencoded'
                             },
                          }).then((res) => {
                            Toast.info("删除成功")
                                  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                              //this.getClglList(userInfo.sfzh);
                              //需要后期放开
                              setTimeout(resolve, 1000);                             //this.getyysqlList("622323199208183117");
                              setTimeout( function(){
                                thatthis.getyysqlList(thatthis.state.yhid)
                            }, 2000 );
                          })

                      } ),
                    },
                  ])
                }
              }
              if(buttonIndex ===2){
                         if(tjztnew!==0){
                          Toast.info("已经成功提交,不需要再次提交")
                         }else{

                          Modal.alert('提交', '您是否提交此单据', [
                            { text: '取消', onPress: () => console.log('取消') },

                            {
                              text: '确定',
                              onPress: () =>
                                new Promise((resolve) => {
                                  const url = NEW_YGK_URL+controllerYysq+'/submitData';
                                  var params = new URLSearchParams();
                                  params.append("ids", item.reservationId);
                                   Axios({
                                      method: 'post',
                                      url: url,
                                      data: params,
                                     headers: {
                                       'Content-Type': 'application/x-www-form-urlencoded'
                                     },
                                  }).then((res) => {
                                    Toast.info("提交成功")
                                    setTimeout(resolve, 1000);
                                    setTimeout( function(){
                                      thatthis.getyysqlList(thatthis.state.yhid)

                                  }, 2000 );

                                  })


                              } ),
                            },
                          ])
                         }

              }
              else
              if(buttonIndex === 3){
                this.props.history.push({ pathname : "./YysqView" , query : { yhxx : yhxx,datasj : item,type:"view" }});
                //this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
              }

            });
      }

    }
    changedatetime= (var2) => {
          if(!var2){
            return ''
          }
        var  dateNow =new Date(var2)
        var year= dateNow.getFullYear()  //年
        var month = ("0" + (dateNow.getMonth() + 1)).slice(-2); //月
        var day = ("0" + dateNow.getDate()).slice(-2); //日
        var mydate = year + "-" + month + "-" + day;
        return mydate;

    }
    setMapList = () => {
      console.log('数据更新')
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.yysqxxlist.map((item, key) => (
                <Item multipleLine extra={item.lntervieweeAddress} key={item.reservationId} onClick={() => this.handleJump(item)}>

                    <Brief>{item.lntervieweeType}</Brief>
                    <Brief>申请状态:{item.reservationState}</Brief>
                    <Brief>预约拜访时间:{this.changedatetime(item.reservationTime)}</Brief>


                </Item>
            ))
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}  >
                导航栏#0F84D6
                <NavBar
                    mode="dark"  key={1000}
                    icon={<Icon type="left" />}
                    rightContent={[
                        <div onClick={this.handleSq} key={101}>申请</div>
                      ]}
                    onLeftClick={() => this.props.history.go(-1)}
                >预约申请</NavBar>

                <div className="divmagin">
                {/* <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                车辆出入申请提交后，预计三个工作日内审核完成。手机退回时，请查看退回原因，删除退回单据后重新提交申请
                </NoticeBar> */}
                    {this.setMapList()}
                </div>
            </div>
        )
      }
}
