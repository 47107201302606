import './login.css';
import React from 'react';
import { BASE_URL, Axios } from '../../utils/url'
import { utftohex } from '../../assets/js/hex'
import logo from '../../assets/images/logo.png';
import jubao1 from '../../assets/images/jubao1.jpg';
import jubao2 from '../../assets/images/jubao2.jpg';
import jubao3 from '../../assets/images/jubao3.jpg';
import jubao5 from '../../assets/images/jubao5.jpg';
import jubao6 from '../../assets/images/jubao6.jpg';
import jubao7 from '../../assets/images/jubao7.jpg';
import jubao8 from '../../assets/images/jubao8.jpg';
import dcwj from '../../assets/images/wjdc.jpg';
import head1 from '../../assets/images/1.png';
import head2 from '../../assets/images/2.png';
import head3 from '../../assets/images/3.png';
import { List, InputItem, WhiteSpace, Button, Toast ,Carousel} from 'antd-mobile';
const sm2 = require('sm-crypto').sm2;
var pubkeyHex =
    "040CD64F13359245B489D67BCD71A209F3FC5A0393CC0CB43F65A7F8F5852ECF03825D9E0C3A5C8E06B765E07F32505EB567126D5F7C420ABA7DBAA2790B0B4176";

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            zh: '',
            mm: '',
            hasErrorPhone: false,
            zfdz: "",
            data: [head1, head2, head3],
            imgHeight: 176,
        }
    }
    componentDidMount() {
        // simulate img loading
        setTimeout(() => {
          this.setState({
            data: [head1, head2, head3],
          });
        }, 100);
      }
    zc = () => {
        Toast.info('该功能暂时不可用')
    }
    //获取密码
    getMm = (value) => {
        this.setState({
            mm: value,
        });
    }
    //绑定手机号phone的onchange事件
    onChangePhone = (value) => {
        const str = value.replace(/\s*/g, "");
        console.log(str);
        if (value.replace(/\s/g, '').length != 11) {
            this.setState({
                hasErrorPhone: true,
            });
        } else {
            this.setState({
                hasErrorPhone: false,
            });
        }
        this.setState({
            zh: str,
        });

    }
    //手机输入错误提示
    onErrorClickPhone = () => {
        if (this.state.hasErrorPhone) {
            Toast.info('请输入正确的手机号');
        }
    }
    //提交
    denglu = () => {
        Toast.info('该功能暂时不可用')
    }
    wjmm=()=>{
        Toast.info('该功能暂时不可用')
    }
    render() {
        return (

            <div className="App">
                <Carousel className="space-carousel"
          frameOverflow="visible"

          autoplay
          infinite
          beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
          afterChange={index => this.setState({ slideIndex: index })}
        >

          {this.state.data.map((val, index) => (
            <a
              key={val}
              style={{
                display: 'block',
                position: 'relative',
                top: this.state.slideIndex === index ? -10 : 0,
                height: this.state.imgHeight,
                boxShadow: '2px 1px 1px rgba(0, 0, 0, 0.2)',
              }}
            >
              <img
                src={`${val}`}
                alt=""
                style={{ width: '100%', verticalAlign: 'top',height:'400px' }}
                onLoad={() => {
                  // fire window resize event to change height
                  window.dispatchEvent(new Event('resize'));
                  this.setState({ imgHeight: 'auto' });
                }}
              />

            </a>
          ))}
        </Carousel>
                <p className="app-name">塔西南移动服务大厅</p>
                <div className="login">
                    <InputItem
                        type="phone"
                        placeholder="请输入手机号"
                        value={this.state.zh}
                        error={this.state.hasErrorPhone}
                        onErrorClick={this.onErrorClickPhone}
                        onChange={this.onChangePhone}
                    >手机号码</InputItem>
                    <InputItem
                        type="password"
                        placeholder="请输入密码"
                        value={this.state.mm}
                        onChange={this.getMm}
                    >密码</InputItem>
                    <List style={{ margin: '5px 0', backgroundColor: 'white' }} >
                        <List.Item
                            extra={<Button type="ghost" size="small" inline onClick={this.zc}>注册</Button>}
                            multipleLine
                        >
                            没有账号？
      <List.Item.Brief>
                                点击右方按钮注册吧~
      </List.Item.Brief>
                        </List.Item>

                    </List>
                    <div style={{textAlign:"right",padding:"10px"}} onClick={this.wjmm}>忘记密码</div>
                    <div className="loginButton"><Button type="primary" onClick={this.denglu}>登录</Button><WhiteSpace /></div>

                </div>
                <div className="link" style={{marginTop:"10px"}}>
                    <a href="http://www.12377.cn" style={{ paddingLeft: "20px" }}>
                    <img src={jubao1} className="jubao1"></img>
                    </a>
                    <a href="https://xjwljb.com/home" style={{ paddingLeft: "20px" }}>
                    <img src={jubao2} className="jubao1"></img>
                    </a>
                    <a style={{ paddingLeft: "20px" }}>
                    <img src={jubao3} className="jubao1"></img>
                    </a>
                    <a  style={{ paddingLeft: "20px" }}>
                    <img src={jubao5} className="jubao1"></img>
                    </a>

                </div>
            <div style={{ marginTop:"20px"}}>
            <a href="http://politics.people.com.cn/GB/8198/447103/index.html" style={{ paddingLeft: "20px" }}>
                    <img src={dcwj} className="jubao1" ></img>

                    </a>
            <a  style={{ paddingLeft: "20px" }}>
                    <img src={jubao6} className="jubao1"></img>
                    </a>
                    <a  style={{ paddingLeft: "20px" }}>
                    <img src={jubao7} className="jubao1"></img>
                    </a>
                    <a  style={{ paddingLeft: "20px" }}>
                    <img src={jubao8} className="jubao1"></img>
                    </a>

            </div>
                <div className="aclass">

                    <a href="https://beian.miit.gov.cn/" style={{ color: "#c1c1c1" }}>新ICP备2021000675号-1</a>
                </div>
                <div className="link" style={{ color: "#c1c1c1" }}>
                    公安备案号：65312402000112
                </div>
            </div>
        );
    }
}

