import './newygkzxcz.css'
import React, { Component } from 'react'
import { NavBar, Icon, ListView, List, Button, Modal, Toast, InputItem, SearchBar, Calendar, Picker, PullToRefresh } from 'antd-mobile';
import { NEW_YGK_URL, BASE_URL, Axios } from '../../../utils/url';
import { FORMAT } from '../../../utils/url';

const Item = List.Item;
const Brief = Item.Brief;
export default class Newygkzxcz extends Component {
    constructor(props) {
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        super(props);
        this.state = ({
            idCardNo: '',
            showShortcut: false,//顶部查询隐藏

            packageTypeList: [],//钱包类型
            accountTypeCode: '',//钱包类型代码
            accountTypeName: '',//钱包类型名称

            en: false,
            config: {},//开始时间结束时间使用
            startTime: '',//开始时间
            endTime: '',//结束时间
            value: '',//时间组件使用的value
            show: false,//控制时间组件

            objdata: {},//列表点击时间
            modal: false,//隐藏列表

            dsplist: [],
            dataSource,
            isLoading: false,
            height: document.documentElement.clientHeight,
            transactionList: [],
            pageNo: 1,//当前页面数
            pageSize: 10, // 分页size
            totalPage: 0, // 总页数初始化
            isShowContent: false, // 控制页面再数据请求后显示
            refreshing: false, // 是否显示刷新状态
        });
    }
    componentDidMount = () => {
        // 页面加载是获取登录用户的人员信息
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log(userInfo);
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: BASE_URL + "bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        idCardNo: userInfo.sfzh
                    })
                    //调用接口服务查询卡片信息
                    this.getTransactionLog();
                });
            }
        } else {
            this.setState({
                idCardNo: userInfo.sfzh
            })
            //调用接口服务查询卡片信息
            this.getTransactionLog();
        }
        this.queryPackageType()
        // this.getTransactionLog()
    }

    //开始时间结束时间处理
    onConfirm = (startTime, endTime) => {//文件返回赋值
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            startTime: startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate(),
            endTime: endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate(),
            value: startTime.toLocaleDateString() + "~" + endTime.toLocaleDateString()
        });
        this.onChange();//
    }
    clear = () => {//点击取消
        this.setState({
            value: '',
            startTime: '',
            endTime: ''
        }, () => {
            this.getTransactionLog();//点击取消按钮查询流水记录
        })
    };

    onChange = (value) => {
        this.setState({
            pageNo: 1,
            totalPage: 0,
            dsplist: [],
        }, () => {
            console.log(this.state.dsplist);
            this.getTransactionLog();//交易流水记录获取
        })
    }
    //组件事件处理结束


    //获取交易流水
    async getTransactionLog(even) {
        if (even !== undefined) {
            this.setState({
                pageNo: 1,
                pageSize: 10,
                dsplist: []
            })
        }
        const { pageNo, pageSize, startTime, endTime, accountTypeCode, idCardNo } = this.state;

        const url = NEW_YGK_URL + "transactionlog/getTransactionLog";
        let params = new URLSearchParams();
        params.append("pageNo", pageNo);
        params.append("pageSize", pageSize);
        params.append("starttime", startTime);
        params.append("endTime", endTime);
        params.append("idCardNo", idCardNo);
        // params.append("idCardNo", "612321199509106233");
        params.append("accountTypeCode", accountTypeCode);
        let dsplist = [];
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then(resolve => {
            if (resolve.status === 200) {
                this.setState({
                    dsplist: dsplist
                })
                dsplist = [...this.state.dsplist, ...resolve.data.records];
                this.setState({
                    dsplist: dsplist,
                    isShowContent: true,
                    pageNo: this.state.pageNo + 1,
                    dataSource: this.state.dataSource.cloneWithRows(dsplist),// 数据源dataSource
                    totalPage: resolve.data.pages,
                    refreshing: false,
                    isLoading: false,
                })
                console.log(this.state.dsplist)
            }
        }, reject => { console.log(reject) })
    }
    //查询钱包类型
    async queryPackageType() {
        let params = [];
        const url = NEW_YGK_URL + "transactionlog/queryPackageTypeList";
        await Axios({
            method: 'get',
            url: url,
            data: params
        }).then((resolve => {

        }, reject => {
            const arrList = reject.data;
            let packageArr = [];
            if (arrList.length === 4) {
                packageArr.push({ value: '05', label: '全部' })
                // eslint-disable-next-line array-callback-return
                arrList.map((itme) => {
                    const obj = {};
                    obj.value = itme.PACKAGE_TYPE_CODE;
                    obj.label = itme.PACKAGE_TYPE;
                    packageArr.push(obj);
                })

            }
            this.setState({
                packageTypeList: packageArr
            })

        }))
    }
    //钱包选择
    getPackageTypeList = (even) => {
        const { packageTypeList } = this.state;
        packageTypeList.map((item, index) => {
            if (item.value === even[0]) {
                let packageName = item.label
                this.setState({
                    accountTypeCode: even,
                    accountTypeName: packageName,
                }, () => {
                    this.getTransactionLog();//交易流水记录获取
                })
            }
        })
    }

    //关闭弹出窗
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    // 下拉刷新
    onRefresh = () => {
        Toast.loading();
        this.setState({
            pageNo: 0,
            totalPage: 0,
            dsplist: [],
        }, () => {
            this.getTransactionLog();//交易流水记录获取
        })
    };

    // 加载更多
    onEndReached = () => {
        if (this.state.isLoading || (this.state.totalPage < this.state.pageNo + 1)) {
            Toast.hide();
            return;
        }
        this.setState({
            isLoading: true,
        }, () => {
            this.getTransactionLog();//交易流水记录获取
        });
    };

    handleDjf = (obj) => {//列表点击事件
        this.setState({
            objdata: obj,
            modal: true
        });
    }
    render() {
        //下拉刷新部分
        // 定义Row，从数据源(dataSurce)中接受一条数据循环到ListView
        const row = (rowData, sectionID, rowID) => {
            console.log("rowData", rowData)
            return (
                <Item multipleLine extra={rowData.shmc} onClick={() => this.handleDjf(rowData)} style={{ paddingLeft: "10px" }}>
                    交易额：{FORMAT.number_format(rowData.a_turnover / 100, 2) + "元"} <Brief>交易类型：{rowData.operation_type_name}</Brief> <Brief style={{ display: "inline-block" }}> 交易时间：{rowData.time_occurrence}</Brief>
                </Item>
            );
        };
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >新一卡通交易记录</NavBar>
                <div className='divmagin'>
                    <SearchBar
                        placeholder='查询'
                        maxLength={8}
                        value={this.state.value}
                        onSubmit={value => { alert(value + '--onSubmit') }}
                        onClear={value => { alert(value + '--onClear') }}
                        onFocus={() => {
                            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                            this.setState({
                                show: true,
                                showShortcut: true
                            })
                        }}
                        onBlur={() => { console.log("onBlur") }}
                        onCancel={() => this.clear()}
                        onChange={this.onChange}
                    >
                    </SearchBar>
                    <Calendar
                        {...this.state.config}
                        visible={this.state.show}
                        onCancel={this.onCancel}
                        onConfirm={this.onConfirm}
                        onSelectHasDisableDate={this.onSelectHasDisableDate}
                    />
                </div>
                <Picker
                    data={this.state.packageTypeList}//设置钱包列表
                    cols={1}
                    onChange={this.getPackageTypeList}//获取钱包列表
                    value={this.state.accountTypeCode}//
                >
                    <List.Item arrow="horizontal">选择钱包查询</List.Item>
                </Picker>
                {/* 下拉刷新部分 */}
                <ListView
                    key={true}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{ textAlign: 'center' }}>
                        {this.state.isLoading ? '正在加载...' : '真的已经到底了'}
                    </div>)}
                    renderRow={row}
                    useBodyScroll={true}
                    style={{
                        height: this.state.height,
                        border: '1px solid #ddd',
                        margin: '5px 0',
                    }}
                    distanceToRefresh='20'
                    pullToRefresh={<PullToRefresh
                        refreshing={this.state.refreshing}
                        onRefresh={this.onRefresh}
                    />}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={20}
                    pageSize={this.state.pageSize}
                />
                {/* 弹窗部分 */}
                <Modal
                    popup
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '详情信息'} >
                        <InputItem
                            type="money"
                            value={this.state.objdata.person_name}
                            editable={false}
                        ><div>姓名</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.person_account}
                            editable={false}
                        ><div>个人账号</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.employee_code}
                            editable={false}
                        ><div>编号</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.equipment_name}
                            editable={false}
                        ><div>设备名称</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.merchant_name}
                            editable={false}
                        ><div>商户名称</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.time_occurrence}
                            editable={false}
                        ><div>发生时间</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.bookkeeping_time}
                            editable={false}
                        ><div>记账时间</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.account_type_name}
                            editable={false}
                        ><div>账户类型</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.objdata.operation_type_name}
                            editable={false}
                        ><div>操作类型</div></InputItem>
                        <InputItem
                            type="money"
                            value={FORMAT.number_format(this.state.objdata.a_turnover / 100, 2) + "元"}
                            editable={false}
                        ><div>交易额</div></InputItem>
                        <InputItem
                            type="money"
                            value={FORMAT.number_format(this.state.objdata.account_balance / 100, 2) + "元"}
                            editable={false}
                        ><div>帐户余额</div></InputItem>
                    </List>
                    <div className="dban">
                        <Button type="primary" inline style={{ marginRight: '4px' }} onClick={this.onClose('modal')}>关闭</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}
