import './yktsqedit.css'
import React, { Component } from 'react'
import { NavBar, Icon, List, Picker, InputItem, TextareaItem, Modal, Button, SearchBar, PickerView, Radio, Toast, ImagePicker } from 'antd-mobile';
import { YKT_URL, DOWNFILEURL, BASE_URL, Axios } from '../../../utils/url';
import { XSZ_URL, CLCRSQ_URL, JHZ_URL, SFZ_URL, XMHT_URL, LDHT_URL } from '../../../utils/fjyl'
import lrz from "lrz";
import ycz from '../../../assets/images/ycz.jpg'
import hkb from '../../../assets/images/hkb.png'
import yktsqd from '../../../assets/images/yktsqd.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Yktsq extends Component {
    constructor(props) {
        super(props);
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        this.state = {
            rybh: "",
            xzqy:"",
            yzbm:"",
            list: [],
            yhlx: [],
            yhzgx:[],
            fzlist: [],
            jtzhlist1: [],
            jtzhlist2: [],
            jtzhlist3: [],
            jtzhid1: "",
            jtzhid2: "",
            jtzhid3: "",
            fzxm: "",
            fzxmvalue: "",
            fzxmid: "",
            fzykth: "",
            yhlxmc: "",
            yhlxbm: "",
            xb: [{ value: '1', label: '男' }, { value: '2', label: '女' }],
            xbdm:"",
            sftz: [{ value: '是', label: '是' }, { value: '否', label: '否' }],
            fjlxlist: [],
            sftzText: "",
            mz: [],
            rylxdm: "",
            tktlxText: "",
            tktlxList: [],
            // tktlxList: [{ value: 'B', label: '配偶及直系亲属(B卡)' }, { value: 'C1', label: '外部单位人员(C卡)' }, { value: 'C3', label: '其他亲属(C卡)' }, { value: 'C2', label: '租房户(C卡)' }],
            ryxx: ryobject,
            sjhm: "",
            sfzh: "",
            sbxm: "",
            ryxm: "",
            lb: "B",
            hzgx: "",
            hzxm:"",
            modal1: false,
            modalhzxm: false,
            multiple: false,
            hkbfiles: [],
            hzyktfiles: [],
            jhzfiles: [],
            yczfiles: [],
            hzsfzfiles: [],
            brsfzfiles: [],
            yktsqdfiles: [],
            yhmzText: "",
            mzdm:"",
            fwbhlist: [],
            fwbhs: "",
            fwbh: "",
            dwlist: [],
            yhdw:"",
            yhdws: "",
            yhdwbm: "",
            yhxb: "",
            jtzz: "",
            previewImage: '',
            sfzyl: false,
            ychdzpyl: false,
            yktyl: false,
            hkbyl: false,
            jhzyl: false,
            yktsqdyl: false,
            bz:"",
            zzjgList:[],
            yczcc:"",
            yktlx:"",
            hasError: false,
            hasErrorPhone: false,
        }
    }

    componentDidMount = () => {
        // this.getyhlx()
        this.getmz();
        this.getlx();
        this.getgzxm();
        this.getZzjg();
        this.getYczcc();
        // this.getSmxx();
        this.state.ryxm = this.state.ryxx.ryxm;

    }

    async getlx() {
        const url = YKT_URL + 'blxdmController/getlx';
        var params = new URLSearchParams();
        // let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        // params.append("yhsfzh", ryobject.sfzh);
        // params.append("yhsfzh", "61032119721106085X");
        await Axios({
            method: 'post',
            url: url,
            // data: params
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {

                this.setState({
                    tktlxList: res.data
                });

            }
        });
    }






    async getmz() {
        const url = YKT_URL + 'blxdmController/getMz';
        var params = new URLSearchParams();

        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    mz: res.data
                });
            }
        });
    }



    async getgzxm() {
        console.log("获取户主信息");
        const url = YKT_URL + 'blxdmController/getHzgx';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    yhzgx: res.data
                });
            }
        });
    }

    async getZzjg() {
        console.log("组织机构");
        const url = YKT_URL + 'zzjgController/getZzjg';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    dwlist: res.data
                });
            }
        });
    }
    async getYczcc() {
        console.log("一寸照尺寸");
        const url = YKT_URL + 'zzjgController/getYczcc';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
        }).then((res) => {
            console.log(res.data[0].pzvalue);
            if (res.status === 200) {
                this.setState({
                    yczcc: res.data[0].pzvalue
                });
            }
        });
    }





    SerMapDw = (e) => {
        return this.state.fzlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.fzonChange(i)}  >
                {i.label + "-" + i.yktzh}
            </RadioItem>
        ))
    }



    setdwlist = (e) => {
        return this.state.dwlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.dwChange(i)}  >
                {i.label}
            </RadioItem>
        ))
    }
    dwChange = (e) => {
        console.log(e)
        console.log(e.label)
        this.setState({
            dwsc: false,
            yhdw: e.label,
            yhdwbm:e.value
        });
    }





    download = () => {
        window.open(DOWNFILEURL + 'yktsqxx/download?fjlx=yktsqfj')
    }

    imgonclick = (index, files) => {
        this.setState({
            previewImage: files[index].url,
            visible: true
        });
    }

    genID = (length) => {
        return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
    }
    onClosesfz = () => {
        this.setState({
            sfzyl: false
        });
    }
    onClosehdzp = () => {
        this.setState({
            ychdzpyl: false
        });
    }
    onCloseykt = () => {
        this.setState({
            yktyl: false
        });
    }
    onClosehkb = () => {
        this.setState({
            hkbyl: false
        });
    }

    onCloseyktsqd = () => {
        this.setState({
            yktsqdyl: false
        });
    }
    showModal = key => (e) => {
        if (this.state.sftzText == "否" && key == "modal1") {
            return;
        }
        this.setState({
            [key]: true,
        });
    }
    onClose = key => (e) => {
        this.setState({
            [key]: false,
        });
    }
    xbChange = (e) => {
        // console.log(e)

        console.log(e);
        const list = this.state.xb
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                this.setState({
                    yhxb: jValue.label,

                });
            }
        }
        this.setState({
            xbdm: e
        });
    }
    dwsearch = (val) => {
        // this.getgzxm(val);
        console.log(val);
        this.setState({
            yhdws: val
        }, () => {
            this.getZzjg();
        });
    }
    mzChange = (e) => {
        console.log(e)
        const list = this.state.mz
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                this.setState({
                    yhmzText: jValue.label,
                    mzdm:jValue.value
                });
            }
        }

        this.setState({
            yhmz: e
        });
    }
    yhzgxChange= (e) => {
        console.log(e)
        console.log(this.state.yhzgx);
        const list = this.state.yhzgx
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                this.setState({
                    hzgxText: jValue.label,
                });
            }
        }

        this.setState({
            hzgx: e
        });
    }
    onChange = (brsfzfiles, type, index) => {
        console.log(brsfzfiles);
        var str=[];
        if(type!="remove"){
        for(let i=0;i<brsfzfiles.length;i++){

              lrz(brsfzfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file],this.genID(16)+"."+brsfzfiles[i].file.name.split(".")[1], { type: brsfzfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    brsfzfiles: str,
                })
              })

          }
        }else{
            this.setState({
              brsfzfiles,
          });
          }
        // this.setState({
        //     brsfzfiles,
        // });
    }
    yczponChange = (yczfiles, type, index) => {
        var str=[];
        let yczfj="";
        if(type!="remove"){
        for(let i=0;i<yczfiles.length;i++){

              lrz(yczfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let img = new Image();
                img.src = rst.base64;
                img.onload = () => {
                    // 为什么要写 onload  是因为要等他加载完之后才能获取到图片宽高
                        // resolve(img.naturalWidth, img.naturalHeight);   //  2064,4608
                        console.log(img.naturalWidth);
                        console.log(img.naturalHeight);

                    };
                    yczfj = img.naturalWidth.toString()+"*"+img.naturalHeight.toString();
                    console.log(yczfj);
                    if(yczfj!=this.state.yczcc){
                        Toast.info("您的照片不符合规定，请重新调准大小后上传")
                        return;
                    }else{
                        let file = new File([rst.file], this.genID(16)+"."+yczfiles[i].file.name.split(".")[1], { type: yczfiles[i].file.type, lastModified: Date.now() });
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        str.push(arr[0])
                        this.setState({
                            yczfiles: str,
                        })
                    }

              })

          }
        }else{
            this.setState({
              yczfiles,
          });
          }
        // console.log(yczfiles, type, index);
        // this.setState({
        //     yczfiles,
        // });
    }

    yktChange = (yktsqdfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<yktsqdfiles.length;i++){

              lrz(yktsqdfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+yktsqdfiles[i].file.name.split(".")[1], { type: yktsqdfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    yktsqdfiles: str,
                })
              })

          }
        }else{
            this.setState({
              yktsqdfiles,
          });
          }
        // console.log(yktsqdfiles, type, index);
        // this.setState({
        //     yktsqdfiles,
        // });
    }
    //身份证号错误提示
  onErrorClick = () => {
    if (this.state.hasError) {
      Toast.info('请输入正确的身份证号',1,null,false);
    }
  }
  //手机输入错误提示
  onErrorClickPhone = () => {
    if (this.state.hasErrorPhone) {
      Toast.info('请输入正确的手机号',1,null,false);
    }
  }
  //绑定手机号phone的onchange事件
  onChangePhone = (value) => {
    const str = value.replace(/\s*/g,"");
    console.log(str);
    if (value.replace(/\s/g, '').length !== 11) {
      this.setState({
        hasErrorPhone: true,
      });
    } else {
      this.setState({
        hasErrorPhone: false,
      });
    }

    this.setState((state, props) => {
      return {

        sjhm: str,
      }
    },
      () => {


      })

  }
   //绑定idcard的onchange事件
   onChangeId = (value) => {
    if (value.replace(/\s/g, '').length !== 18) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
    }
    this.setState({
      sfzh: value,
    });
  }
  checkSfzh = (e) => {
    const url = YKT_URL + 'bryxxController/checkSfzh';
    var params = new URLSearchParams();
    params.append('sfzh', e);
    Axios({
      method: 'post',
      url: url,
      data: params,

    }).then((res) => {
      console.log(res)
      if (res.data.flag === false) {
        // Toast.info(res.data.msg,1,null,false)
        Toast.info(res.data.msg)
        this.setState({
          sfzh: ''
        })
      }

    });
    this.setState({
      sfzh: e
    })
  }
    handleSubmit = e => {
        //阻止表单提交行为
        e.preventDefault()
        console.log('表单提交')

        if(this.state.yktlx == "B" || this.state.tktlxText == '租房户'){
            if(this.state.hzxm =="" || this.state.hzgx==""){
                Toast.info("请填写户主姓名和与户主关系后在提交", 4, null, false);
            }
        }
        if(this.state.ryxx.ryxm==""||this.state.yhxb==""||this.state.tktlx==""||this.state.yhmzText==""||this.state.sjhm==""||this.state.ryxx.sfzh==""||this.state.jtzz==""){
            Toast.info("请将表单填写完整", 4, null, false);
            return;
        }
        if(this.state.yczfiles.length!=0&&this.state.yktsqdfiles.length!=0&&this.state.brsfzfiles!=0){
            console.log(111)
        }else{
            Toast.info("请上传相关附件", 4, null, false);
            return;
        }
        console.log("判断是否可以提交");
        const url = YKT_URL + 'bryxxController/saveYkt';
        let params = new FormData()
        params.append('ryxm', this.state.ryxx.ryxm);
        params.append('rybh', this.state.rybh);
        params.append('xbmc', this.state.yhxb);
        params.append('xbdm', this.state.xbdm);
        params.append('rylxdm', this.state.rylxdm);
        params.append('rylxmc', this.state.tktlxText);
        params.append('mzdm', this.state.mzdm);
        params.append('mzmc', this.state.yhmzText);
        params.append('zjlxdm', "01");
        params.append('zjlxmc', "身份证");
        params.append('zjbh', this.state.sfzh);
        params.append('jgdm', this.state.yhdwbm);
        params.append('jdmc', this.state.yhdws);
        params.append('xzqybm', "sjsq");
        params.append('xzqymc', this.state.xzqy);
        params.append('lxsjh', this.state.sjhm);
        params.append('lxdz', this.state.jtzz);
        params.append('fwbh', this.state.fwbh);
        params.append('yzbm', this.state.yzbm);
        params.append('yhid', this.state.sfzh);
        params.append('yhxm', this.state.ryxx.ryxm);
        params.append('ykth', "申请中");
        params.append('bz', '手机申请待确认');
        params.append('dqzt', 8);
        let fileinfo = []
        if (this.state.yczfiles.length !== 0) {
            for (let f of this.state.yczfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '03' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }
        if (this.state.brsfzfiles.length !== 0) {
            for (let f of this.state.brsfzfiles) {
                params.append("fjList", f.file);
                console.log(f.file);
                fileinfo.push({ name: f.file.name, type: '2' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }
        if (this.state.yktsqdfiles.length !== 0) {
            for (let f of this.state.yktsqdfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '1' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        params.append('fileinfo', JSON.stringify(fileinfo));
        console.log(fileinfo);
        Axios({
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: url,
            data: params,
            timeout: 900000, //这个就是重点
            // transformRequest: [function () { return params; }],
        }).then((res) => {
            console.log(res);
            if (res.status == 200 && res.data.msg == "保存成功!") {
                // Toast.info(res.data.msg)
                Toast.success(res.data.msg, 1);
                //
                let _that = this;
                setTimeout( function(){
                  _that.props.history.go(-1)
                  }, 1000 );
              }
        });
    }
    setContent = () => {

        return (
            <form onSubmit={this.handleSubmit}>
            <div>
                <InputItem value={this.state.ryxm} onChange={(value) => this.setState({ ryxm: value })}>
                    <div ><span className="error">*</span>用户姓名</div>
                </InputItem>
                <Picker
                    data={this.state.xb}
                    cols={1}
                    onChange={this.xbChange}
                    value={this.state.xbdm}
                >
                    <List.Item arrow="horizontal"><span className="error">*</span>性别</List.Item>
                </Picker>
                {/* <InputItem
                    placeholder="请输入人员编号"
                    ref={el => this.labelFocusInst = el}
                    value={this.state.rybh}
                    onChange={(value) => this.setState({ rybh: value })}
                ><div onClick={() => this.labelFocusInst.focus()}>人员编号</div></InputItem> */}
                <Picker
                    data={this.state.tktlxList}
                    cols={1}
                    onOk={this.handleFromChange}
                    value={this.state.tktlx}
                >
                    <List.Item arrow="horizontal"><span className="error">*</span>人员类型</List.Item>
                </Picker>
                <Picker
                    data={this.state.mz}
                    cols={1}
                    onChange={this.mzChange}
                    value={this.state.yhmz}
                >
                    <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                </Picker>


                <InputItem placeholder="请选择所属机构" value={this.state.yhdw} onClick={this.showModal('dwsc')} editable="false">
                        <div ><span className="error">*</span>机构</div>
                    </InputItem>
                {/* <InputItem placeholder="请输入行政区域"  value={this.state.xzqy}  onChange={(value) => this.setState({ xzqy: value })}>
                    <div ><span className="error">*</span>行政区域</div>
                </InputItem> */}
                <InputItem
          maxLength="18"
          type="text"
          onChange={this.onChangeId}
          value={this.state.sfzh}
          error={this.state.hasError}
          onErrorClick={this.onErrorClick}
          placeholder="请输入身份证号"
          onBlur={this.checkSfzh}
        ><span className="error">*</span>身份证号</InputItem>
                <InputItem
          type="phone"
          placeholder="186 1234 1234"
          error={this.state.hasErrorPhone}
          onErrorClick={this.onErrorClickPhone}
          onChange={this.onChangePhone}
          value={this.state.sjhm}
        //   onBlur={this.checkSjh}
        ><span className="error">*</span>手机号码</InputItem>
                <Modal
                    popup
                    visible={this.state.dwsc}
                    cols={1}
                    value={this.state.yhdws}
                    onClose={this.onClose('dwsc')}
                    animationType="slide-up"
                    style={{
                        height: "280px",
                        overflow: "auto"
                    }}
                >
                    {/* <SearchBar placeholder="请输入单位名称" cancelText="查询" onCancel={this.dwsearch} /> */}
                    {this.setdwlist()}

                </Modal>


                <InputItem placeholder="请输入联系地址" value={this.state.jtzz} onChange={(value) => this.setState({ jtzz: value })}>
                    <div ><span className="error">*</span>联系地址</div>
                </InputItem>
                <InputItem placeholder="请输入房屋编号" value={this.state.fwbh} onChange={(value) => this.setState({ fwbh: value })}>
                    <div >房屋编号</div>
                </InputItem>
                <InputItem placeholder="请输入邮政编码" value={this.state.yzbm} onChange={(value) => this.setState({ yzbm: value })}>
                    <div >邮政编码</div>
                </InputItem>
                <InputItem placeholder="请输入户主姓名" value={this.state.hzxm} onChange={(value) => this.setState({ hzxm: value })}>
                    <div >户主姓名</div>
                </InputItem>
                <Picker
                    data={this.state.yhzgx}
                    cols={1}
                    onChange={this.yhzgxChange}
                    value={this.state.hzgx}
                >
                    <List.Item arrow="horizontal">与户主关系</List.Item>
                </Picker>
                <TextareaItem
                    title="备注"
                    placeholder="请填写备注"
                    value={this.state.bz}
                    onChange={(value) => this.setState({ bz: value })}
                    data-seed="logId"
                    ref={el => this.autoFocusInst = el}
                    autoHeight
                />
                <List>
                    <Modal
                        visible={this.state.sfzyl}
                        transparent
                        footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosesfz(); } }]}
                    >
                        <div style={{ marginTop: 50, transform: this.state.transStyle, display: 'flex', alignItems: 'center' }}>
                            <img
                                alt="example"
                                style={{ width: '100%', height: '100%' }}
                                src={SFZ_URL}
                            />
                        </div>
                        {/* <div style={{clear:'both'}} /> */}
                    </Modal>

                    <Modal
                        visible={this.state.yktyl}
                        transparent
                        footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseykt(); } }]}
                    >
                        <div style={{ marginTop: 50, transform: this.state.transStyle, display: 'flex', alignItems: 'center' }}>
                            <img
                                alt="example"
                                style={{ width: '100%', height: '100%' }}
                                src={YKT_URL}
                            />
                        </div>
                        {/* <div style={{clear:'both'}} /> */}
                    </Modal>
                    <Modal
                    visible={ this.state.ychdzpyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosehdzp(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={ycz}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                    <Modal
                        visible={this.state.yktsqdyl}
                        transparent
                        footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseyktsqd(); } }]}
                    >
                        <div style={{ marginTop: 50, transform: this.state.transStyle, display: 'flex', alignItems: 'center' }}>
                            <img
                                alt="example"
                                style={{ width: '100%', height: '100%' }}
                                src={yktsqd}
                            />
                        </div>
                        {/* <div style={{clear:'both'}} /> */}
                    </Modal>
                </List>
                <div className="scfj" style={{
                    fontSize: "14px",
                    color: "#888"
                }}>上传附件</div>
                <div className="scfj"><span style={{ color: "red" }}>*</span>办卡人身份证(<span onClick={() => this.setState({ sfzyl: true })} style={{
                    color: "#108ee9",

                }}>查看样例</span>)</div>
                <div style={{ paddingTop: "10px" }}>
                    {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                    }}>身份证样例(点击查看)</span> */}
                    {/* <ImagePicker
                            files={[{url:SFZ_URL}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                        /> */}
                </div>
                <ImagePicker
                    files={this.state.brsfzfiles}
                    onChange={this.onChange}
                    onImageClick={(index, fs) => console.log(index, fs)}
                    selectable={this.state.brsfzfiles.length < 2}
                    multiple={this.state.multiple}
                    capture="camera"
                />
                <div className="line"></div>
                <div style={{ paddingTop: "10px" }}>
                    {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                        }}>一寸照片样例(点击查看)</span> */}
                    {/* <ImagePicker
                            files={[{url:ycz}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                </div>
                <div className="scfj"><span style={{ color: "red" }}>*</span>一寸照片(红底，宽*高为{this.state.yczcc})(<span onClick={() => this.setState({ ychdzpyl: true })} style={{
                    color: "#108ee9",

                }}>查看样例</span>)</div>
                <ImagePicker
                    files={this.state.yczfiles}
                    onChange={this.yczponChange}
                    onImageClick={(index, fs) => console.log(index, fs)}
                    selectable={this.state.yczfiles.length < 1}
                    multiple={this.state.multiple}
                />
                <div className="line"></div>
                <div style={{ paddingTop: "10px" }}>
                    {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                        }}>一卡通申请单样例(点击查看)<br /> */}

                    {/* <ImagePicker
                            files={[{url:yktsqd}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}

                </div>
                <div className="scfj"><span style={{ color: "red" }}>*</span>一卡通申请单(<span onClick={() => this.setState({ yktsqdyl: true })} style={{
                    color: "#108ee9",

                }}>查看样例</span>)</div>
                <ImagePicker
                    files={this.state.yktsqdfiles}
                    onChange={this.yktChange}
                    onImageClick={(index, fs) => console.log(index, fs)}
                    selectable={this.state.yktsqdfiles.length < 1}
                    multiple={this.state.multiple}
                    capture="camera"
                />
                <span style={{
                    color: "#888",
                    padding: "15px 15px 9px"
                }}>1、申请表单直接<span onClick={() => this.download()} style={{
                    color: "#108ee9",
                }}>  下载</span></span>
                <div style={{ color: "#888", paddingLeft: "15px", paddingRight: "15px", paddingBottom: "10px" }}>2、塔西南主页-服务大厅表单-一卡通申请表</div>
                <div className="formSubmit">
            <button className="submit" type="submit">
              申 请
            </button>
          </div>
            </div>
            </form>
        )

    }
    //类型选择
    handleFromChange = (e) => {

        const list = this.state.tktlxList
        console.log(e)
        console.log(list)
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                this.setState({
                    tktlxText: jValue.label,
                    rylxdm:jValue.value
                });
            }
        }
        this.setState({
            tktlx: e
        }, () => {
            this.getfjlx(e);
        });
    }
    getfjlx=(e)=>{
        console.log(this.state.tktlxText);
        console.log("看看数据几类卡");
        const url = YKT_URL + 'blxdmController/getYktlx';
        var params = new URLSearchParams();
        params.append("lxmc", this.state.tktlxText);
        Axios({
            method: 'post',
            url: url,
            data:params
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    yktlx: res.data[0].nrbds
                });
            }
        });
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >一卡通申请</NavBar>
                <div className="divmagin">
                    {this.setContent()}


                </div>
            </div>
        )
    }
}
