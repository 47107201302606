import './zc.css'
import React from 'react';
import Appdown1 from '../../assets/images/80.png'
import ios from '../../assets/images/IOS.png'
import { TTAZURL } from  '../../utils/url'
import {NavBar, Modal, InputItem, WhiteSpace, Button, Toast,Icon,List,NoticeBar } from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;

class Appdown extends React.Component {
 
android(){
  window.parent.frames.location.href = TTAZURL ;
}

  render() {

    return (
      <div style={{ marginTop: ".89rem" }}>
         <NavBar
            icon={<Icon type="left" />}
            mode="dark"
            onLeftClick={() => this.props.history.goBack()}
           
        >相关下载</NavBar>
        <div style={{ marginTop: "2.89rem" }}>
          <div style={{textAlign:"center"}}>
            <img src={Appdown1} onClick={this.android} style={{ width: '150px', height: '150px',marginTop:'30px' }} alt="" ></img>
            <div style={{fontSize:'18px',fontWeight:'900',marginTop:'10px',marginBottom:'100px'}}>坦途移动办公</div>
            {/* <Button onClick={this.android} type="ghost" inline style={{ marginRight: '4px' }} className="am-button-borderfix">安卓下载</Button> */}
            {/* <Button onClick={this.ios} type="ghost" inline style={{ marginRight: '4px' }} className="am-button-borderfix">ios下载</Button> */}
          </div>
         
        </div>
        <div style={{color:'red',fontSize:'18px',fontWeight:'900',textAlign:"center"}}>安卓坦途下载:请点击上面图标下载</div> 
        <div style={{color:'red',fontSize:'18px',fontWeight:'900',textAlign:"center"}}>IOS坦途下载:企业级app需按以下说明下载</div> 
        <img src={ios} style={{ width: '100%',marginTop:'30px' }} alt="" ></img>
      </div>
      
    );
  }

}

export default Appdown;
