import './postponeApply.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,Toast,ImagePicker,Modal } from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
import { SFZ_URL } from '../../../utils/fjyl';
import lrz from "lrz";
const Item = List.Item;
const Brief = Item.Brief;
export default class Yktsfyz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:{},
            files:[],
            multiple: false,
            bz:"",
            yl:''
        }
    }

    componentDidMount = () => {
        this.getList();
    }

    //查询当前一卡通详情信息
    async getList() {
      console.log("执行获取数据");
      let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        const url = NEW_YGK_URL+'postpone/getPostponePersonInfo';
        var params = new URLSearchParams();
        params.append("idCardNo", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((res) => {
            console.log(res.data);
            if(res.data.flag){
                this.setState({
                    list: res.data.data[0]
                })
            }
        });
    }

    onChange = (files, type, index) => {
        console.log(files, type, index);
        var str=[];
        if(type!=="remove"){
        for(let i=0;i<files.length;i++){

            lrz(files[i].url, { quality: 0.5 }) .then((rst) => {
              console.log(rst)
              let file = new File([rst.file], this.genID(16)+"."+files[i].file.name.split(".")[1], { type: files[i].file.type, lastModified: Date.now() });
              var arr = [{ orientation: 1, url: rst.base64, file: file }];;
              str.push(arr[0])
              this.setState({
                files: str,
              })
            })
        }
      }else{
        this.setState({
        files,
      });
      }
      }
      genID = (length) => {
        return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
      }

      handleChange = (e) => {
        this.setState({bz: e});
      }

      handleSubmit = e => {
        e.preventDefault()
        console.log('表单提交')
        console.log(this.state.files)
        const url = NEW_YGK_URL+'postpone/addPostponeBills';
        let params = new FormData();
        params.append('cardNo', this.state.list.CARD_NO_NEW);
        params.append('personId', this.state.list.PERSON_ID);
        params.append('personName', this.state.list.PERSON_NAME);
        params.append('gender', this.state.list.SEX);
        params.append('cardType', this.state.list.HR_NO.substr(0, 1));
        params.append('idCardNo', this.state.list.ID_CARD_NO);
        params.append('phone', this.state.list.PHONE_NO);
        params.append('hrNo', this.state.list.HR_NO);

        params.append('bz', this.state.bz);
        params.append('personTypeName', this.state.list.PERSON_TYPE_NAME);
        params.append('personType', this.state.list.PERSON_TYPE);
        params.append('cardholderId', this.state.list.CARDHOLDER_ID);
        params.append('unitName', this.state.list.UNIT_NAME);
        params.append('workerUnit', this.state.list.WORK_UNIT_NAME);
        if(this.state.files.length !==0){
          for(var i=0;i<this.state.files.length;i++){
            params.append("affix",this.state.files[i].file);
            console.log(this.state.files[i].file)
          }
        }else{
          Toast.info('请提交附件后再提交申请', 3, null, false);
          return;
        }

          Toast.loading("加载中...",0,null,true);
          Axios({
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: url,
            data: params,
            //这部分非常重要，否则formdata会被转格式
            // transformRequest: [function(){return params;}],
          }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
              Toast.info(res.data.msg, 2, null, false);
            }
            this.props.history.go(-1)

          });
      }




    render() {
        const { files} = this.state
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通门禁延期申请</NavBar>
            <form onSubmit={this.handleSubmit}>
             <List >
             <div className="scfj" style={{marginTop:"50px",color:"#888",fontSize:"14px"}}>申请信息</div>
             <InputItem
              value={this.state.list.PERSON_NAME}
              editable
            ><div >姓名</div></InputItem>

            <InputItem
              value={this.state.list.SEX}
              editable
            ><div >性别</div></InputItem>

            <InputItem
              value={this.state.list.CARD_NO_NEW}
              editable
            ><div >一卡通号</div></InputItem>

            <InputItem
              value={this.state.list.ID_CARD_NO}
              editable
            ><div >证件编号</div></InputItem>

            <InputItem
              value={this.state.list.PHONE_NO}
              editable
            ><div >联系电话</div></InputItem>

            <InputItem
              value={this.state.bz}
              onChange={this.handleChange}
            ><div>备注</div></InputItem>
            <Modal
              visible={ this.state.yl }
              transparent
              footer={[{ text: '关闭', onPress: () => {this.setState({ yl: false })}} ]}
            >
              <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                  <img
                      alt="example"
                      style={{width: '100%',height:'100%' }}
                      src={SFZ_URL}
                  />
              </div>
            </Modal>
<div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
    <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>身份证正反面(<span onClick={()=>this.setState({ yl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={files}
              onChange={this.onChange}
              onImageClick={(index, fs) => console.log(index, fs)}
              selectable={files.length < 2}
              multiple={this.state.multiple}
              capture="camera"
            />


          </div>
             </List>
             <div className="formSubmit">
            <button className="submit" type="submit">
              申 请
            </button>
            </div>
             </form>
            </div>
        )
    }
}
