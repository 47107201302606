import './scanQrCode.css'
import React, { Component } from 'react'
import { NavBar, Icon, List, InputItem } from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';

const type = "POST";
const Item = List.Item;
export default class ScanQrCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNo: '',
            idCardNO: '',
            personName: '',
            employeeNo: '',
            phoneNO: '',
            title: '',
            photo: '',
            unitName: '',
            permissionDate: '',
            permissionName: '',
            consumePermissionDate: '',
            consumePermissionName: '',
            isShow: false,
            msg: '',
            warning: true
        }
    }
    componentDidMount = () => {

    }
    //通过卡片编号获取卡片信息
    async getPersonInfoByCardNo(value) {
        console.log(value);
        if (value) {
            let params = {};
            params.cardNo = value.trim();
            let url = NEW_YGK_URL + "CardholderController/queryCardInfoByCardNo";
            console.log("url:" + url)
            await Axios({ method: type, url: url, data: params }).then(resolve => {
                if (resolve.data.flag) {
                    const obj = resolve.data.data;
                    if (obj.personName) {
                        this.setState({
                            warning: false,
                            isShow: true,
                            cardNo: "No." + obj.cardNo,
                            idCardNO: obj.id_card_no.substring(0, 4) + "**********" + obj.id_card_no.substring(14, 18),
                            personName: obj.personName,
                            employeeNo: obj.hrNo,
                            phoneNO: obj.phoneNo,
                            photo: obj.imgUrl,
                            unitName: obj.unitName,
                            permissionDate: '********',
                            permissionName: '********',
                            consumePermissionDate: '********',
                            consumePermissionName: '********',
                        })
                    } else {
                        this.setState({
                            isShow: false,
                            msg: '无此卡片信息',
                            warning: true
                        })
                    }


                } else {
                    this.setState({
                        isShow: false,
                        warning: true,
                        msg: resolve.data.msg
                    })

                }

            }, reject => {
                console.log(reject);
            })
        } else {
            this.setState({
                isShow: false,
                warning: true
            })
        }

    }
    //输入框失去焦点是调用根据卡片编号获取卡片信息
    handleChange(val) {
        this.getPersonInfoByCardNo(val);
    }

    render() {
        const { warning, isShow, msg, phoneNO, photo, personName, employeeNo, unitName, cardNo, idCardNO, permissionDate, permissionName, consumePermissionDate, consumePermissionName } = this.state; //解构赋值
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    key={2}
                    mode="dark"
                    icon={<Icon type="left" />}
                    rightContent={[
                        <div onClick={(value) => this.getPersonInfoByCardNo(value)} style={{ display: 'none' }}>扫一扫</div>
                    ]}
                    onLeftClick={() => this.props.history.goBack()}
                >卡片信息</NavBar>
                <div style={{ width: '100%', height: '40px', marginTop: '50px' }}>
                    <InputItem type="money"
                        name='cardNo'
                        moneyKeyboardAlign='left'
                        pattern='[0-9]*'
                        placeholder='卡片编号的纯数字'
                        style={{ display: 'inline', wdith: '10px' }}
                        onBlur={val => { this.handleChange(val) }}
                    >编号:No.
                    </InputItem>
                    {warning ? (
                        <div style={{ color: 'red', fontWeight: '', marginTop: '50px' }}>请输入卡片背面二维码下方的卡片编号的纯数字查询</div>
                    ) : null}

                </div>

                {isShow ? (

                    <List>
                        <div style={{ textAlign: 'center' }} >
                            <img src={photo} style={{ width: '180px', height: '220px', textAlign: 'center' }} />
                        </div>
                        <Item>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：{personName}</Item>
                        <Item>人员编号：{employeeNo}</Item>

                        <Item>组织机构：{unitName}</Item>
                        <Item>卡片编号：{cardNo}</Item>

                    </List>
                ) :
                    <List align='middle'>
                        <Item align='middle' style={{ color: 'red' }}>{msg}</Item>
                    </List>
                }
            </div>
        )
    }
}
