import './yktsfyzshow.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,ImagePicker,Modal } from 'antd-mobile';
import { BASE_URL, Axios } from '../../../utils/url';
const Item = List.Item;
export default class Yktmjqxsqshow extends Component {
    constructor(props) {
        super(props);
        const list = this.props.location.query.detail;
        this.state = {
            files:[],
            multiple: false,
            bz:"",
            list,
            // yqsqid:yqsqid,
            visible: false
        }
    }

    componentDidMount = () => {
        // this.getList();
        // this.getimgList();
    }





    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}
             onLeftClick={() => this.props.history.goBack()}
            >{this.state.list.ddmc}详情</NavBar>
            <form onSubmit={this.handleSubmit}>
             <List renderHeader={() => '一卡通基本信息'} >
             <div className="scfj">申请信息</div>
             <InputItem
              value={this.state.list.ryxm}
              editable
            ><div >姓名</div></InputItem>

            <InputItem
              value={this.state.list.mz}
              editable
            ><div >民族</div></InputItem>

            <InputItem
              value={this.state.list.yktbh}
              editable
            ><div >一卡通号</div></InputItem>

            <InputItem
              value={this.state.list.zjbh}
              editable
            ><div >证件编号</div></InputItem>
            <InputItem
              value={this.state.list.ddmc}
              editable
            ><div >权限名称</div></InputItem>

            <InputItem
              value={this.state.list.lxdh}
              editable
            ><div >联系电话</div></InputItem>

            <InputItem
              value={this.state.list.dqzt}
              editable
            ><div >当前状态</div></InputItem>

            <InputItem
              value={this.state.list.thyj=="undefined"?"":this.state.list.thyj}
              editable
            ><div >退回意见</div></InputItem>

             </List>
             </form>
            </div>
        )
    }
}
