import './postpone.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,Toast } from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
export default class Postpone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:{},
            dqzt:"待延期",
            todate:"",
            tjlist:[]
        }
    }

    componentDidMount = () => {
        this.getList();
    }

    //查询当前一卡通详情信息
    async getList() {
      console.log("查询当前一卡通详情信息");
      let ryobject=JSON.parse(localStorage.getItem('userInfo'));

        const url = NEW_YGK_URL+'postpone/getPostponePersonInfo';
        var params = new URLSearchParams();
        params.append("idCardNo",ryobject.sfzh);
        // params.append("idCardNo","612321199509106233");
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                console.log(res.data.data[0]);
                if(res.data.data[0].HR_NO.substr(0,1) !== 'A' || res.data.data[0].HR_NO.substr(0,1) !== 'B'){
                    this.setState({
                        list: res.data.data[0]
                    });
                    this.getPostponeStatus();//获取当前是否有延期申请
                    this.getPostponeSubmitList();//获取当前用户提交过的列表

                }else{
                    alert(1)
                    this.setState({
                        list: res.data.data[0]
                    });
                    Toast.info('您当前所有一卡通类型不支持手机延期', 3, null, false);
                }

            }else{
                Toast.info('您登记信息有误，请持证件到窗口处理', 3, null, false);
            }
        });
    }

    //查询当前人员一卡通申请提交状态
    async getPostponeStatus() {
        console.log("查询当前人员一卡通申请提交状态");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          const url = NEW_YGK_URL+'postpone/queryPostponeRecord';
          var params = new URLSearchParams();
          params.append("idCardNo", ryobject.sfzh);
        // params.append("idCardNo", "612321199509106233");
          await Axios({
              method: 'post',
              url: url,
              data: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((res) => {
              console.log(res);
              if (res.data.flag) {
                    this.setState({
                        dqzt:"待提交",
                    });
              }else{
                this.setState({
                    dqzt:"待延期",
                });
              }
          });
      }

      //获取当前人员的提交列表
    async getPostponeSubmitList() {
        console.log("获取当前人员的提交列表");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          const url = NEW_YGK_URL+'postpone/queryPostponeBillsByIdCardNo';
          var params = new URLSearchParams();
          params.append("idCardNo", ryobject.sfzh);
        // params.append("idCardNo", "612321199509106233");
          await Axios({
              method: 'post',
              url: url,
              data: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((res) => {
              if (res.data.flag) {
                console.log(res.data.data);
                  this.setState({
                    tjlist: res.data.data
                  });
              }
          });
      }
      status = (dqzt) => {
          if(dqzt==="待延期"){
              return "待延期"
          }
          if(dqzt==="已延期"){
            return "已延期"
        }
        if(dqzt==="已退回"){
            return "已退回"
        }
      }

      setMapList = () => {
          console.log(this.state.tjlist);
            return this.state.tjlist.map((item, key) => (
                <Item multipleLine extra={this.status(item.STATUS)} key={key} onClick={() => this.handleJump(item)}>
                    {item.APPLY_PERSON_NAME} <Brief>{item.CREATE_TIME}</Brief>
                </Item>
            ))
    }

    handleJump=(item)=>{
        this.props.history.push({ pathname : "./postponeShow" , query : { postponeId : item.POSTPONE_ID}});
    }

    handleSq=()=>{
        if(this.state.list.HR_NO.substr(0,1)==="A"||this.state.list.HR_NO.substr(0,1)==="B"){
            Toast.info('您的一卡通类型无法提交申请', 3, null, false);
            return;
        }
        if(this.state.dqzt==="待延期"){
            Toast.info('您已经提交了申请，正在审核中,请耐心等待', 3, null, false);
            return;
        }

        var startdate = new Date();
        var endDate=this.state.list.PERMISSION_DATE;
              console.log(endDate)
              //当前时间
              var sDate = new Date(startdate).getTime();

              console.log(sDate)
              // console.log(stime);
              //到期时间
              // var eDate = new Date(endDate).getTime();
              var eDate=new Date(endDate.replace(/-/g, "/")).getTime();
              console.log(eDate)
              var thisMothDays = 1000 * 3600 * 24 * 30;
             var xsDate = eDate - sDate;
              if (eDate - sDate > 0) {
                if (xsDate > thisMothDays) {
                  Toast.fail('请您于到期时间一个月内提交', 3, null, false);
                  return;
                }else{
                    this.props.history.push('./postponeApply');
                }

              }else if(eDate < sDate){
                this.props.history.push('./postponeApply');
              }
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}
              rightContent={[
                <div onClick={this.handleSq}>申请</div>
              ]}
             onLeftClick={() => this.props.history.goBack()}
            >C卡门禁延期</NavBar>
            <form onSubmit={this.handleSubmit}>
             <List  >
             <div className="scfj" style={{marginTop:"50px",color:"#888",fontSize:"14px"}}>一卡通基本信息</div>
             <InputItem
              value={this.state.list.PERSON_NAME}
              editable
            ><div >姓名</div></InputItem>

            <InputItem
              value={this.state.list.SEX}
              editable
            ><div >性别</div></InputItem>

            <InputItem
              value={this.state.list.CARD_NO_NEW}
              editable
            ><div >一卡通号</div></InputItem>

            <InputItem
              value={this.state.list.HR_NO}
              editable
            ><div >人员编号</div></InputItem>

            <InputItem
              value={this.state.list.ID_CARD_NO}
              editable
            ><div >证件编号</div></InputItem>

            <InputItem
              value={this.state.list.PHONE_NO}
              editable
            ><div >联系电话</div></InputItem>

            <InputItem
              value={this.state.list.PERMISSION_DATE}
              editable
            ><div >到期时间</div></InputItem>

            {/* <InputItem
              value={this.state.dqzt}
              editable
            ><div >申请状态</div></InputItem> */}
            <div className="scfj">申请说明</div>
            <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>
            C卡门禁延期申请提交后，预计三个工作日内审核完成</p>
            <div className="scfj">申请记录</div>
            {this.setMapList()}
             </List>
             </form>
            </div>
        )
    }
}
