import './zc.css'
import React from 'react';
import Appdown1 from '../../assets/images/jdhcewm.png'
import { TTAZURL, Axios } from  '../../utils/url'
import {NavBar, Modal, InputItem, WhiteSpace, Button, Toast,Icon,List,NoticeBar } from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;

class Jdhcdown extends React.Component {

  render() {

    return (
      <div style={{ marginTop: ".89rem" }}>
         <NavBar
            icon={<Icon type="left" />}
            mode="dark"
            onLeftClick={() => this.props.history.goBack()}

        >相关下载</NavBar>
        <div style={{ marginTop: "2.89rem" }}>
          <div style={{textAlign:"center"}}>
            <img src={Appdown1}  style={{ width: '80%', height: '300px',marginTop:'30px' }} alt="" ></img>
            <div style={{fontSize:'18px',fontWeight:'900',marginTop:'10px',marginBottom:'100px'}}>京东慧采APP下载</div>

          </div>

        </div>

      </div>

    );
  }

}

export default Jdhcdown;
