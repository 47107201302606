import './wyfcx.css'
import React, { Component } from 'react'
import { NavBar, Icon,Toast,List } from 'antd-mobile';
import { BASE_URL, Axios } from '../../../utils/url'
const Item = List.Item;
const Brief = Item.Brief;
export default class Wyfcx extends Component {
    constructor(props){
        super(props);
        this.state = {
            fwList: [],
            isLoaded: false
        }
    }
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            yhxm: userInfo.ryxm,
            yhid: userInfo.sfzh
        })
        this.getClglList(userInfo.sfzh);
    }
    async getClglList(sfzh) {
        const url = BASE_URL + 'wysfyw/page1';
        var params = new URLSearchParams();
        params.append("sfzh", sfzh);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            Toast.hide();
            console.log(res)
            if (res.status === 200) {
                this.setState({
                    fwList: res.data,
                    isLoaded: true
                });
            }
        });
    }
    handleJump=(item)=>{
        this.props.history.push({ pathname: '/wyfcxmx', query: { fjh: item.owner_no } });
    }
    setMapfwxx=()=>{
        if (!this.state.isLoaded) {
            return null;
        } else {
            if(this.state.fwList.length>0){
                return this.state.fwList.map((item, key) => (
                    <Item multipleLine  key={key} onClick={() => this.handleJump(item)}>
                        {item.owner_name} <Brief>{item.owner_no}</Brief>
                    </Item>
                ))
            }else{
                return <div style={{fontSize:"14px",textAlign:"left",color:"rgb(243 75 98)",paddingLeft:"20px",lineHeight:"25px"}}>*未查找到您名下的房屋，如有问题请携带身份证、房屋合同到大厅维护相关信息</div>
            }

        }
    }
    handlefwbd=()=>{
        this.props.history.push('/fwxxbdlist')
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={<div onClick={() => this.handlefwbd()}>房屋绑定</div>}
                >物业费查询</NavBar>
                <div className="kf">
                    {this.setMapfwxx()}
                </div>
            </div>
        )
    }
}
