import React, { Component } from 'react'
import { utftohex, hextoutf } from '../../../assets/js/hex'
import { BASE_URL, YL_URL, Axios } from '../../../utils/url.js'
import { List, NavBar, InputItem, Toast, Button, WhiteSpace, Icon } from 'antd-mobile';
import './index.css'

const sm2 = require('sm-crypto').sm2;
const cipherMode = 1;
var pubkeyHex =
    "040CD64F13359245B489D67BCD71A209F3FC5A0393CC0CB43F65A7F8F5852ECF03825D9E0C3A5C8E06B765E07F32505EB567126D5F7C420ABA7DBAA2790B0B4176";
var priKey = "008ABF7E03C6BB305E36281DF816E5FB8F1076C61097040A152D9773D43899E292";

export default class xggrxx extends Component {
    constructor(props) {
        super(props);
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.state = {
            // 基础数据
            uname: userInfo.ryxm,
            phone: userInfo.rysx20120716p0048,
            hasErrorPhone: false,//手机号位数判断值
            // 密码相关数据
            password0: '',
            password1: '',
            password2: '',
            repassword0: false,
            repassword1: false,
            repassword2: false,
            // 验证码相关数据
            time: 60000,
            yzmVal: '',
            yzmStatus: false,
            msg: "",
        }
    }
    // 提交
    changeProfileMsg = () => {
        if (this.state.yzm !== this.state.yzmVal || this.state.yzmVal=== '') {
            Toast.info("验证码输入错误或为空,请重新获取验证码!");
            this.setState({
                yzm: ""
            })
            return;
        }
        if (this.state.phone === '' || this.state.password0 === '' || this.state.password1 === '' || this.state.password2 === '') {
            Toast.info('请先完善注册信息再提交!', 1, null, false)
        } else if (this.state.repassword0 || this.state.repassword1 || this.state.repassword2) {
            Toast.info('您输入的信息有误，请检查!', 1, null, false)
        } else {
            const url = BASE_URL + 'bRyzcController/changepassword';
            var params = new URLSearchParams();
            let psw0 = sm2.doEncrypt(utftohex(this.state.password0), pubkeyHex, 0); // 加密结果
            let psw1 = sm2.doEncrypt(utftohex(this.state.password1), pubkeyHex, 0); // 加密结果
            params.append('sjh', this.state.phone);
            params.append('psw0', psw0);
            params.append('psw1', psw1);
            Toast.loading("申请中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {
                console.log(res)
                Toast.info(res.data.msg,1,null,false)
                if (res.data.flag) {
                    localStorage.removeItem('userInfo');
                    localStorage.removeItem('bshtoken');
                    this.props.history.push('/login');
                }
            });
        }
    }
    // 密码chang回调函数*3
    onOldPassword = (value) => {
        if (value.replace(/\s/g, '').length < 6) {
            this.setState({
                repassword0: true,
            });
        } else {
            this.setState({
                repassword0: false,
            });
        }
        this.setState({
            password0: value,
        });
    }
    onNewPassword = (value) => {
        if (value.replace(/\s/g, '').length < 6) {
            this.setState({
                repassword1: true,
            });
        } else {
            this.setState({
                repassword1: false,
            });
        }
        this.setState({
            password1: value,
        });
    }
    onrePassword = (value) => {
        if (value.replace(/\s/g, '').length < 6) {
            this.setState({
                repassword2: true,
            });
        } else {
            this.setState({
                repassword2: false,
            });
        }
        this.setState({
            password2: value,
        });
        if (value !== this.state.password1) {
            this.setState({
                repassword2: true,
            });
        } else {
            this.setState({
                repassword2: false,
            });
        }
    }
    // 密码长度验证提示*3
    onErrorClickPass = () => {
        if (this.state.repassword0) {
            Toast.info('密码长度不能低于6位！', 1, null, false)
        }
    }
    onErrorClickPass1 = () => {
        if (this.state.repassword1) {
            Toast.info('密码长度不能低于6位！', 1, null, false)
        }
    }
    onErrorClickrePass = () => {
        if (this.state.password2.replace(/\s/g, '').length < 6) {
            Toast.info('密码长度不能低于6位！', 1, null, false)
        } else {
            Toast.info('与新密码不一样', 1, null, false)
        }
    }
    //手机输入错误提示
    onErrorClickPhone = () => {
        if (this.state.hasErrorPhone) {
            Toast.info('不足11位', 1, null, false);
        }
    }
    // 返回个人主页
    backgrzx = (e) => {
        return (
            this.props.history.push('/main/grzx')
        )
    }
    // 验证码回调函数
    onyzm = (value) => {
        this.setState({
            yzmVal: value,
        });
    }
    // name回调函数
    onChangename = (value) => {
        this.setState({
            uname: value,
        });
    }
    // phone回调函数
    onChangePhone = (value) => {
        const str = value.replace(/\s*/g, ""); //字符串处理
        if (value.replace(/\s/g, '').length !== 11) { //判断长度是否合法
            this.setState({
                hasErrorPhone: true,
            });
        } else {
            this.setState({
                hasErrorPhone: false,
            });
        }
        this.setState({  //缺一位，执行完成后计算？
            phone: value,
        });
    }
    // 获取验证码
    hqyzm = () => {
        if (this.state.phone === "") {
            Toast.info("请输入手机号后获取验证码")
        } else {
            this.setState({
                yzmStatus: true
            })
            let maxtime = this.state.time / 1000; //按秒计算
            let timer = null;
            let _this = this;
            console.log(this.state.time)
            setTimeout(function f() {
                if (maxtime > 0) {
                    let minutes = Math.floor(maxtime / 60);
                    let seconds = Math.floor(maxtime % 60);
                    minutes < 10 ? minutes = '0' + minutes : minutes = minutes;
                    seconds < 10 ? seconds = '0' + seconds : seconds = seconds;
                    let msg = minutes + "分" + seconds + "秒后重发";
                    _this.setState({
                        msg
                    });
                    --maxtime;
                } else {
                    let msg = "获取验证码";
                    _this.setState({
                        yzmStatus: false,
                        msg
                    });
                    clearTimeout(timer);
                    return;
                }
                timer = setTimeout(f, 1000);
            }, 1000);
            _this.getYzm();
        }
    }
    getYzm = () => {
        const url = YL_URL + 'duanXinController/sendMsg';
        var params = new URLSearchParams();
        params.append('sjh', this.state.phone);
        // Toast.loading("加载中...",0,null,true);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            // Toast.hide();
            console.log(res)
            if (res.data.flag) {
                // alert(res.data.flag);
                this.setState((state, props) => {
                    return {
                        yzm: res.data.yzm,
                    }
                },
                    () => {
                    })
            }
        });
    }

    render() {
        return (
            <div>
                <NavBar
                    // leftContent="返回"
                    icon={<Icon type="left" />}
                    mode="dark"
                    onLeftClick={this.backgrzx}
                >修改密码</NavBar>
                <div className="grid-css"><List>
                    <InputItem
                        type="text"
                        // extra='不可修改'
                        editable={false}
                        placeholder="请输入姓名"
                        value={this.state.uname}
                        onChange={this.onChangename}
                    >姓名:</InputItem>
                    <InputItem
                        type="phone"
                        // extra='不可修改'
                        editable={false}
                        error={this.state.hasErrorPhone}
                        onErrorClick={this.onErrorClickPhone}
                        onChange={this.onChangePhone}
                        value={this.state.phone}
                        placeholder={this.state.phone}
                    >手机号码:</InputItem>
                    <InputItem
                        type="text"
                        placeholder="请输入验证码"
                        onChange={this.onyzm}
                        value={this.state.yzmVal}
                        extra={<Button
                            type="ghost"
                            size="small"
                            inline
                            onClick={this.hqyzm}>
                            {this.state.yzmStatus ? `${this.state.msg}` : '获取验证码'}
                        </Button>}
                    >验证码:</InputItem>
                    <InputItem
                        type="password"
                        value={this.state.password0}
                        onChange={this.onOldPassword}
                        error={this.state.repassword0}
                        onErrorClick={this.onErrorClickPass}
                        placeholder="请输入旧密码"
                    >旧密码:</InputItem>
                    <InputItem
                        type="password"
                        value={this.state.password1}
                        onChange={this.onNewPassword}
                        error={this.state.repassword1}
                        onErrorClick={this.onErrorClickPass1}
                        placeholder="请输入新密码"
                    >新密码:</InputItem>
                    <InputItem
                        type="password"
                        value={this.state.password2}
                        onChange={this.onrePassword}
                        error={this.state.repassword2}
                        onErrorClick={this.onErrorClickrePass}
                        placeholder="请输入确认密码"
                    >确认密码:</InputItem>

                </List>
                    <div className="changeProfiel">
                        <Button type="primary" onClick={this.changeProfileMsg}>确认修改</Button>
                        <WhiteSpace /></div>
                </div>
            </div>
        )
    }
}
