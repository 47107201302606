import './yktsfyz.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,Toast,Card,WhiteSpace,WingBlank } from 'antd-mobile';
import { BASE_URL, YKT_URL, Axios } from '../../../utils/url';
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class Yktmjqxsq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:{},
            dqzt:"待提交",
            todate:"",
            tjlist:[],
            isLoad:false,
            isLoaded:false,
            sqlist:{}
        }
    }

    componentDidMount = () => {
        this.getList();
        this.getSqjlList();
    }

    //查询当前一卡通详情信息
    async getList() {
      console.log("查询当前一卡通详情信息");
      let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        const url = BASE_URL+'mjsq/page1';
        var params = new URLSearchParams();
        params.append("sfzh",ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((res) => {
            console.log(res.data.records[0]);
            if (res.status === 200) {
                if(res.data.records.length>0){
                  this.setState({
                    list:res.data.records[0]
                });
                  this.getMjxx();
                }else{
                    Toast.info('您登记信息有误，请持证件到窗口处理', 3, null, false);
                }

            }
        });
    }

    //查询申请记录
    async getSqjlList() {
      console.log("查询当前一卡通详情信息");
      let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        const url = BASE_URL+'mjqxsqjl/page';
        var params = new URLSearchParams();
        params.append("sfzh",ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                if(res.data.length>0){
                  for(var a = 0;a<res.data.length;a++){
                    var item = res.data[a];
                    if(item.dqzt === -1){
                      item.dqzt = "退回"
                    }else if(item.dqzt === 0){
                      item.dqzt = "待确认"
                    }else if(item.dqzt === 1){
                      item.dqzt = "已确认"
                    }
                  }
                  this.setState({
                    sqlist:res.data,
                    isLoaded:true
                });
                }

            }
        });
    }

      //获取当前人员的门禁信息
    async getMjxx() {
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          console.log(ryobject.sfzh);
          const url = BASE_URL+'mjqxsq/getMjxx';
          var params = new URLSearchParams();
          params.append("sfzh", ryobject.sfzh);
          await Axios({
              method: 'post',
              url: url,
              data: params
          }).then((res) => {
            console.log(res.data);
              if (res.status === 200) {
                if(res.data.length>0){
                  this.setState({
                    tjlist:res.data,
                    isLoad:true
                  });
                }

              }
          });
      }
      setMapList = (e) => {
        if(this.state.isLoad){
                return this.state.tjlist.map((item, key) => (
                  <List renderHeader={() => '门禁权限'+(key+1) } >
                  <Item  align="top" thumb={ygk} multipleLine wrap>
                  {item.lymc}
                  </Item>
              </List>
            ))
        }
    }

    setsqMapList = () => {
      if (!this.state.isLoaded) {
          return null;
      } else {
          return this.state.sqlist.map((item, key) => (

              <WingBlank size="lg" key={key}>
              <WhiteSpace size="lg" />
              <Card onClick={() => this.handleOrder(item)}>
                  <Card.Header
                      title={item.ryxm}
                      thumb={ygk}
                      extra={<span>{'类型：' + item.ddmc}</span>}
                  />
                  <Card.Body>
                      <div>{item.zhlxmc}</div>
                  </Card.Body>

                  <Card.Footer content={'申请时间：' + item.firsttime.slice(0,10) } extra={<div>{'当前状态：' + item.dqzt}</div>}  />
              </Card>
              <WhiteSpace size="lg" />
          </WingBlank>

              // <Item multipleLine extra={item.dqztmc} key={key} onClick={() => this.handleJump(item)}>
              //     {item.ryxm} <Brief>{item.firsttime}</Brief>
              //     <Brief>{item.fkzxmc}</Brief>
              // </Item>
          ))
      }
  }


    handleSq=()=>{
      this.props.history.push({ pathname : "./yktmjqxsqedit", query : { list : this.state.list,tjlist:this.state.tjlist}});
    }
    handleOrder=(item)=>{
      this.props.history.push({ pathname : "./yktmjqxsqshow", query : { detail : item}});
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}
              rightContent={[
                <div onClick={this.handleSq}>申请</div>
              ]}
             onLeftClick={() => this.props.history.goBack()}
            >门禁权限延期</NavBar>
            <form onSubmit={this.handleSubmit}>
             <List  >
             <div className="scfj" style={{marginTop:"50px",color:"#888",fontSize:"14px"}}>一卡通基本信息</div>
             <InputItem
              value={this.state.list.sqyhxm}
              editable
            ><div >姓名</div></InputItem>

            <InputItem
              value={this.state.list.yktzh}
              editable
            ><div >一卡通号</div></InputItem>

            <InputItem
              value={this.state.list.sfzh}
              editable
            ><div >证件编号</div></InputItem>


            {this.setMapList()}

            <div className="scfj">申请说明</div>
            <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>
            门禁权限延期申请提交后，预计三个工作日内审核完成</p>
            <div className="scfj">申请记录</div>
            {this.setsqMapList()}
             </List>
             </form>
            </div>
        )
    }
}
