import './wyfcxmx.css'
import React, { Component } from 'react'
import { NavBar, Icon, Picker, List, WhiteSpace, Toast, Radio, } from 'antd-mobile';
import { BASE_URL, YL_URL, Axios } from '../../../utils/url'
import thjl from '../../../assets/images/thjl.png'
import jtdh from '../../../assets/images/jtdh.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
const seasons = [
    [
        {
            label: '01',
            value: '01',
        },
        {
            label: '02',
            value: '02',
        },
        {
            label: '03',
            value: '03',
        },
        {
            label: '04',
            value: '04',
        },
        {
            label: '05',
            value: '05',
        },
        {
            label: '06',
            value: '06',
        },
        {
            label: '07',
            value: '07',
        },
        {
            label: '08',
            value: '08',
        },
        {
            label: '09',
            value: '09',
        },
        {
            label: '10',
            value: '10',
        },
        {
            label: '11',
            value: '11',
        },
        {
            label: '12',
            value: '12',
        },
    ],

];


export default class Dhfcxmx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jfdjList: [],
            zje: "",
            isLoad: false,
            mxList: [],
            value: "",
            dhhm: "",
            sfzh: "",
            sValue: ""
        }
    }
    componentDidMount = () => {
        const mouth = this.props.location.query.mouth;
        const dhhm = this.props.location.query.dhhm;
        // this.getClglList(dhhm, sfzh);
        this.handleFromChange(dhhm,mouth)
        this.state.dhhm = dhhm;
    }

    handleFromChange (dhhm,mouth) {
        if(mouth < 10){
            mouth = '0'+mouth;
        }
        const url = BASE_URL + 'systeleuserController/getdjfcxmx';
        var params = new URLSearchParams();
        params.append("dhhm", dhhm);
        params.append("yf", mouth);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            Toast.hide();
            console.log(res);
            if (res.status === 200) {
                if (res.data.list.length > 0) {
                    this.setState({
                        jfdjList: res.data.list,
                        isLoad: true,
                    });
                } else {
                    this.setState({
                        isLoad: true,
                        jfdjList: [],
                    });
                    alert("暂无信息");
                }
            }
        });
    }
    setMapList = () => {
        if (!this.state.isLoad) {
            return null;
        } else {
            console.log(this.state.dhhm);
            return this.state.jfdjList.map((item, key) => (
                item.tocode == this.state.dhhm ?
                <List renderHeader={() => '通话记录'+(key+1) } >
                    <Item extra={item.fromcode}>被叫</Item>
                    <Item extra={item.timelong} align="top" thumb={jtdh} multipleLine>
                    通话时长 <Brief>{item.calldate}    {item.calltime}</Brief>
                    </Item>
                </List>
                :
                <List renderHeader={() => '通话记录'+(key+1) } >
                    <Item extra={item.tocode}>呼叫</Item>
                    <Item extra={item.timelong} align="top" thumb={thjl} multipleLine>
                    通话时长 <Brief>{item.calldate}    {item.calltime}</Brief>
                    </Item>
                </List>
            ))
        }
    }
    render() {

        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >电话费明细</NavBar>

                <div  style={{ marginTop: "2.89rem" }}>
                    {this.setMapList()}

                </div>
            </div>

        )
    }
}
