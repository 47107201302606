import './index.css'
import React, { Component } from 'react'
import { PullToRefresh, ListView, Toast, NavBar, Icon, List } from 'antd-mobile';
import { BASE_URL, Axios } from '../../../utils/url';

const Item = List.Item;
const Brief = Item.Brief;
export default class jfjl extends Component {

    constructor(props) {
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        super(props);
        this.state = {
            show: false,
            value: '',
            dsplist: [],
            pageNo: 1,//当前页面数
            pageSize: 10, // 分页size
            totalPage: 0, // 总页数初始化
            isShowContent: false, // 控制页面再数据请求后显示
            refreshing: false, // 是否显示刷新状态
            dataSource,
            isLoading: false, // 是否显示加载状态
            height: document.documentElement.clientHeight,
        };
    }

    componentDidMount = () => {
        this.getList();
    }

    async getList() {
        console.log(this.state.pageNo)
        console.log("执行获取数据");
        const url = BASE_URL + 'bRyzcController/jflist';
        var params = new URLSearchParams();
        params.append("pageno", this.state.pageNo);
        params.append("size", this.state.pageSize);

        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log(JSON.parse(userInfo.sfzh));
        params.append("ryid", userInfo.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            if (res.status === 200) {
                let dsplist = [...this.state.dsplist, ...res.data.jflist.records];
                this.setState({
                    dsplist: dsplist,
                    isShowContent: true,
                    pageNo: this.state.pageNo + 1,
                    dataSource: this.state.dataSource.cloneWithRows(dsplist),// 数据源dataSource
                    totalPage: res.data.pages,
                    refreshing: false,
                    isLoading: false,
                });
            }
        });
    }

    onConfirm = (startTime, endTime) => {//文件返回赋值
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            startTime: startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate(),
            endTime: endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate(),
            value: startTime.toLocaleDateString() + "~" + endTime.toLocaleDateString()
        });
        this.onChange();
    }

    clear = () => {//点击取消
        this.setState({
            value: '',
            startTime: '',
            endTime: ''
        }, () => {
            this.getList();
        })
    };
    show = () => {//展示
        this.show = true;
    }

    // 下拉刷新
    onRefresh = () => {
        Toast.loading();
        this.setState({
            pageNo: 1,
            totalPage: 0,
            dsplist: [],
        }, () => {
            this.getList();
        })
    };

    // 加载更多
    onEndReached = () => {
        if (this.state.isLoading || (this.state.totalPage < this.state.pageNo + 1)) {
            Toast.hide();
            return;
        }
        this.setState({
            isLoading: true,
        }, () => {
            this.getList()
        });
    };

    render() {
        //下拉刷新部分
        // 定义Row，从数据源(dataSurce)中接受一条数据循环到ListView
        const row = (rowData, sectionID, rowID) => {
            var str = ''
            if (rowData.dqzt == '已缴费') {
                if (rowData.targetsys == 'WXPay.jsPay') {
                    str = "已缴费/微信"
                } else if (rowData.targetsys == 'trade.precreate') {
                    str = "已缴费/支付宝"
                } else {
                    str = '已缴费/未知方式'
                }
            } else if (rowData.dqzt == '待支付') {
                str = '待支付'
            } else { str = '未知状态' }
            return (
                <Item
                    multipleLine
                    align="top"
                >用户:{rowData.yhxm}<div style={{ float: 'right' }}>{rowData.firsttime.substr(0, 10)}</div>
                    <Brief>状态:{str}<div style={{ float: 'right' }}>{rowData.mername}</div></Brief>
                    <Brief>金额:{rowData.je / 100 + "元"}<div style={{ float: 'right' }}>{rowData.djid}</div></Brief>
                </Item>
            );
        };
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >个人缴费记录</NavBar>
                {/* 下拉刷新部分 */}
                <ListView
                    key={true}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{ textAlign: 'center' }}>
                        {this.state.isLoading ? '正在加载...' : '真的已经到底了'}
                    </div>)}
                    renderRow={row}
                    useBodyScroll={true}
                    style={{
                        height: this.state.height,
                        border: '1px solid #ddd',
                        margin: '5px 0',
                        marginTop: "45px"
                    }}
                    distanceToRefresh='20'
                    pullToRefresh={<PullToRefresh
                        refreshing={this.state.refreshing}
                        onRefresh={this.onRefresh} />}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={20}
                    pageSize={this.state.pageSize}
                />
            </div>
        )
    }
}
