import React, { Component } from 'react'
import { BASE_URL } from '../../../utils/url.js'
import Axios from 'axios';
import { List, NavBar, Icon } from 'antd-mobile';
import './index.css'
const Item = List.Item;
const Brief = Item.Brief;

export default class fwxxbdlist extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.state = {
            uname: userInfo.ryxm,
            sfzh: userInfo.sfzh,
            fwxxbdlist: [],
            fwxx: '',
            hasError: false,//身份证号数判断值
            isLoad: false
        }

    }
    componentDidMount = () => {
        this.getList()
    }
    async getList() {
        var params = new URLSearchParams();
        const url = BASE_URL + 'grzy/profilebdlist'
        console.log(url)
        params.append("yhxm", this.state.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            console.log(res);
            this.setState({
                fwxxbdlist: res.data,
                isLoad: true
            });
        })
    }

    // 返回个人主页
    backgrzx = (e) => {
        return (
            this.props.history.push('/main/grzx')
        )
    }
    // 下一页
    handleSq = () => {
        // this.props.history.push('./fwxxbd');
        this.props.history.push({ pathname: "./fwxxbd", query: { res: false } });
    }
    // 下一页
    xgButton = (item) => {
        this.props.history.push({ pathname: "./fwxxbd", query: { fwxx: item, res: true } });
    }
    // 绑定列表
    fwbdloop() {
        if (this.state.fwxxbdlist.length > 0) {
            return this.state.fwxxbdlist.map((item, key) => (
                <Item multipleLine extra={item.yhxm} key={key} onClick={() => this.xgButton(item)}>
                    {item.fwbh.substr(item.fwbh.lastIndexOf("_") + 1, 13)} <Brief>{item.hzxm}</Brief>
                </Item>
            ))
        } else {
            return <div style={{fontSize:"14px",textAlign:"left",color:"rgb(243 75 98)",paddingLeft:"10px",lineHeight:"25px"}}>*当前无绑定房屋，请先绑定。<div style={{fontSize:"14px",textAlign:"left",color:"rgb(243 75 98)",lineHeight:"25px"}}>*目前只有户主能够绑定其名下房屋，其他人员暂不能绑定。</div></div>
        }
    }

    render() {
        return (
            <div>
                <NavBar
                    icon={<Icon type="left" />}
                    mode="dark"
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={[
                        <div onClick={this.handleSq}>绑定</div>
                    ]}
                >房屋绑定信息</NavBar>
                <div style={{marginTop:"48px"}}>
                    {this.fwbdloop()}
                </div>
            </div>
        )
    }
}
