import './yktsfyz.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,Toast } from 'antd-mobile';
import { BASE_URL, YKT_URL, Axios } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
export default class Yktsfyz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list:{},
            dqzt:"待提交",
            todate:"",
            tjlist:[]
        }
    }

    componentDidMount = () => {
        this.getList();
        this.getcx();
        // this.getmj();
    }

    //查询当前一卡通详情信息
    async getList() {
      console.log("查询当前一卡通详情信息");
      let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        const url = YKT_URL+'bryxxController/page';
        var params = new URLSearchParams();
        params.append("sfzh",ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                if(res.data.records.length!=0){
                    if(res.data.records[0].ykth.substr(0,1)!="A"&&res.data.records[0].ykth.substr(0,1)!="B"){
                        this.setState({
                            list:res.data.records[0]
                        });
                        this.getzt();
                        this.getmj(this.state.list.ykth);
                        this.gettjjl();
                    }else{
                        this.setState({
                            list:res.data.records[0]
                        });
                        Toast.info('您当前所有一卡通类型不支持手机延期', 3, null, false);
                    }

                }else{
                    Toast.info('您登记信息有误，请持证件到窗口处理', 3, null, false);
                }

            }
        });
    }
//查询是否可以看到
async getcx() {

  let ryobject=JSON.parse(localStorage.getItem('userInfo'));
    console.log(ryobject.sfzh);

    const url = BASE_URL+'mjsq/page1';
    var params = new URLSearchParams();
    params.append("sfzh", ryobject.sfzh);
    await Axios({
        method: 'post',
        url: url,
        data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
        console.log(res.data.records);
        if(res.data.records.length>0){

        }else{
          Toast.info('没有查询到您的授权信息，不能提交延期申请', 3, null, false);
          // this.props.history.push('./main');
        }


    });
}
    //查询当前人员一卡通申请提交状态
    async getzt() {
        console.log("查询当前人员一卡通申请提交状态");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          console.log(ryobject.sfzh);
          let sfzh="31010519741126162X";
          const url = BASE_URL+'yktyq/page';
          var params = new URLSearchParams();
          params.append("sfzh", ryobject.sfzh);
          await Axios({
              method: 'post',
              url: url,
              data: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((res) => {
              console.log(res.data);
              if (res.status === 200) {
                  if(res.data.records.length==0){
                    this.setState({
                        dqzt:"待提交",

                    });
                  }else{
                    this.setState({
                        dqzt:"已申请",
                        // tjlist:res.data.records
                    });
                  }

              }
          });
      }

      //获取当前人员的提交列表
    async gettjjl() {
        console.log("获取当前人员的提交列表");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          console.log(ryobject.sfzh);
          const url = BASE_URL+'yktyq/getlist';
          var params = new URLSearchParams();
          params.append("sfzh", ryobject.sfzh);
          await Axios({
              method: 'post',
              url: url,
              data: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((res) => {
            console.log(res.data);
              if (res.status === 200) {
                  this.setState({
                    tjlist:res.data.records
                  });
              }
          });
      }



      //查询当前一卡通门禁期限
    async getmj(e) {
        console.log(e);
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
          console.log(ryobject.sfzh);
          const url = BASE_URL+'mjsq/page';
          var params = new URLSearchParams();
          console.log(this.state.list.ykth)
          params.append("yktzh", this.state.list.ykth);
          Axios({
              method: 'post',
              url: url,
              data: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((res) => {
            console.log(res)
              if (res.status === 200) {
                if(res.data.records.length>0){
                  this.setState({
                    todate:res.data.records[0].todate
                  });
                }

              }
          });
      }
      zt = (dqzt) => {
          if(dqzt=="0"){
              return "待确认"
          }
          if(dqzt=="1"){
            return "已延期"
        }
        if(dqzt=="-1"){
            return "已退回"
        }
      }

      setMapList = () => {
          console.log(this.state.tjlist);
            return this.state.tjlist.map((item, key) => (
                <Item multipleLine extra={this.zt(item.dqzt)} key={key} onClick={() => this.handleJump(item)}>
                    {item.ryxm} <Brief>{item.firsttime}</Brief>
                    {/* {item.dqzt=="0" &&
                        <Brief>待确认</Brief>
                    }
                    {item.dqzt=="1" &&
                        <Brief>已延期</Brief>
                    }
                    {item.dqzt=="-1" &&
                        <Brief>已退回</Brief>
                    } */}
                </Item>
            ))
    }

    handleJump=(item)=>{
        this.props.history.push({ pathname : "./yktsfyzshow" , query : { yqsqid : item.yqsqid}});
    }

    handleSq=()=>{
        if(this.state.list.dqzt=='3'){

            if(this.state.dqzt=="已申请"){
              Toast.info('您已经提交了申请，正在审核中,请耐心等待', 3, null, false);
              return;
          }

          var startdate = new Date();
          var enddate=this.state.todate;
                console.log(enddate)
                //当前时间
                var sDate = new Date(startdate).getTime();

                console.log(sDate)
                // console.log(stime);
                //到期时间
                // var eDate = new Date(enddate).getTime();
                var eDate=new Date(enddate.replace(/-/g, "/")).getTime();
                console.log(eDate)
                var thisMothDays = 1000 * 3600 * 24 * 30;


               var xsDate = eDate - sDate;
              //  xsDate = parseInt(xsDate)

                if (eDate - sDate > 0) {
                  if (xsDate > thisMothDays) {
                    Toast.fail('请您于到期时间一个月内提交', 3, null, false);
                    return;
                  }else{
                      this.props.history.push('./yktsfyzedit');
                  }

                }else if(eDate < sDate){
                  this.props.history.push('./yktsfyzedit');
                }
        }else{
            Toast.info('您的一卡通类型无法提交申请', 3, null, false);
            return;
        }

    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}
              rightContent={[
                <div onClick={this.handleSq}>申请</div>
              ]}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通门禁延期</NavBar>
            <form onSubmit={this.handleSubmit}>
             <List  >
             <div className="scfj" style={{marginTop:"50px",color:"#888",fontSize:"14px"}}>一卡通基本信息</div>
             <InputItem
              value={this.state.list.ryxm}
              editable
            ><div >姓名</div></InputItem>

            <InputItem
              value={this.state.list.xbmc}
              editable
            ><div >性别</div></InputItem>

            <InputItem
              value={this.state.list.ykth}
              editable
            ><div >一卡通号</div></InputItem>

            <InputItem
              value={this.state.list.zjbh}
              editable
            ><div >证件编号</div></InputItem>

            <InputItem
              value={this.state.list.lxsjh}
              editable
            ><div >联系电话</div></InputItem>

            <InputItem
              value={this.state.todate}
              editable
            ><div >到期时间</div></InputItem>

            {/* <InputItem
              value={this.state.dqzt}
              editable
            ><div >申请状态</div></InputItem> */}
            <div className="scfj">申请说明</div>
            <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>
            一卡通门禁延期申请提交后，预计三个工作日内审核完成</p>
            <div className="scfj">申请记录</div>
            {this.setMapList()}
             </List>
             </form>
            </div>
        )
    }
}
