import './wyfcxmx.css'
import React, { Component } from 'react'
import { NavBar, Icon, SearchBar, List, Toast,Modal,Radio,Button,InputItem } from 'antd-mobile';
import { BASE_URL, YL_URL, Axios } from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Wyfcxmx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jfdjList: [],
            zje: "",
            isLoaded: false,
            mxList:[],
            modal:false,
            modal1:false,
            value:"",
            fjh:""
        }
    }
    componentDidMount = () => {
        const fjh = this.props.location.query.fjh;
        this.getClglList(fjh);
    }
    onSubmit = (e) => {


    };
    //获取列表
    async getClglList(e) {
        const url = BASE_URL + 'bgrfyzeController/getcx';
        var params = new URLSearchParams();
        params.append("srfj", e);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                this.setState({
                    jfdjList: res.data.list,
                    zje: res.data.zje,
                    isLoaded: true
                });

            }
        });
    }
    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.jfdjList.map((item, key) => (
                <Item extra={item.zje + "元"} align="middle"
                 thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                 multipleLine key={key}
                 onClick={() => this.handlemx(item)}>
                    {item.ryxm} <Brief>{item.fyny}</Brief>
                    <Brief>{item.fwbh}</Brief>
                </Item>
            ))
        }
    }
    handlemx = (item) => {
        const url = BASE_URL + 'bgrfymxController/getMx';
        var params = new URLSearchParams();
        params.append("fybh", item.fybh);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                this.setState({
                    modal:true,
                    mxList:res.data
                });
            }

        });
    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }


    setmxList=()=>{
        return this.state.mxList.map((item, key) => (
            <Item extra={item.je + "元"} align="middle"
             thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
             multipleLine key={key} >
                {item.fylb}
                <Brief>{"单价："+ (item.flv==undefined?"":item.flv)}</Brief>
                <Brief>{"数量："+ (item.sl==undefined?"":item.sl)}</Brief>
            </Item>
        ))
    }
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };

    render() {
        const { value } = this.state;
        const data = [
            { value: "wx.unifiedOrder", label: '微信支付', img: Wxzf },
            { value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >物业费明细</NavBar>
                <div className="kf">
                    {/* <SearchBar placeholder="请输入房号" maxLength={13}
                        onSubmit={this.onSubmit} /> */}
                    <List renderHeader={() => ''} >
                        {this.setMapList()}
                    </List>
                </div>
                <Modal
                    style={{height:"500px",overflow:"auto"}}
                    title="缴费明细"
                    popup
                    closable={true}
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => ''} >
                        {this.setmxList()}
                    </List>
                </Modal>
                <Modal
                    popup
                    visible={this.state.modal1}
                    onClose={this.onClose('modal1')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '缴费金额'}>
                        <InputItem
                            type='number'
                            placeholder="请输入充值金额"
                            value={this.state.zje}
                            editable={false}
                            extra="元"
                        >充值金额</InputItem>
                    </List>
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
            </div>
        )
    }
}
