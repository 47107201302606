import './yktjfjlcx.css'
import React, { Component } from 'react'
import { NavBar,Icon } from 'antd-mobile';
import { SearchBar} from 'antd-mobile';
import { List, Calendar } from 'antd-mobile';
import { PullToRefresh, ListView,Toast,Modal,Button, InputItem, } from 'antd-mobile';
import { YKT_URL, Axios, FORMAT } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
export default class Ygkjfjlcx extends Component {
    constructor(props) {
      const dataSource = new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      });
        super(props);
        this.state = {
          en: false,
          show: false,
          config: {},
          value: '',
          startTime:'',
          endTime:'',
          // useBodyScroll:'partial scroll'
          dsplist:[],
          pageNo: 1,//当前页面数
          pageSize: 10, // 分页size
          totalPage: 0, // 总页数初始化
          isShowContent: false, // 控制页面再数据请求后显示
          refreshing: false, // 是否显示刷新状态
          dataSource,
          isLoading: false, // 是否显示加载状态
          height: document.documentElement.clientHeight,
          objdata:{},
          modal:false
        };
      }

    componentDidMount = () => {
        this.getList();
    }

    async getList() {
      console.log("执行获取数据");
      let rydata=localStorage.getItem('userInfo');
      console.log(rydata);
        let ryobject=JSON.parse(localStorage.getItem("userInfo"));
        console.log(ryobject.sfzh);
        let sfzh="653124197401024225";
        const url = YKT_URL+'yktjycx/page';
        var params = new URLSearchParams();
        params.append("pageno", this.state.pageNo);
        params.append("size", this.state.pageSize);
        params.append("starttime", this.state.startTime);
        params.append("endtime", this.state.endTime);
        params.append("ryid", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params,
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
              let dsplist = [...this.state.dsplist, ...res.data.records];
              // let dsplist=this.state.dsplist.push(...res.data.records)
              console.log(dsplist);
                this.setState({
                    dsplist: dsplist,
                    isShowContent: true,
                    pageNo: this.state.pageNo +1,
                    dataSource: this.state.dataSource.cloneWithRows(dsplist),// 数据源dataSource
                    totalPage:res.data.pages,
                    refreshing: false,
                    isLoading: false,
                });
                console.log(this.state.dsplist);
            }
        });
    }

      onConfirm = (startTime, endTime) => {//文件返回赋值
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
          show: false,
          startTime:startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate(),
          endTime:endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate(),
        //   value:startTime.toLocaleString()+"~"+endTime.toLocaleString()
        value:startTime.toLocaleDateString()+"~"+endTime.toLocaleDateString()
        });
        this.onChange();
      }

      clear = () => {//点击取消
        this.setState({
           value: '' ,
           startTime:'',
           endTime:''
          },()=>{
            this.getList();
          })
      };
      show = () =>{//展示
          this.show=true;
      }

    // 下拉刷新
    onRefresh = () => {
      Toast.loading();
      this.setState({
        pageNo: 1,
        totalPage: 0,
        dsplist: [],
      },()=>{
        this.getList();
      })
    };

    // 加载更多
    onEndReached = () => {
      console.log(this.state.isLoading);
      console.log(this.state.totalPage < this.state.pageNo +1);
      if (this.state.isLoading || (this.state.totalPage < this.state.pageNo +1)) {
        Toast.hide();
        return;
      }
      this.setState({
        isLoading: true,
      },()=>{
        this.getList()
      });
    };

    handleDjf = (obj) => {//列表点击事件
      this.setState({
        objdata:obj,
        modal:true
      });
      console.log(obj);
    }

    //关闭弹出窗
    onClose = key => () => {
      this.setState({
          [key]: false,
      });
  }
    //查询
    onChange= (value) => {
      this.setState({
        pageNo: 1,
        totalPage: 0,
        dsplist: [],
      },()=>{
        this.getList();
      })
    };



    render() {
        //下拉刷新部分
        // 定义Row，从数据源(dataSurce)中接受一条数据循环到ListView
        const row =  (rowData, sectionID, rowID) => {
          return (
            <Item multipleLine extra={rowData.shmc} onClick={() => this.handleDjf(rowData)}>
              交易额：{FORMAT.number_format(rowData.jye/100, 2)+"元"} <Brief>交易类型：{rowData.czlxmc}</Brief> <Brief> 交易时间：{rowData.jzsj}</Brief>
            </Item>
          );
        } ;
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通交易记录查询</NavBar>
            <div className="divmagin">
            <SearchBar placeholder="查询" maxLength={8}
             value={this.state.value}
             onSubmit={value => console.log(value, 'onSubmit')}
             onClear={value => console.log(value, 'onClear')}
             onFocus={() => {
                document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                this.setState({
                  show: true,
                  showShortcut: true
                });
             }}
             onBlur={() => console.log('onBlur')}
             onCancel={() => this.clear()}
             onChange={this.onChange}
            />
            <Calendar
            {...this.state.config}
            visible={this.state.show}
            onCancel={this.onCancel}
            onConfirm={this.onConfirm}
            onSelectHasDisableDate={this.onSelectHasDisableDate}
            />
            </div>
            {/* 下拉刷新部分 */}
            <ListView
                key={true}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{ textAlign: 'center' }}>
                  {this.state.isLoading ? '正在加载...' : '真的已经到底了'}
                </div>)}
                renderRow={row}
                useBodyScroll={true}
                style={{
                  height: this.state.height,
                  border: '1px solid #ddd',
                  margin: '5px 0',
                }}
                distanceToRefresh='20'
                pullToRefresh={<PullToRefresh
                  refreshing={this.state.refreshing}
                  onRefresh={this.onRefresh}
                />}
                onEndReached={this.onEndReached}
                onEndReachedThreshold={20}
                pageSize={this.state.pageSize}
              />
              {/* 弹窗部分 */}
              <Modal
                  popup
                  visible={this.state.modal}
                  onClose={this.onClose('modal')}
                  animationType="slide-up"
                  afterClose={() => { console.log('afterClose'); }}
              >
                  <List renderHeader={() => '详情信息'} >
                      <InputItem
                          type="money"
                          value={this.state.objdata.ryxm}
                          editable={false}
                      ><div>姓名</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.grzh}
                          editable={false}
                      ><div>个人账号</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.rybh}
                          editable={false}
                      ><div>编号</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.sbmc}
                          editable={false}
                      ><div>设备名称</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.shmc}
                          editable={false}
                      ><div>商户名称</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.fssj}
                          editable={false}
                      ><div>发生时间</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.jzsj}
                          editable={false}
                      ><div>记账时间</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.lyshzhlxmc}
                          editable={false}
                      ><div>账户类型</div></InputItem>
                      <InputItem
                          type="money"
                          value={this.state.objdata.czlxmc}
                          editable={false}
                      ><div>操作类型</div></InputItem>
                      <InputItem
                          type="money"
                          value={FORMAT.number_format(this.state.objdata.jye/100, 2)+"元"}
                          editable={false}
                      ><div>交易额</div></InputItem>
                      <InputItem
                          type="money"
                          value={FORMAT.number_format(this.state.objdata.zhye/100, 2)+"元"}
                          editable={false}
                      ><div>帐户余额</div></InputItem>
                  </List>
                  <div className="dban">
                      <Button type="primary" inline style={{ marginRight: '4px' }} onClick={this.onClose('modal')}>关闭</Button>
                  </div>

              </Modal>
            </div>
        )
    }
}
